import { BbsType } from 'constant/Constant';
import React from 'react';
import BbsList from 'sections/rent25/operation/BbsList';

type Props = {};

const NoticeListPage = (props: Props) => {
  return <BbsList bbsType={BbsType.notice.code} />;
};

export default NoticeListPage;
