import { MouseEvent, useEffect, useState } from 'react';
// material-ui
import { useTheme } from '@mui/material/styles';
import {
  Grid,
  Divider,
  Link,
  List,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  Stack,
  Typography,
  Tooltip,
  Button,
  Box,
  FormHelperText,
  InputLabel,
  TextField,
  Checkbox,
  FormControl,
  FormControlLabel
} from '@mui/material';

// third-party
import * as Yup from 'yup';

// project-imports
import MainCard from 'components/MainCard';

// assets
import { Personalcard, Link2, Location, Mobile, Edit, Trash, CloseSquare } from 'iconsax-react';
import Avatar from 'components/@extended/Avatar';
import AlbumImageView from 'components/image/AlbumImageView';
import IconButton from 'components/@extended/IconButton';
import { ThemeMode } from 'types/config';
import AlertUserDelete from './AlertUserDelete';
import { Code, Company, FileInformation, UpdateUserRequest, User } from 'openapi/models';
import UserStatusChip from 'components/chip/UserStatusChip';
import EditableTextField from 'components/form/EditableTextField';
import PhoneNumberField from 'components/form/PhoneNumberField';
import TooltipTypography from 'components/text/TooltipTypography';
import { userServiceApi } from 'utils/axios';
import _ from 'lodash';
import { getImageUrl } from 'utils/file-utils';
import ConfirmDialog, { ConfirmProps } from 'components/dialog/ConfirmDialog';
import { useFormik } from 'formik';
import SelectCompanyDialog from 'components/dialog/SelectCompanyDialog';
import DragAndDropUpload from 'components/image/DragAndDropUpload';
import { useCodes } from 'contexts/CodeContext';
import {
  CodeGroupName,
  CompanyTypeCode,
  RealmGroupCode,
  UserStatusCode,
  UserTypeCode,
  alertSnackbarConfig,
  toValue
} from 'constant/Constant';
import { toCodeName } from 'utils/code-utils';
import { openSnackbar } from 'store/reducers/snackbar';
import { dispatch } from 'store';
import RequiredInputLabel from 'components/RequiredInputLabel';
import EditableNumericFormat from 'components/form/EditableNumericFormat';
import { toDateFormatString, toDateString } from 'utils/string-utils';

type Props = {
  edit: boolean;
  userId?: number;
  onChange?: (user: User) => void;
};

const UserInformationSection = ({ edit = false, userId, onChange }: Props) => {
  const theme = useTheme();
  const mode = theme.palette.mode;
  const code = useCodes();
  const [companyTypeCodes, setCompanyTypeCodes] = useState<Code[]>([]);

  const [open, setOpen] = useState<boolean>(false);
  const [user, setUser] = useState<User>({} as User);
  const [company, setCompany] = useState<Company | undefined | null>({} as Company);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [editMode, setEditMode] = useState(edit);
  const [confirmConfig, setConfirmConfig] = useState<ConfirmProps>({
    open: false,
    title: '',
    color: 'primary',
    isContent: false,
    onConfirm: () => {}
  });

  useEffect(() => {
    setCompanyTypeCodes(code.getCodes(CodeGroupName.CompanyTypeCode));
  }, [code]);

  useEffect(() => {
    if (userId) {
      userServiceApi
        .getUser(userId)
        .then((response) => {
          setUser(response.data?.user!);
          setCompany(response.data?.user?.company);
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }, [userId]);

  useEffect(() => {
    setEditMode(edit);
  }, [edit]);

  const handleClose = () => {
    setOpen(!open);
  };

  const validationSchema = Yup.object({
    name: Yup.string().max(100).required('이름은 필수 사항입니다.'),
    companyName: Yup.string().max(100).required('회사명은 필수 사항입니다.'),
    departmentName: Yup.string().max(100).required('부서명은 필수 사항입니다.'),
    positionName: Yup.string().max(100).required('직급명은 필수 사항입니다.'),
    telNumber: Yup.string().required('전화번호는 필수 사항입니다.'),
    email: Yup.string().email('이메일 형식이 맞지 않습니다.').max(255).required('이메일은 필수 사항입니다.'),
    requestContent: Yup.string().min(0).max(250, '추가정보는 최대 250자 입니다.'),
    businesscardImages: Yup.array().required('명함은 필수 사항입니다.'),
    businessRegistrationImages: Yup.array().required('사업자등록증(이미지)을 선택해주세요.'),
    slidingFeeRate: Yup.number().required('슬라이딩 수수료율는 필요입니다.'),
    companyId: Yup.number().when(['submitMode'], {
      is: (submitMode: string) => {
        return !['rejected', 'save'].includes(submitMode);
      },
      then: (schema) => {
        return schema.required('회사 선택은 필수 사항입니다.');
      }
    })
  });

  // const formikValidation = async (values: any) => {
  //   const errors = {};
  //   const isValid = await validationSchema.isValid(values);

  //   let error = isValid
  //     ? null
  //     : await validationSchema
  //         .validate(values)
  //         .then()
  //         .catch((error) => {
  //           // if (['rejected', 'save'].includes(values.submitMode)) {
  //           //   delete error.errors.companyId;
  //           // }

  //           // console.log("???: ", err.name);
  //           console.log(Object.keys(error));
  //           console.log(Object.values(error));

  //           // let cerror = err.errors[0];
  //           // let cerror = err.name === "ValidationError" ? err.errors[0] : null;
  //           return error.errors;
  //         });

  //   console.log(values.submitMode, error);

  //   return errors;
  // };

  function handleSubmit(submitMode: string) {
    formik.setFieldValue('submitMode', submitMode);
    formik.validateForm().then((errors) => {
      if (['rejected', 'save'].includes(submitMode)) {
        delete errors.companyId;
      }
      if (Object.keys(errors).length === 0) {
        setConfirmConfig({
          open: true,
          title: `${
            submitMode === 'approval' ? '승인' : submitMode === 'rejected' ? '거절' : submitMode === 'suspension' ? '정지' : '저장'
          } 하시겠습니까?`,
          subtitle: submitMode === 'rejected' ? '거절하시면 해당 사용자는 다시 가입하셔야 합니다.' : undefined,
          color: 'primary',
          isContent: submitMode !== 'save',
          onConfirm: ({ yesNo, content }: { yesNo: boolean; content?: string }) => {
            formik.setFieldValue('reason', content ?? '');
            setConfirmConfig({ ...confirmConfig, open: false });
            if (yesNo) {
              formik.handleSubmit();
            }
          }
        });
      } else {
        formik.handleSubmit();
      }
    });
  }

  function handleApproval() {
    formik.setFieldValue('submitMode', 'approval');
    handleSubmit('approval');
  }

  function handleSuspension() {
    formik.setFieldValue('submitMode', 'suspension');
    handleSubmit('suspension');
  }

  function handleReject() {
    formik.setFieldValue('submitMode', 'rejected');
    handleSubmit('rejected');
  }

  const handleSelectCompanyDialogOpen = () => {
    if (!editMode) setEditMode(true);
    setDialogOpen(true);
  };

  const handleDialogClose = (value?: Company) => {
    setDialogOpen(false);

    if (value) {
      formik.setFieldValue('companyId', value.companyId);
      setCompany(value);
    }
  };

  const formik = useFormik({
    initialValues: {
      name: user.name ?? '',
      companyName: user.companyName ?? '',
      departmentName: user.departmentName ?? '',
      positionName: user.positionName ?? '',
      telNumber: user.telNumber ?? '',
      email: user.email ?? '',
      requestContent: user.requestContent ?? '',
      businesscardImages: user.businesscardImages ?? ([] as FileInformation[]),
      businessRegistrationImages: user.businessRegistrationImages ?? ([] as FileInformation[]),
      companyId: user.companyId,
      unionYn: user.unionYn,
      unionName: user.unionName,
      submitMode: '',
      reason: '',
      slidingFeeRate: user.slidingFeeRate ?? 0,
      submit: null
    },
    validationSchema: validationSchema,
    onSubmit: async (values, { setErrors, setStatus, setSubmitting }) => {
      try {
        const request = {
          user: {
            ...user,
            ...values
          }
        } as UpdateUserRequest;

        await userServiceApi.updateUser(request);
        if (values.submitMode === 'approval') {
          await userServiceApi.updateUserStatusCode({
            userId: user.userId!,
            companyId: values.companyId,
            userStatusCode: UserStatusCode.approved.code,
            changeContent: values.reason
          });
        } else if (values.submitMode === 'rejected') {
          await userServiceApi.updateUserStatusCode({
            userId: user.userId!,
            companyId: values.companyId,
            userStatusCode: UserStatusCode.rejected.code,
            changeContent: values.reason
          });
        } else if (values.submitMode === 'suspension') {
          await userServiceApi.updateUserStatusCode({
            userId: user.userId!,
            companyId: values.companyId,
            userStatusCode: UserStatusCode.suspension.code,
            changeContent: values.reason
          });
        }

        dispatch(
          openSnackbar({
            ...alertSnackbarConfig,
            message: `업체정보가 ${
              values.submitMode === 'approval'
                ? '승인'
                : values.submitMode === 'rejected'
                ? '거절'
                : values.submitMode === 'suspension'
                ? '정지'
                : '저장'
            }되었습니다.`
          })
        );

        const response = await userServiceApi.getUser(user.userId!);
        setUser(response.data?.user!);
        onChange?.(response.data?.user!);

        setStatus({ success: true });
        setSubmitting(false);
        setEditMode(false);
      } catch (err: any) {
        setStatus({ success: false });
        setErrors({ submit: err.message });
        setSubmitting(false);
      }
    },
    enableReinitialize: true
  });

  return (
    <>
      <Grid container spacing={2.5}>
        <Grid item xs={12} md={3}>
          <MainCard
            title="회사 정보"
            sx={{
              '& .MuiCardHeader-root': {
                padding: '12px 12px 12px 24px'
              }
            }}
            secondary={
              <>
                <Stack direction={'row'} spacing={1.25} alignItems={'center'}>
                  <Box sx={{ fontSize: '0.675rem' }}>
                    <Tooltip
                      componentsProps={{
                        tooltip: {
                          sx: {
                            backgroundColor: mode === ThemeMode.DARK ? theme.palette.grey[50] : theme.palette.grey[700],
                            opacity: 0.9
                          }
                        }
                      }}
                      title="정보수정"
                      sx={{ ml: 4 }}
                    >
                      <IconButton
                        color="primary"
                        onClick={(e: MouseEvent<HTMLButtonElement>) => {
                          e.stopPropagation();
                          formik.resetForm();
                          setEditMode(!editMode);
                        }}
                      >
                        {!editMode ? <Edit /> : <CloseSquare />}
                      </IconButton>
                    </Tooltip>
                    {user.userId !== userId && (
                      <Tooltip
                        componentsProps={{
                          tooltip: {
                            sx: {
                              backgroundColor: mode === ThemeMode.DARK ? theme.palette.grey[50] : theme.palette.grey[700],
                              opacity: 0.9
                            }
                          }
                        }}
                        title="강제탈퇴"
                      >
                        <IconButton
                          color="error"
                          onClick={(e: MouseEvent<HTMLButtonElement>) => {
                            e.stopPropagation();
                            handleClose();
                          }}
                        >
                          <Trash />
                        </IconButton>
                      </Tooltip>
                    )}
                  </Box>
                </Stack>
              </>
            }
          >
            {company && (
              <>
                <Stack spacing={2.5} alignItems="center">
                  <Avatar
                    alt="회사 로고"
                    size="xl"
                    src={getImageUrl(company.companyLogoImage?.fileUuid)}
                    sx={{ width: '120px', height: '120px' }}
                  />
                  <Typography variant="h5">{company.companyName}</Typography>
                  {editMode && (
                    <Tooltip
                      componentsProps={{
                        tooltip: {
                          sx: {
                            backgroundColor: mode === ThemeMode.DARK ? theme.palette.grey[50] : theme.palette.grey[700],
                            opacity: 0.9
                          }
                        }
                      }}
                      title="회사 선택"
                    >
                      <IconButton color="primary" onClick={handleSelectCompanyDialogOpen}>
                        <Link2 />
                      </IconButton>
                    </Tooltip>
                  )}
                  <Typography color="secondary">{toCodeName(companyTypeCodes, company.companyTypeCode)}</Typography>
                </Stack>
                <Divider sx={{ mt: 2, mb: 2 }} />
              </>
            )}
            <Grid item xs={12}>
              {!company ? (
                <>
                  <Stack spacing={0.5} alignItems="center">
                    <Typography variant="h6">소속회사를 선택해주세요</Typography>
                    <Button variant="outlined" startIcon={<Link2 />} onClick={handleSelectCompanyDialogOpen} size="small">
                      회사 선택
                    </Button>
                  </Stack>
                </>
              ) : (
                <>
                  <List aria-label="main mailbox folders" sx={{ py: 0, '& .MuiListItemIcon-root': { minWidth: 32 } }}>
                    {/* 대표자명 */}
                    <ListItem>
                      <ListItemIcon style={{ display: 'flex', alignItems: 'center' }}>
                        <Personalcard size={18} style={{ marginRight: '3px' }} /> 대표자명
                      </ListItemIcon>
                      <ListItemSecondaryAction>
                        <Typography align="right">{company.representativeName}</Typography>
                      </ListItemSecondaryAction>
                    </ListItem>
                    {/* 회사번호 */}
                    <ListItem>
                      <ListItemIcon style={{ display: 'flex', alignItems: 'center' }}>
                        <Mobile size={18} style={{ marginLeft: '2px', marginRight: '3px' }} /> 회사번호
                      </ListItemIcon>
                      <ListItemSecondaryAction>
                        <Typography align="right">{company.telNumber}</Typography>
                      </ListItemSecondaryAction>
                    </ListItem>
                    {/* 회사주소 */}
                    <ListItem>
                      <ListItemIcon style={{ display: 'flex', alignItems: 'center' }}>
                        <Location size={18} style={{ marginRight: '3px' }} /> 회사주소
                      </ListItemIcon>
                    </ListItem>
                    {company.companyAddresses?.map((address, index) => (
                      <ListItem key={index}>
                        <TooltipTypography width={'300px'} sx={{ pl: 2 }}>
                          {address.postCode === '' ? '' : `(${address.postCode}) ${address.address} ${address.addressDetail}`}
                        </TooltipTypography>
                      </ListItem>
                    ))}
                    <ListItem>
                      <ListItemIcon style={{ display: 'flex', alignItems: 'center' }}>
                        <Location size={18} style={{ marginRight: '3px' }} /> 차고지
                      </ListItemIcon>
                    </ListItem>
                    {company.garageAddresses?.map((address, index) => (
                      <ListItem key={index}>
                        <TooltipTypography width={'300px'} sx={{ pl: 2 }}>
                          {address.postCode === '' ? '' : `(${address.postCode}) ${address.address} ${address.addressDetail}`}
                        </TooltipTypography>
                      </ListItem>
                    ))}
                    <ListItem>
                      <ListItemIcon style={{ display: 'flex', alignItems: 'center' }}>
                        <Link2 size={18} style={{ marginRight: '3px' }} /> 홈페이지
                      </ListItemIcon>
                      <ListItemSecondaryAction>
                        {company.webSite && (
                          <Link align="right" href={company.webSite} target="_blank" rel="noopener noreferrer">
                            {company.webSite}
                          </Link>
                        )}
                      </ListItemSecondaryAction>
                    </ListItem>
                  </List>
                </>
              )}
            </Grid>
          </MainCard>
        </Grid>
        <Grid item xs={12} md={3}>
          <MainCard title="명함 및 사업자등록증">
            <Stack spacing={0.5}>
              <InputLabel htmlFor="businesscardImages">명함</InputLabel>
              {editMode ? (
                <>
                  <DragAndDropUpload
                    realmGroupCode={RealmGroupCode.user_business_card.code}
                    height={300}
                    maxFiles={2}
                    defaultFileInformations={formik.values.businesscardImages}
                    name="businesscardImages"
                    onChange={(files: FileInformation[]) => {
                      formik.setFieldValue('businesscardImages', files);
                    }}
                  ></DragAndDropUpload>
                  {formik.touched.businesscardImages && formik.errors.businesscardImages && (
                    <FormHelperText error id="user-businesscardImages-helper">
                      {'명함(이미지)은 필수 사항입니다.'}
                    </FormHelperText>
                  )}
                </>
              ) : (
                <AlbumImageView
                  images={[..._.map(formik.values.businesscardImages, (image) => getImageUrl(image.fileUuid)!)]}
                  onImageClick={(index) => {
                    console.log(index);
                  }}
                ></AlbumImageView>
              )}
            </Stack>
            <Divider sx={{ mt: 2, mb: 2 }} />
            <Stack spacing={0.5}>
              <InputLabel htmlFor="businessRegistrationImages">사업자등록증</InputLabel>
              {editMode ? (
                <>
                  <DragAndDropUpload
                    realmGroupCode={RealmGroupCode.user_business_registration.code}
                    height={300}
                    maxFiles={1}
                    defaultFileInformations={formik.values.businessRegistrationImages}
                    id="businessRegistrationImages"
                    name="businessRegistrationImages"
                    onChange={(files: FileInformation[]) => {
                      formik.setFieldValue('businessRegistrationImages', files);
                    }}
                  ></DragAndDropUpload>
                  {formik.touched.businessRegistrationImages && formik.errors.businessRegistrationImages && (
                    <FormHelperText error id="user-businessRegistrationImages-helper">
                      {'사업자등록증(이미지)은 필수 사항입니다.'}
                    </FormHelperText>
                  )}
                </>
              ) : (
                <AlbumImageView
                  images={[..._.map(formik.values.businessRegistrationImages, (image) => getImageUrl(image.fileUuid)!)]}
                  currentIndex={0}
                ></AlbumImageView>
              )}
            </Stack>
          </MainCard>
        </Grid>
        <Grid item xs={12} md={6}>
          <form noValidate onSubmit={formik.handleSubmit}>
            <Stack spacing={2.5}>
              <MainCard
                title="회원정보"
                sx={{
                  '& .MuiCardHeader-root': {
                    padding: '12px 24px 12px 24px'
                  }
                }}
                secondary={
                  <>
                    <Stack direction={'row'} spacing={1.25} alignItems={'center'}>
                      <UserStatusChip userStatusCode={user.userStatusCode!} />
                    </Stack>
                  </>
                }
              >
                <Grid container spacing={3}>
                  <Grid item xs={12} md={6}>
                    <Stack spacing={0.5}>
                      <InputLabel htmlFor="loginName">로그인명</InputLabel>
                      <EditableTextField edit={false} value={user.loginName}></EditableTextField>
                    </Stack>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Stack spacing={0.5}>
                      <InputLabel htmlFor="loginTypeCode">회원 타입</InputLabel>
                      <EditableTextField
                        edit={false}
                        value={user.userTypeCode}
                        toName={() => code.toCodeName(CodeGroupName.UserTypeCode, user.userTypeCode ?? '')}
                      ></EditableTextField>
                    </Stack>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Stack spacing={0.5}>
                      <RequiredInputLabel editMode={editMode} required={true} htmlFor="name">
                        이름
                      </RequiredInputLabel>
                      <EditableTextField
                        id="name"
                        name="name"
                        edit={editMode}
                        value={formik.values.name}
                        onChange={formik.handleChange}
                      ></EditableTextField>
                      {formik.touched.name && formik.errors.name && (
                        <FormHelperText error id="user-name-helper">
                          {formik.errors.name}
                        </FormHelperText>
                      )}
                    </Stack>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Stack spacing={0.5}>
                      <RequiredInputLabel editMode={editMode} required={true} htmlFor="companyName">
                        회사명
                      </RequiredInputLabel>
                      <EditableTextField
                        edit={editMode}
                        id="companyName"
                        name="companyName"
                        value={formik.values.companyName}
                        onChange={formik.handleChange}
                      ></EditableTextField>
                      {formik.touched.companyName && formik.errors.companyName && (
                        <FormHelperText error id="user-companyName-helper">
                          {formik.errors.companyName}
                        </FormHelperText>
                      )}
                    </Stack>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Stack spacing={0.5}>
                      <RequiredInputLabel editMode={editMode} required={true} htmlFor="departmentName">
                        부서명
                      </RequiredInputLabel>
                      <EditableTextField
                        edit={editMode}
                        id="departmentName"
                        name="departmentName"
                        value={formik.values.departmentName}
                        onChange={formik.handleChange}
                      ></EditableTextField>
                      {formik.touched.departmentName && formik.errors.departmentName && (
                        <FormHelperText error id="user-departmentName-helper">
                          {formik.errors.departmentName}
                        </FormHelperText>
                      )}
                    </Stack>
                  </Grid>
                  {user.userTypeCode === UserTypeCode.agent.code && (
                    <>
                      <Grid item xs={12} md={6}>
                        <Stack spacing={0.5}>
                          <RequiredInputLabel editMode={editMode} required={false} htmlFor="unionYn">
                            AG 연합 여부
                          </RequiredInputLabel>
                          {editMode ? (
                            <FormControl>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={formik.values.unionYn === 'Y'}
                                    id="unionYn"
                                    name="unionYn"
                                    color="primary"
                                    onChange={(event) => {
                                      formik.setFieldValue(`unionYn`, event.target.checked ? 'Y' : 'N');
                                    }}
                                  />
                                }
                                label=""
                              ></FormControlLabel>
                            </FormControl>
                          ) : (
                            <Typography sx={{ fontWeight: 'bold', pl: 1 }}>{formik.values.unionYn}</Typography>
                          )}
                        </Stack>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <Stack spacing={0.5}>
                          <RequiredInputLabel editMode={editMode} required={false} htmlFor="unionName">
                            AG 연합 명(입력값)
                          </RequiredInputLabel>
                          <EditableTextField
                            edit={editMode}
                            id="unionName"
                            name="unionName"
                            value={formik.values.unionName}
                            onChange={formik.handleChange}
                          ></EditableTextField>
                          {formik.touched.unionName && formik.errors.unionName && (
                            <FormHelperText error id="user-unionName-helper">
                              {formik.errors.unionName}
                            </FormHelperText>
                          )}
                        </Stack>
                      </Grid>
                    </>
                  )}
                  <Grid item xs={12} md={6}>
                    <Stack spacing={0.5}>
                      <RequiredInputLabel editMode={editMode} required={true} htmlFor="positionName">
                        직급명
                      </RequiredInputLabel>
                      <EditableTextField
                        edit={editMode}
                        id="positionName"
                        name="positionName"
                        value={formik.values.positionName}
                        onChange={formik.handleChange}
                      ></EditableTextField>
                      {formik.touched.positionName && formik.errors.positionName && (
                        <FormHelperText error id="user-positionName-helper">
                          {formik.errors.positionName}
                        </FormHelperText>
                      )}
                    </Stack>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Stack spacing={0.5}>
                      <RequiredInputLabel editMode={editMode} required={true} htmlFor="telNumber">
                        전화번호
                      </RequiredInputLabel>
                      <PhoneNumberField
                        edit={editMode}
                        id="telNumber"
                        name="telNumber"
                        value={formik.values.telNumber}
                        onChange={formik.handleChange}
                      ></PhoneNumberField>
                      {formik.touched.telNumber && formik.errors.telNumber && (
                        <FormHelperText error id="user-telNumber-helper">
                          {formik.errors.telNumber}
                        </FormHelperText>
                      )}
                    </Stack>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Stack spacing={0.5}>
                      <RequiredInputLabel editMode={editMode} required={true} htmlFor="email">
                        이메일
                      </RequiredInputLabel>
                      <EditableTextField
                        edit={editMode}
                        id="email"
                        name="email"
                        value={formik.values.email}
                        onChange={formik.handleChange}
                      ></EditableTextField>
                      {formik.touched.email && formik.errors.email && (
                        <FormHelperText error id="user-email-helper">
                          {formik.errors.email}
                        </FormHelperText>
                      )}
                    </Stack>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Stack spacing={0.5}>
                      <InputLabel htmlFor="createDatetime">가입일자</InputLabel>
                      <EditableTextField
                        id="createDatetime"
                        name="createDatetime"
                        edit={false}
                        value={toDateString(user.createDatetime)}
                      ></EditableTextField>
                    </Stack>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Stack spacing={0.5}>
                      <InputLabel htmlFor="approvalDatetime">승인일자</InputLabel>
                      <EditableTextField
                        edit={false}
                        id="approvalDatetime"
                        name="approvalDatetime"
                        value={toDateString(user.approvalDatetime)}
                      ></EditableTextField>
                    </Stack>
                  </Grid>
                  {user.company?.companyTypeCode === CompanyTypeCode.rent25.code && (
                    <Grid item xs={12} sm={6}>
                      <Stack spacing={1.25}>
                        <RequiredInputLabel
                          editMode={editMode}
                          required={user.company?.companyTypeCode === CompanyTypeCode.rent25.code}
                          htmlFor="slidingFeeRate"
                        >
                          슬라이딩 수수료
                        </RequiredInputLabel>
                        <EditableNumericFormat
                          edit={editMode}
                          size="small"
                          value={formik.values.slidingFeeRate ?? 0}
                          onValueChange={(values: any) => {
                            let value = values.value;
                            formik.setFieldValue(`slidingFeeRate`, value);
                          }}
                          customInput={TextField}
                          thousandSeparator=","
                          InputProps={{ endAdornment: '%' }}
                          inputProps={{ style: { textAlign: 'right' } }}
                        />
                        {formik.touched.slidingFeeRate && formik.errors.slidingFeeRate && (
                          <FormHelperText error id="company-slidingFeeRate-helper">
                            {formik.errors.slidingFeeRate as string}
                          </FormHelperText>
                        )}
                      </Stack>
                    </Grid>
                  )}
                  <Grid item xs={12}>
                    <Stack spacing={0.5}>
                      <InputLabel htmlFor="requestContent">요청 메시지</InputLabel>
                      <EditableTextField
                        edit={false}
                        fullWidth
                        rows={3}
                        maxRows={5}
                        id="requestContent"
                        name="requestContent"
                        value={formik.values.requestContent}
                        onChange={formik.handleChange}
                        placeholder="요청 메시지"
                      />
                    </Stack>
                    {formik.touched.requestContent && formik.errors.requestContent && (
                      <FormHelperText error id="user-requestContent-helper">
                        {formik.errors.requestContent}
                      </FormHelperText>
                    )}
                  </Grid>
                  {(user.userStatusHistories?.length ?? 0) > 0 && (
                    <Grid item xs={12}>
                      <Stack spacing={0.5}>
                        <InputLabel htmlFor="requestContent">상태 히스토리</InputLabel>
                        {user.userStatusHistories?.map((history) => (
                          <>
                            <Stack direction={'row'} spacing={2}>
                              <Typography sx={{ fontWeight: 'bold' }}>
                                {toDateFormatString(history.createDatetime, 'yyyy-MM-dd hh:mm')}
                              </Typography>
                              <Typography sx={{ fontWeight: 'bold' }}>{toValue(UserStatusCode, history.userStatusCode).name}</Typography>
                              <Typography>
                                {(history.statusChangeWhy === undefined || history.statusChangeWhy) === ''
                                  ? '메시지 없음'
                                  : history.statusChangeWhy}
                              </Typography>
                            </Stack>
                          </>
                        ))}
                      </Stack>
                      {formik.touched.requestContent && formik.errors.requestContent && (
                        <FormHelperText error id="user-requestContent-helper">
                          {formik.errors.requestContent}
                        </FormHelperText>
                      )}
                    </Grid>
                  )}
                </Grid>
              </MainCard>
              {formik.touched.companyId && formik.errors.companyId && (
                <FormHelperText error id="user-companyId-helper">
                  {formik.errors.companyId}
                </FormHelperText>
              )}
            </Stack>
            {editMode && (
              <>
                <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2} sx={{ mt: 2.5 }}>
                  <>
                    {user.userId !== userId ? (
                      <Box>
                        {UserStatusCode.request.code === user.userStatusCode && (
                          <Button color="error" variant="contained" onClick={handleReject} sx={{ ml: 2.5 }}>
                            거절
                          </Button>
                        )}
                        {UserStatusCode.approved.code !== user.userStatusCode && (
                          <Button color="success" variant="contained" onClick={handleApproval} sx={{ ml: 2.5 }}>
                            승인
                          </Button>
                        )}
                        {UserStatusCode.approved.code === user.userStatusCode && (
                          <Button color="error" variant="contained" onClick={handleSuspension} sx={{ ml: 2.5 }}>
                            정지
                          </Button>
                        )}
                      </Box>
                    ) : (
                      <Box></Box>
                    )}
                  </>
                  <Box>
                    {editMode && user.userId && (
                      <Button
                        variant="outlined"
                        color="secondary"
                        onClick={() => {
                          formik.resetForm();
                          setEditMode(false);
                        }}
                      >
                        취소
                      </Button>
                    )}
                    <Button
                      variant="contained"
                      onClick={() => {
                        handleSubmit('save');
                      }}
                      sx={{ ml: 2.5 }}
                    >
                      저장
                    </Button>
                  </Box>
                </Stack>
              </>
            )}
          </form>
        </Grid>
      </Grid>
      <SelectCompanyDialog companyTypeCode={''} open={dialogOpen} onClose={handleDialogClose} />
      <AlertUserDelete userName={user.name} open={open} handleClose={handleClose} />
      <ConfirmDialog
        title={confirmConfig.title}
        subtitle={confirmConfig.subtitle}
        isContent={confirmConfig.isContent}
        color={confirmConfig.color}
        open={confirmConfig.open}
        onConfirm={confirmConfig.onConfirm}
      ></ConfirmDialog>
    </>
  );
};

export default UserInformationSection;
