import { FileInformation } from 'openapi';
import { fileServiceApi } from './axios';

export const downloadFile = (file: FileInformation) => {
  fileServiceApi.downloadFile(file.fileUuid!).then((response) => {
    const url = window.URL.createObjectURL(new Blob([response.data], {}));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', file.originalFileName ?? file.fileUuid!); // or any other extension
    document.body.appendChild(link);
    link.click();
    link.remove();
  });
};
