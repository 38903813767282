/* tslint:disable */
/* eslint-disable */
/**
 * Rent25 API 가이드
 * Rent25 API 가이드 문서입니다.
 *
 * The version of the OpenAPI document: v0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
// @ts-ignore
import { ChangePasswordRequest } from '../models';
// @ts-ignore
import { ChangePasswordResponse } from '../models';
// @ts-ignore
import { ErrorResponse } from '../models';
// @ts-ignore
import { InitPasswordRequest } from '../models';
// @ts-ignore
import { InitPasswordResponse } from '../models';
// @ts-ignore
import { SigninRequest } from '../models';
// @ts-ignore
import { SigninResponse } from '../models';
// @ts-ignore
import { ValidTokenRequest } from '../models';
// @ts-ignore
import { ValidTokenResponse } from '../models';
/**
 * AuthServiceApi - axios parameter creator
 * @export
 */
export const AuthServiceApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 비밀번호 변경 요청
         * @summary 비밀번호 변경 요청
         * @param {ChangePasswordRequest} changePasswordRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changePassword: async (changePasswordRequest: ChangePasswordRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'changePasswordRequest' is not null or undefined
            assertParamExists('changePassword', 'changePasswordRequest', changePasswordRequest)
            const localVarPath = `/apis/v1/auth/password/change`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(changePasswordRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 비밀번호 초기화 요청
         * @summary 비밀번호 초기화 요청
         * @param {InitPasswordRequest} initPasswordRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        initPassword: async (initPasswordRequest: InitPasswordRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'initPasswordRequest' is not null or undefined
            assertParamExists('initPassword', 'initPasswordRequest', initPasswordRequest)
            const localVarPath = `/apis/v1/auth/password/init`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(initPasswordRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 사용자 로그인 요청
         * @summary 사용자 로그인 요청
         * @param {SigninRequest} signinRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        signin: async (signinRequest: SigninRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'signinRequest' is not null or undefined
            assertParamExists('signin', 'signinRequest', signinRequest)
            const localVarPath = `/apis/v1/auth/signin`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(signinRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 토큰 유효성 검사
         * @summary 토큰 유효성 검사
         * @param {ValidTokenRequest} validTokenRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validToken: async (validTokenRequest: ValidTokenRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'validTokenRequest' is not null or undefined
            assertParamExists('validToken', 'validTokenRequest', validTokenRequest)
            const localVarPath = `/apis/v1/auth/token/valid`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(validTokenRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AuthServiceApi - functional programming interface
 * @export
 */
export const AuthServiceApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AuthServiceApiAxiosParamCreator(configuration)
    return {
        /**
         * 비밀번호 변경 요청
         * @summary 비밀번호 변경 요청
         * @param {ChangePasswordRequest} changePasswordRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async changePassword(changePasswordRequest: ChangePasswordRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ChangePasswordResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.changePassword(changePasswordRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AuthServiceApi.changePassword']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 비밀번호 초기화 요청
         * @summary 비밀번호 초기화 요청
         * @param {InitPasswordRequest} initPasswordRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async initPassword(initPasswordRequest: InitPasswordRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InitPasswordResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.initPassword(initPasswordRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AuthServiceApi.initPassword']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 사용자 로그인 요청
         * @summary 사용자 로그인 요청
         * @param {SigninRequest} signinRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async signin(signinRequest: SigninRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SigninResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.signin(signinRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AuthServiceApi.signin']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 토큰 유효성 검사
         * @summary 토큰 유효성 검사
         * @param {ValidTokenRequest} validTokenRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async validToken(validTokenRequest: ValidTokenRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ValidTokenResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.validToken(validTokenRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AuthServiceApi.validToken']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * AuthServiceApi - factory interface
 * @export
 */
export const AuthServiceApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AuthServiceApiFp(configuration)
    return {
        /**
         * 비밀번호 변경 요청
         * @summary 비밀번호 변경 요청
         * @param {ChangePasswordRequest} changePasswordRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changePassword(changePasswordRequest: ChangePasswordRequest, options?: any): AxiosPromise<ChangePasswordResponse> {
            return localVarFp.changePassword(changePasswordRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 비밀번호 초기화 요청
         * @summary 비밀번호 초기화 요청
         * @param {InitPasswordRequest} initPasswordRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        initPassword(initPasswordRequest: InitPasswordRequest, options?: any): AxiosPromise<InitPasswordResponse> {
            return localVarFp.initPassword(initPasswordRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 사용자 로그인 요청
         * @summary 사용자 로그인 요청
         * @param {SigninRequest} signinRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        signin(signinRequest: SigninRequest, options?: any): AxiosPromise<SigninResponse> {
            return localVarFp.signin(signinRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 토큰 유효성 검사
         * @summary 토큰 유효성 검사
         * @param {ValidTokenRequest} validTokenRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validToken(validTokenRequest: ValidTokenRequest, options?: any): AxiosPromise<ValidTokenResponse> {
            return localVarFp.validToken(validTokenRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AuthServiceApi - object-oriented interface
 * @export
 * @class AuthServiceApi
 * @extends {BaseAPI}
 */
export class AuthServiceApi extends BaseAPI {
    /**
     * 비밀번호 변경 요청
     * @summary 비밀번호 변경 요청
     * @param {ChangePasswordRequest} changePasswordRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthServiceApi
     */
    public changePassword(changePasswordRequest: ChangePasswordRequest, options?: RawAxiosRequestConfig) {
        return AuthServiceApiFp(this.configuration).changePassword(changePasswordRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 비밀번호 초기화 요청
     * @summary 비밀번호 초기화 요청
     * @param {InitPasswordRequest} initPasswordRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthServiceApi
     */
    public initPassword(initPasswordRequest: InitPasswordRequest, options?: RawAxiosRequestConfig) {
        return AuthServiceApiFp(this.configuration).initPassword(initPasswordRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 사용자 로그인 요청
     * @summary 사용자 로그인 요청
     * @param {SigninRequest} signinRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthServiceApi
     */
    public signin(signinRequest: SigninRequest, options?: RawAxiosRequestConfig) {
        return AuthServiceApiFp(this.configuration).signin(signinRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 토큰 유효성 검사
     * @summary 토큰 유효성 검사
     * @param {ValidTokenRequest} validTokenRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthServiceApi
     */
    public validToken(validTokenRequest: ValidTokenRequest, options?: RawAxiosRequestConfig) {
        return AuthServiceApiFp(this.configuration).validToken(validTokenRequest, options).then((request) => request(this.axios, this.basePath));
    }
}

