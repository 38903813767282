/* tslint:disable */
/* eslint-disable */
/**
 * Rent25 API 가이드
 * Rent25 API 가이드 문서입니다.
 *
 * The version of the OpenAPI document: v0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
// @ts-ignore
import { CarBrandsResponse } from '../models';
// @ts-ignore
import { CarModelResponse } from '../models';
// @ts-ignore
import { CarModelsResponse } from '../models';
// @ts-ignore
import { ErrorResponse } from '../models';
/**
 * CarServiceApi - axios parameter creator
 * @export
 */
export const CarServiceApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 모델 정보 조회
         * @summary 모델 정보 조회
         * @param {string} brandCode 
         * @param {string} modelCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getModel: async (brandCode: string, modelCode: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'brandCode' is not null or undefined
            assertParamExists('getModel', 'brandCode', brandCode)
            // verify required parameter 'modelCode' is not null or undefined
            assertParamExists('getModel', 'modelCode', modelCode)
            const localVarPath = `/apis/v1/car/brands/{brandCode}/models/{modelCode}`
                .replace(`{${"brandCode"}}`, encodeURIComponent(String(brandCode)))
                .replace(`{${"modelCode"}}`, encodeURIComponent(String(modelCode)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 자동차 브랜드 목록
         * @summary 자동차 브랜드 목록
         * @param {string} [brandTypeCode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        selectBrands: async (brandTypeCode?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/apis/v1/car/brands`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (brandTypeCode !== undefined) {
                localVarQueryParameter['brandTypeCode'] = brandTypeCode;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 자동차 모델 목록
         * @summary 자동차 모델 목록
         * @param {string} brandCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        selectModels: async (brandCode: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'brandCode' is not null or undefined
            assertParamExists('selectModels', 'brandCode', brandCode)
            const localVarPath = `/apis/v1/car/brands/{brandCode}/models`
                .replace(`{${"brandCode"}}`, encodeURIComponent(String(brandCode)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CarServiceApi - functional programming interface
 * @export
 */
export const CarServiceApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CarServiceApiAxiosParamCreator(configuration)
    return {
        /**
         * 모델 정보 조회
         * @summary 모델 정보 조회
         * @param {string} brandCode 
         * @param {string} modelCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getModel(brandCode: string, modelCode: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CarModelResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getModel(brandCode, modelCode, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CarServiceApi.getModel']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 자동차 브랜드 목록
         * @summary 자동차 브랜드 목록
         * @param {string} [brandTypeCode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async selectBrands(brandTypeCode?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CarBrandsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.selectBrands(brandTypeCode, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CarServiceApi.selectBrands']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 자동차 모델 목록
         * @summary 자동차 모델 목록
         * @param {string} brandCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async selectModels(brandCode: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CarModelsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.selectModels(brandCode, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CarServiceApi.selectModels']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * CarServiceApi - factory interface
 * @export
 */
export const CarServiceApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CarServiceApiFp(configuration)
    return {
        /**
         * 모델 정보 조회
         * @summary 모델 정보 조회
         * @param {string} brandCode 
         * @param {string} modelCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getModel(brandCode: string, modelCode: string, options?: any): AxiosPromise<CarModelResponse> {
            return localVarFp.getModel(brandCode, modelCode, options).then((request) => request(axios, basePath));
        },
        /**
         * 자동차 브랜드 목록
         * @summary 자동차 브랜드 목록
         * @param {string} [brandTypeCode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        selectBrands(brandTypeCode?: string, options?: any): AxiosPromise<CarBrandsResponse> {
            return localVarFp.selectBrands(brandTypeCode, options).then((request) => request(axios, basePath));
        },
        /**
         * 자동차 모델 목록
         * @summary 자동차 모델 목록
         * @param {string} brandCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        selectModels(brandCode: string, options?: any): AxiosPromise<CarModelsResponse> {
            return localVarFp.selectModels(brandCode, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CarServiceApi - object-oriented interface
 * @export
 * @class CarServiceApi
 * @extends {BaseAPI}
 */
export class CarServiceApi extends BaseAPI {
    /**
     * 모델 정보 조회
     * @summary 모델 정보 조회
     * @param {string} brandCode 
     * @param {string} modelCode 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CarServiceApi
     */
    public getModel(brandCode: string, modelCode: string, options?: RawAxiosRequestConfig) {
        return CarServiceApiFp(this.configuration).getModel(brandCode, modelCode, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 자동차 브랜드 목록
     * @summary 자동차 브랜드 목록
     * @param {string} [brandTypeCode] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CarServiceApi
     */
    public selectBrands(brandTypeCode?: string, options?: RawAxiosRequestConfig) {
        return CarServiceApiFp(this.configuration).selectBrands(brandTypeCode, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 자동차 모델 목록
     * @summary 자동차 모델 목록
     * @param {string} brandCode 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CarServiceApi
     */
    public selectModels(brandCode: string, options?: RawAxiosRequestConfig) {
        return CarServiceApiFp(this.configuration).selectModels(brandCode, options).then((request) => request(this.axios, this.basePath));
    }
}

