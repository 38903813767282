import { ConfirmProps } from 'components/dialog/ContextConfirmDialog';
import React, { createContext, useContext, useState, ReactNode } from 'react';

interface DialogContextType {
  isOpen: boolean;
  confirmProps: ConfirmProps;
  showDialog: (confirmProps: ConfirmProps) => void;
  onConfirm: (yesNo: boolean, content?: any) => void;
}

const DialogContext = createContext<DialogContextType | undefined>(undefined);

export const DialogProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [confirmProps, setConfirmProps] = useState<ConfirmProps>({ title: '', onConfirm: () => {} });

  const showDialog = (props: ConfirmProps) => {
    setIsOpen(true);
    setConfirmProps(props);
  };

  const onConfirm = (yesNo: boolean, content?: any) => {
    setIsOpen(false);
    confirmProps.onConfirm(yesNo, content);
  };

  return <DialogContext.Provider value={{ isOpen, confirmProps, showDialog, onConfirm }}>{children}</DialogContext.Provider>;
};

export const useDialog = (): DialogContextType => {
  const context = useContext(DialogContext);
  if (!context) {
    throw new Error('useDialog must be used within a DialogProvider');
  }
  return context;
};
