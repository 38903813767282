import { Personalcard, Setting3, Task } from 'iconsax-react';

export type CodeValue = {
  code: string;
  name: string;
  path?: string;
  type?: string;
  step?: boolean;
  to?: string;
  icon?: any;
};

export type CodeType = {
  [key: string]: CodeValue;
};

export enum CodeGroupName {
  RealmGroupCode = 'RealmGroupCode',
  UserStatusCode = 'UserStatusCode',
  UserTypeCode = 'UserTypeCode',
  CompanyTypeCode = 'CompanyTypeCode',
  FileTypeCode = 'FileTypeCode',
  LoginTypeCode = 'LoginTypeCode',
  AddressTypeCode = 'AddressTypeCode',
  CarStatusCode = 'CarStatusCode',
  EstimateStatusCode = 'EstimateStatusCode',
  VehicleTypeCode = 'VehicleTypeCode',
  PdsTypeCode = 'PdsTypeCode'
}

export const Deposits = [5, 7, 10, 15, 20, 25, 30];

export const BbsType = {
  notice: { name: '공지사항', code: 'NOTICE' },
  pds: { name: '자료실', code: 'PDS' }
};

export const InstallmentMonth = {
  12: { month: 12 },
  24: { month: 24 },
  36: { month: 36 },
  48: { month: 48 },
  60: { month: 60 }
};

export const EngagementDistance = {
  20000: { distance: 20000 },
  30000: { distance: 30000 },
  50000: { distance: 50000 }
};

export const RealmGroupCode: CodeType = {
  user_business_card: { name: '사용자 명함 이미지', code: 'C01' },
  user_business_registration: { name: '사용자 사업자 등록증', code: 'C02' },
  company_business_registration: { name: '업체 사업자 등록증', code: 'C03' },
  company_logo: { name: '업체 로고', code: 'C04' },
  car_image: { name: '자동차 이미지', code: 'C05' },
  estimate_additional_driver1_license: { name: '제1기명 운전면허증', code: 'C06' },
  estimate_additional_driver2_license: { name: '제2기명 운전면허증', code: 'C07' },
  estimate_evidentiary_documents: { name: '약정 증빙 서류', code: 'C08' },
  banners: { name: 'Banner 이미지', code: 'C09' },
  pds_attach_files: { name: '자료실 파일', code: 'C10' }
};

export const UserStatusCode = {
  request: { name: '신청', code: 'C01' },
  approved: { name: '승인', code: 'C02' },
  rejected: { name: '거절', code: 'C03' },
  suspension: { name: '정지', code: 'C04' },
  deleted: { name: '탈퇴', code: 'C09' }
};

export const UserTypeCode = {
  rent25: { name: '렌트25', code: 'C01' },
  rent_car: { name: '렌트카', code: 'C02' },
  agent: { name: 'AG', code: 'C03' },
  agent_union: { name: 'AG연합', code: 'C04' },
  dealership: { name: '대리점', code: 'C05' }
};

export const CompanyTypeCode = {
  rent25: { name: '렌트25', code: 'C01', path: 'rent25' },
  rentcar: { name: '렌트카', code: 'C02', path: 'rentcar' },
  agent: { name: 'AG', code: 'C03', path: 'agent' },
  agentUnion: { name: 'AG연합', code: 'C04', path: 'agent-union' },
  dealership: { name: '대리점', code: 'C05', path: 'dealership' }
};

export const VehicleTypeCode = {
  car: { name: '승용차', code: 'CAR' },
  van: { name: '승합차', code: 'VAN' },
  bus: { name: '다인승', code: 'BUS' }
};

export enum FileTypeCode {
  image = 'IMAGE' // '이미지'
}

export enum LoginTypeCode {
  id = 'ID', // '아이디',
  kakao = 'KAKAO', // '카카오',
  google = 'GOOGLE' // '구글'
}

export enum AddressTypeCode {
  garage = 'C01', // '차고지',
  company = 'C02' // '회사'
}

export const CarTypeCode = {
  newCar: { name: '신차', code: 'C01' }
  // usedCar: { name: '중고차', code: 'C02' },
  // dealerCar: { name: '대리점', code: 'C03' }
};

export const BrandTypeCode = {
  domestic: { name: '국산', code: 'domestic' },
  import: { name: '수입', code: 'import' }
};

export const SupplyTypeCode = {
  frontTinting: { name: '전면선팅', code: 'C01' },
  backTinting: { name: '측후면선팅', code: 'C02' },
  blackBox: { name: '블랙박스', code: 'C03' },
  additional: { name: '추가용품', code: 'C04' }
};

export const DeliveryMethodCode = {
  special: { name: '특판', code: 'C01' },
  dealership: { name: '대리점', code: 'C02' }
};

export const ConsignmentMethodCode = {
  factoryDirectShipping: { name: '제조사+로드탁송', code: 'C01' }
};

export const DriverPossibleAgeType = {
  21: { name: '21세 이상', age: 21, code: 21 },
  26: { name: '26세 이상', age: 26, code: 26 }
};

export const SubstituteAmountType = {
  1: { name: '1억', code: 1 },
  2: { name: '2억', code: 2 },
  3: { name: '3억', code: 3 }
};

export const ExceptedAmountType = {
  20: { name: '20만원', code: 20 },
  30: { name: '30만원', code: 30 },
  50: { name: '50만원', code: 50 }
};

export const DriverTypeCode = {
  individual: {
    name: '개인',
    code: 'C01',
    addDriverTypes: [
      { name: '직계가족 1인', code: 'C01' },
      { name: '누구나 1인', code: 'C02' }
    ]
  },
  privateBusiness: {
    name: '개인사업자',
    code: 'C02',
    addDriverTypes: [
      { name: '직계가족 1인', code: 'C01' },
      { name: '직원 1인', code: 'C03' }
    ]
  },
  corporateBusiness: {
    name: '법인',
    code: 'C03',
    addDriverTypes: [{ name: '본인외 직원 1인', code: 'C04' }]
  }
} as const;

export const GoodsTypeCode = {
  long: {
    name: '장기렌트',
    code: 'C01'
  },
  short: {
    name: '단기렌트',
    code: 'C02'
  }
};

export const ExpirationTypeCode = {
  choice: {
    name: '선택형',
    code: 'C01'
  },
  fix: {
    name: '인수형',
    code: 'C02'
  }
};

export const SaveTypeCode = {
  use: {
    name: '사용',
    code: 'USE'
  },
  backup: {
    name: '백업',
    code: 'BACKUP'
  }
};

export const DocumentTypeCode = {
  termsOfService: {
    name: '서비스 약관',
    code: 'DOC_01'
  },
  privacyPolicy: {
    name: '개인정보 보호정책',
    code: 'DOC_02'
  }
};

export const searchAreas = [
  '서울',
  '부산',
  '대구',
  '인천',
  '광주',
  '대전',
  '울산',
  '세종',
  '경기',
  '강원',
  '충북',
  '충남',
  '전북',
  '전남',
  '경북',
  '경남',
  '제주'
];

export const CarStatusCode: CodeType = {
  // temporary: { code: 'C01', name: '가등록' },
  sale: { code: 'C02', name: '렌트가능' },
  hold: { code: 'C03', name: '렌트보류' },
  reservation: { code: 'C04', name: '선점중' },
  contract: { code: 'C05', name: '계약진행' },
  completion: { code: 'C06', name: '계약완료' },
  rent_expired: { code: 'C07', name: '계약만료' },
  suspension: { code: 'C09', name: '중도반납' }
};

export const CarActionTypeCode: CodeType = {
  ...CarStatusCode,
  registration: { code: 'C90', name: '차량등록' },
  view: { code: 'C91', name: '정보보기' },
  update: { code: 'C92', name: '정보수정' },
  estimate_registration: { code: 'C93', name: '견적내기' },
  used_car_registration: { code: 'C94', name: '중고차등록' },
  simulation: { code: 'C95', name: '견적내기' }
};

export const EstimateStatusCode: CodeType = {
  temporary: { code: 'C01', name: '가견적', type: 'temporary', step: false },
  reservation_request: { code: 'C02', name: '선점요청', type: 'reservation', step: true },
  reservation_complete: { code: 'C03', name: '선점확정', type: 'reservation', step: true },
  reservation_reject: { code: 'C04', name: '선점반려', type: 'reservation' },
  reservation_cancel: { code: 'C05', name: '선점취소', type: 'reservation' },
  contract_request: { code: 'C11', name: '약정요청', type: 'contract', step: true },
  contract_confirm: { code: 'C12', name: '약정확인', type: 'contract', step: true },
  contract_processing: { code: 'C13', name: '약정진행', type: 'contract', step: true },
  contract_complete: { code: 'C14', name: '약정완료', type: 'contract', step: true },
  contract_cancel: { code: 'C15', name: '약정취소', type: 'contract' },
  contract_destroy: { code: 'C16', name: '약정파기', type: 'contract' },
  consignment_request: { code: 'C21', name: '탁송요청', type: 'consignment', step: true },
  consignment_complete: { code: 'C22', name: '탁송완료', type: 'consignment', step: true }
};

export const EstimateActionTypeCode: CodeType = {
  ...EstimateStatusCode,
  estimate_update: { code: 'C90', name: '견적수정' },
  view: { code: 'C91', name: '약정정보' },
  update: { code: 'C92', name: '정보수정' },
  contract_template_download: { code: 'C93', name: '다운로드' },
  virtual_accounts_request: { code: 'C94', name: '가상계좌' }
};

export const EstimateSetupTypeCode: CodeType = {
  vehiclePriceGroup: {
    name: '차량 가격별 그룹설정',
    code: 'C01',
    to: 'estimate/setup/vehicle-price-group',
    icon: <Personalcard />
  },
  deposit: { name: '보증금 설정', code: 'C02', to: 'estimate/setup/deposit', icon: <Setting3 /> },
  carResidualRate: { name: ' 그룹별 잔가율 설정', code: 'C03', to: 'estimate/setup/residual-value', icon: <Setting3 /> },
  carResidualGroup: { name: '차량 코드 및 잔가 그룹 설정', code: 'C08', to: 'estimate/setup/residual-group', icon: <Setting3 /> },
  addRentalFee: { name: '가산렌탈료 설정', code: 'C04', to: 'estimate/setup/add-rental-fee', icon: <Setting3 /> },
  rentalArea: { name: '렌트지역/탁송료 설정', code: 'C05', to: 'estimate/setup/rental-area', icon: <Setting3 /> },
  supply: { name: '용품 설정', code: 'C06', to: 'estimate/setup/supply', icon: <Task /> },
  etc: { name: '기타 설정', code: 'C07', to: 'estimate/setup/etc', icon: <Setting3 /> }
} as const;

export const alertSnackbarConfig = {
  open: true,
  message: '메시지 없음',
  position: 'top',
  variant: 'alert',
  transition: 'SlideDown',
  anchorOrigin: { vertical: 'top', horizontal: 'center' },
  alert: {
    color: 'success'
  },
  close: true
};

export const alertWarningSnackbarConfig = {
  open: true,
  message: '메시지 없음',
  position: 'top',
  variant: 'alert',
  transition: 'SlideDown',
  anchorOrigin: { vertical: 'top', horizontal: 'center' },
  alert: {
    color: 'warning'
  },
  close: true
};

export const alertErrorSnackbarConfig = {
  open: true,
  message: '메시지 없음',
  position: 'top',
  variant: 'alert',
  transition: 'SlideDown',
  anchorOrigin: { vertical: 'top', horizontal: 'center' },
  alert: {
    color: 'error'
  },
  close: true
};

export const bookmark = {
  rent25: [
    {
      menuName: '회원 목록',
      menuLink: '/rent25/user/list',
      fromAlpha: 0.1,
      toAlpha: 0.1
    },
    {
      menuName: '업체 목록',
      menuLink: '/rent25/company/list',
      fromAlpha: 0.3,
      toAlpha: 0.3
    },
    {
      menuName: '신차 견적 설정',
      menuLink: '/rent25/estimate/setup/vehicle-price-group',
      fromAlpha: 0.5,
      toAlpha: 0.5
    },
    {
      menuName: '중고 견적 설정',
      menuLink: '#',
      fromAlpha: 0.7,
      toAlpha: 0.7
    },
    {
      menuName: '렌트 견적',
      menuLink: '/rent25/estimate/simulation',
      fromAlpha: 0.1,
      toAlpha: 0.1
    },
    {
      menuName: '신차 선구매',
      menuLink: '/rent25/car/new/list',
      fromAlpha: 0.3,
      toAlpha: 0.3
    },
    {
      menuName: '중고 선구매',
      menuLink: '#',
      fromAlpha: 0.5,
      toAlpha: 0.5
    },
    {
      menuName: '정산 관리',
      menuLink: '#',
      fromAlpha: 0.7,
      toAlpha: 0.7
    }
  ],
  rentcar: [
    {
      menuName: '신차 차량 등록',
      menuLink: '/rentcar/car/new/registration',
      fromAlpha: 0.1,
      toAlpha: 0.1
    },
    {
      menuName: '신차 견적 설정',
      menuLink: '/rentcar/estimate/setup/vehicle-price-group',
      fromAlpha: 0.5,
      toAlpha: 0.5
    },
    {
      menuName: '중고 차량 등록',
      menuLink: '#',
      fromAlpha: 0.3,
      toAlpha: 0.3
    },
    {
      menuName: '중고 견적 설정',
      menuLink: '#',
      fromAlpha: 0.7,
      toAlpha: 0.7
    },
    {
      menuName: '렌트 견적',
      menuLink: '/rentcar/estimate/simulation',
      fromAlpha: 0.1,
      toAlpha: 0.1
    },
    {
      menuName: '신차 선구매',
      menuLink: '/rentcar/car/new/list',
      fromAlpha: 0.3,
      toAlpha: 0.3
    },
    {
      menuName: '중고 선구매',
      menuLink: '#',
      fromAlpha: 0.5,
      toAlpha: 0.5
    },
    {
      menuName: '정산 관리',
      menuLink: '#',
      fromAlpha: 0.7,
      toAlpha: 0.7
    }
  ],
  agent: [
    {
      menuName: '렌트 견적',
      menuLink: '/agent/estimate/simulation',
      fromAlpha: 0.1,
      toAlpha: 0.1
    },
    {
      menuName: '신차 선구매',
      menuLink: '/agent/car/new/list',
      fromAlpha: 0.3,
      toAlpha: 0.3
    },
    {
      menuName: '중고 선구매',
      menuLink: '#',
      fromAlpha: 0.5,
      toAlpha: 0.5
    },
    {
      menuName: '정산 관리',
      menuLink: '#',
      fromAlpha: 0.7,
      toAlpha: 0.7
    }
  ],
  agentUnion: [
    {
      menuName: '에이전트 목록',
      menuLink: '/agent-union/company/list',
      fromAlpha: 0.1,
      toAlpha: 0.1
    },
    {
      menuName: '신차 선구매',
      menuLink: '/agent-union/car/new/list',
      fromAlpha: 0.3,
      toAlpha: 0.3
    },
    {
      menuName: '중고 선구매',
      menuLink: '#',
      fromAlpha: 0.5,
      toAlpha: 0.5
    },
    {
      menuName: '정산 관리',
      menuLink: '#',
      fromAlpha: 0.7,
      toAlpha: 0.7
    }
  ]
};

export function toKeys(enumType: any): string[] {
  return Object.keys(enumType);
}

export function toValues(enumType: any): any[] {
  return Object.values(enumType);
}

export function toValue(enumType: any, code: number | string | undefined): any | undefined {
  return toValues(enumType).find((value: any) => value.code === code);
}
