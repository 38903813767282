/* tslint:disable */
/* eslint-disable */
/**
 * Rent25 API 가이드
 * Rent25 API 가이드 문서입니다.
 *
 * The version of the OpenAPI document: v0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
// @ts-ignore
import { DeleteFilesRequest } from '../models';
// @ts-ignore
import { DeleteFilesResponse } from '../models';
// @ts-ignore
import { DownloadZipRequest } from '../models';
// @ts-ignore
import { ErrorResponse } from '../models';
// @ts-ignore
import { ImageListRequest } from '../models';
// @ts-ignore
import { ImageListResponse } from '../models';
// @ts-ignore
import { UploadFilesResponse } from '../models';
/**
 * FileServiceApi - axios parameter creator
 * @export
 */
export const FileServiceApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 파일을 삭제한다. 
         * @summary 파일 삭제
         * @param {DeleteFilesRequest} deleteFilesRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteFiles: async (deleteFilesRequest: DeleteFilesRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'deleteFilesRequest' is not null or undefined
            assertParamExists('deleteFiles', 'deleteFilesRequest', deleteFilesRequest)
            const localVarPath = `/apis/v1/common/files`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(deleteFilesRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * fileUUID로 파일을 다운로드한다. 
         * @summary 파일 다운로드
         * @param {string} fileUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadFile: async (fileUuid: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'fileUuid' is not null or undefined
            assertParamExists('downloadFile', 'fileUuid', fileUuid)
            const localVarPath = `/apis/v1/common/files/{fileUuid}`
                .replace(`{${"fileUuid"}}`, encodeURIComponent(String(fileUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 여러 파일을 ZIP 파일로 다운로드
         * @summary 파일 다운로드
         * @param {DownloadZipRequest} downloadZipRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadZip: async (downloadZipRequest: DownloadZipRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'downloadZipRequest' is not null or undefined
            assertParamExists('downloadZip', 'downloadZipRequest', downloadZipRequest)
            const localVarPath = `/apis/v1/common/files/download-zip`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(downloadZipRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * fileUUID의 이미지 파일 
         * @summary 이미지파일
         * @param {string} fileUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        imageFile: async (fileUuid: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'fileUuid' is not null or undefined
            assertParamExists('imageFile', 'fileUuid', fileUuid)
            const localVarPath = `/apis/v1/common/files/image/{fileUuid}`
                .replace(`{${"fileUuid"}}`, encodeURIComponent(String(fileUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 이미지 목록 조회
         * @summary 이미지 목록
         * @param {ImageListRequest} imageListRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        imageList: async (imageListRequest: ImageListRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'imageListRequest' is not null or undefined
            assertParamExists('imageList', 'imageListRequest', imageListRequest)
            const localVarPath = `/apis/v1/common/files/image-list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(imageListRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 파일을 멀티 업로드한다.
         * @summary 파일 멀티 업로드
         * @param {string} realmGroupCode 파일 타입 그룹 코드
         * @param {Array<File>} files 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadFiles: async (realmGroupCode: string, files: Array<File>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'realmGroupCode' is not null or undefined
            assertParamExists('uploadFiles', 'realmGroupCode', realmGroupCode)
            // verify required parameter 'files' is not null or undefined
            assertParamExists('uploadFiles', 'files', files)
            const localVarPath = `/apis/v1/common/files`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            if (realmGroupCode !== undefined) {
                localVarQueryParameter['realmGroupCode'] = realmGroupCode;
            }

            if (files) {
                files.forEach((element) => {
                    localVarFormParams.append('files', element as any);
                })
            }

    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FileServiceApi - functional programming interface
 * @export
 */
export const FileServiceApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = FileServiceApiAxiosParamCreator(configuration)
    return {
        /**
         * 파일을 삭제한다. 
         * @summary 파일 삭제
         * @param {DeleteFilesRequest} deleteFilesRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteFiles(deleteFilesRequest: DeleteFilesRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeleteFilesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteFiles(deleteFilesRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FileServiceApi.deleteFiles']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * fileUUID로 파일을 다운로드한다. 
         * @summary 파일 다운로드
         * @param {string} fileUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async downloadFile(fileUuid: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<File>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.downloadFile(fileUuid, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FileServiceApi.downloadFile']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 여러 파일을 ZIP 파일로 다운로드
         * @summary 파일 다운로드
         * @param {DownloadZipRequest} downloadZipRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async downloadZip(downloadZipRequest: DownloadZipRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<File>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.downloadZip(downloadZipRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FileServiceApi.downloadZip']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * fileUUID의 이미지 파일 
         * @summary 이미지파일
         * @param {string} fileUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async imageFile(fileUuid: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<File>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.imageFile(fileUuid, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FileServiceApi.imageFile']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 이미지 목록 조회
         * @summary 이미지 목록
         * @param {ImageListRequest} imageListRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async imageList(imageListRequest: ImageListRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ImageListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.imageList(imageListRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FileServiceApi.imageList']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 파일을 멀티 업로드한다.
         * @summary 파일 멀티 업로드
         * @param {string} realmGroupCode 파일 타입 그룹 코드
         * @param {Array<File>} files 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uploadFiles(realmGroupCode: string, files: Array<File>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UploadFilesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.uploadFiles(realmGroupCode, files, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FileServiceApi.uploadFiles']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * FileServiceApi - factory interface
 * @export
 */
export const FileServiceApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = FileServiceApiFp(configuration)
    return {
        /**
         * 파일을 삭제한다. 
         * @summary 파일 삭제
         * @param {DeleteFilesRequest} deleteFilesRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteFiles(deleteFilesRequest: DeleteFilesRequest, options?: any): AxiosPromise<DeleteFilesResponse> {
            return localVarFp.deleteFiles(deleteFilesRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * fileUUID로 파일을 다운로드한다. 
         * @summary 파일 다운로드
         * @param {string} fileUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadFile(fileUuid: string, options?: any): AxiosPromise<File> {
            return localVarFp.downloadFile(fileUuid, options).then((request) => request(axios, basePath));
        },
        /**
         * 여러 파일을 ZIP 파일로 다운로드
         * @summary 파일 다운로드
         * @param {DownloadZipRequest} downloadZipRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadZip(downloadZipRequest: DownloadZipRequest, options?: any): AxiosPromise<File> {
            return localVarFp.downloadZip(downloadZipRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * fileUUID의 이미지 파일 
         * @summary 이미지파일
         * @param {string} fileUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        imageFile(fileUuid: string, options?: any): AxiosPromise<File> {
            return localVarFp.imageFile(fileUuid, options).then((request) => request(axios, basePath));
        },
        /**
         * 이미지 목록 조회
         * @summary 이미지 목록
         * @param {ImageListRequest} imageListRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        imageList(imageListRequest: ImageListRequest, options?: any): AxiosPromise<ImageListResponse> {
            return localVarFp.imageList(imageListRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 파일을 멀티 업로드한다.
         * @summary 파일 멀티 업로드
         * @param {string} realmGroupCode 파일 타입 그룹 코드
         * @param {Array<File>} files 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadFiles(realmGroupCode: string, files: Array<File>, options?: any): AxiosPromise<UploadFilesResponse> {
            return localVarFp.uploadFiles(realmGroupCode, files, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * FileServiceApi - object-oriented interface
 * @export
 * @class FileServiceApi
 * @extends {BaseAPI}
 */
export class FileServiceApi extends BaseAPI {
    /**
     * 파일을 삭제한다. 
     * @summary 파일 삭제
     * @param {DeleteFilesRequest} deleteFilesRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FileServiceApi
     */
    public deleteFiles(deleteFilesRequest: DeleteFilesRequest, options?: RawAxiosRequestConfig) {
        return FileServiceApiFp(this.configuration).deleteFiles(deleteFilesRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * fileUUID로 파일을 다운로드한다. 
     * @summary 파일 다운로드
     * @param {string} fileUuid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FileServiceApi
     */
    public downloadFile(fileUuid: string, options?: RawAxiosRequestConfig) {
        return FileServiceApiFp(this.configuration).downloadFile(fileUuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 여러 파일을 ZIP 파일로 다운로드
     * @summary 파일 다운로드
     * @param {DownloadZipRequest} downloadZipRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FileServiceApi
     */
    public downloadZip(downloadZipRequest: DownloadZipRequest, options?: RawAxiosRequestConfig) {
        return FileServiceApiFp(this.configuration).downloadZip(downloadZipRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * fileUUID의 이미지 파일 
     * @summary 이미지파일
     * @param {string} fileUuid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FileServiceApi
     */
    public imageFile(fileUuid: string, options?: RawAxiosRequestConfig) {
        return FileServiceApiFp(this.configuration).imageFile(fileUuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 이미지 목록 조회
     * @summary 이미지 목록
     * @param {ImageListRequest} imageListRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FileServiceApi
     */
    public imageList(imageListRequest: ImageListRequest, options?: RawAxiosRequestConfig) {
        return FileServiceApiFp(this.configuration).imageList(imageListRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 파일을 멀티 업로드한다.
     * @summary 파일 멀티 업로드
     * @param {string} realmGroupCode 파일 타입 그룹 코드
     * @param {Array<File>} files 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FileServiceApi
     */
    public uploadFiles(realmGroupCode: string, files: Array<File>, options?: RawAxiosRequestConfig) {
        return FileServiceApiFp(this.configuration).uploadFiles(realmGroupCode, files, options).then((request) => request(this.axios, this.basePath));
    }
}

