// material-ui
import { Box, Button, Checkbox, Divider, Grid, Stack, Tab, Tabs, TextField, Typography } from '@mui/material';

// project-imports
import MainCard from 'components/MainCard';
import { useDialog } from 'contexts/DialogContext';
import { useFormik } from 'formik';
import _ from 'lodash';
import { DeliveryAreaFee, RentalArea } from 'openapi/models';
import { EstimateSetupContext } from 'pages/rent25/estimate/estimate-management';
import { ReactNode, SyntheticEvent, useEffect, useState } from 'react';
import { DataSheetGrid, textColumn, keyColumn, Column, checkboxColumn, intColumn } from 'react-datasheet-grid';
import { NumericFormat } from 'react-number-format';
import { useOutletContext } from 'react-router-dom';

import 'react-datasheet-grid/dist/style.css';

import * as Yup from 'yup';

interface TabPanelProps {
  children?: ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

const DeliveryAreaFeeSettingSection = () => {
  // const theme = useTheme();
  const [columns] = useState<Column<RentalArea>[]>([
    { ...keyColumn('siName', textColumn), title: '시/구', disabled: true, maxWidth: 120 },
    { ...keyColumn('isUse', checkboxColumn), title: '사용', disabled: false, maxWidth: 80 },
    { ...keyColumn('deliveryFee', intColumn), title: '배송비', disabled: false }
  ]);

  const { estimateSetup, onRefresh } = useOutletContext<EstimateSetupContext>();
  const [deliveryAreaFees, setDeliveryAreaFees] = useState<Array<DeliveryAreaFee>>([]);
  const [initValues, setInitValues] = useState<Array<DeliveryAreaFee> | undefined>([]);
  const [doIndex, setDoIndex] = useState(0);
  const { showDialog } = useDialog();

  useEffect(() => {
    setDeliveryAreaFees(estimateSetup?.setupInformation?.deliveryAreaFees ?? []);
    setInitValues(_.cloneDeep(estimateSetup?.setupInformation?.deliveryAreaFees));
  }, [estimateSetup]);

  function handleSubmit() {
    formik.validateForm().then((errors) => {
      if (Object.keys(errors).length === 0) {
        showDialog({
          title: '저장하시겠습니까?',
          onConfirm: handleOnConfirm
        });
      } else {
        formik.handleSubmit();
      }
    });
  }

  function handleOnConfirm(yesNo: boolean) {
    if (yesNo) {
      formik.handleSubmit();
    }
  }

  const formik = useFormik({
    initialValues: {
      deliveryAreaFees: deliveryAreaFees,
      submit: null
    },
    validationSchema: Yup.object().shape({}),
    onSubmit: async (values, { setErrors, setStatus, setSubmitting }) => {
      try {
        setStatus({ success: true });
        setSubmitting(false);

        // Parent page의 Data 갱신
        await onRefresh({
          ...estimateSetup,
          setupInformation: {
            ...estimateSetup?.setupInformation,
            deliveryAreaFees: values.deliveryAreaFees
          }
        });
      } catch (err: any) {
        console.error(err);
        setStatus({ success: false });
        setErrors({ submit: err.message });
        setSubmitting(false);
      }
    },
    enableReinitialize: true
  });

  const handleChangeDoIndex = (event: SyntheticEvent, newValue: number) => {
    setDoIndex(newValue);
  };

  return (
    <Grid container>
      <Grid item xs={12} md={12}>
        <MainCard
          content={false}
          divider={false}
          title="렌트지역/탁송료 설정"
          sx={{ '& .MuiInputLabel-root': { fontSize: '0.875rem' }, mb: 6 }}
        >
          <Box sx={{ width: '100%' }}>
            <Stack direction="row">
              <Tabs
                orientation="vertical"
                value={doIndex}
                onChange={handleChangeDoIndex}
                variant="scrollable"
                aria-label="도/광역시 탭"
                TabIndicatorProps={{ style: { width: 4, backgroundColor: 'primary' } }}
                sx={{ pt: 0, pb: 8, mt: 2, borderRight: 1, borderColor: 'divider', height: 450, width: 160 }}
              >
                {formik.values.deliveryAreaFees?.map((deliveryAreaFee, index) => (
                  <Tab
                    key={index}
                    label={`${deliveryAreaFee.doName}`}
                    id={`simple-tab-${index}`}
                    aria-controls={`simple-tabpanel-${index}`}
                    sx={{ fontWeight: 'bold' }}
                  />
                ))}
              </Tabs>

              <Box sx={{ width: '100%', p: 2 }}>
                <style>
                  {`.dsg-add-row { display: none; } `} {`.dsg-cell { font-size: 14px; } `} {`.dsg-input { font-size: 14px; } `}
                </style>
                {formik.values.deliveryAreaFees?.map((area, index) => (
                  <TabPanel key={index} value={doIndex} index={index}>
                    <Box sx={{ maxWidth: '400px', height: '100%', p: 1 }}>
                      <Stack direction={'row'} justifyContent="start" alignItems="center" sx={{ mb: 2 }}>
                        <Typography variant="h4">{area.doName} 지역</Typography>
                        <Typography variant="h6" sx={{ ml: 3 }}>
                          전체 적용
                        </Typography>
                        <Checkbox
                          checked={formik.values.deliveryAreaFees![index].all}
                          onChange={(event) => {
                            formik.setFieldValue(`deliveryAreaFees[${index}].all`, event.target.checked);
                            const sis = _.map(formik.values.deliveryAreaFees?.[index].sis, (si) => {
                              si.isUse = event.target.checked;
                              return si;
                            });
                            formik.setFieldValue(`deliveryAreaFees[${index}].sis`, sis);
                          }}
                        ></Checkbox>
                        <NumericFormat
                          size="small"
                          value={formik.values.deliveryAreaFees![index].deliveryFee}
                          onValueChange={(values) => {
                            let value = values.value;
                            if (value === '') {
                              value = '0';
                            }
                            formik.setFieldValue(`deliveryAreaFees[${index}].deliveryFee`, value);
                          }}
                          sx={{ width: 120 }}
                          customInput={TextField}
                          thousandSeparator=","
                          InputProps={{ endAdornment: '원' }}
                          inputProps={{ style: { textAlign: 'right' } }}
                        />
                      </Stack>
                      <DataSheetGrid
                        lockRows
                        value={formik.values.deliveryAreaFees![index].sis}
                        onChange={(newValue) => {
                          formik.setFieldValue(`deliveryAreaFees[${index}].sis`, newValue);

                          const i = _.findIndex(newValue, { isUse: false });
                          if (i === -1) {
                            formik.setFieldValue(`deliveryAreaFees[${index}].all`, true);
                          } else {
                            formik.setFieldValue(`deliveryAreaFees[${index}].all`, false);
                          }
                        }}
                        columns={columns}
                        height={800}
                      />
                    </Box>
                  </TabPanel>
                ))}
              </Box>
            </Stack>
          </Box>
        </MainCard>
        <Box
          sx={{
            zIndex: 1,
            position: 'fixed',
            bottom: 0,
            left: 0,
            right: 0,
            bgcolor: 'rgba(250, 250, 250, 0.9)',
            height: '70px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
            px: 2,
            borderTop: '1px solid rgba(0, 0, 0, 0.12)'
          }}
        >
          <Divider />
          <Stack direction="row" justifyContent="end" alignItems="center" spacing={2} sx={{ mt: 0, mr: 5 }}>
            <Button
              variant="outlined"
              color="secondary"
              onClick={() => {
                setDeliveryAreaFees(initValues ?? []);
                formik.resetForm();
              }}
            >
              초기화
            </Button>
            <Button variant="contained" onClick={handleSubmit}>
              저장
            </Button>
          </Stack>
        </Box>
      </Grid>
    </Grid>
  );
};

export default DeliveryAreaFeeSettingSection;
