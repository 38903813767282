import axios from 'axios';
import { alertErrorSnackbarConfig } from 'constant/Constant';
import {
  AuthServiceApi,
  BbsServiceApi,
  CarServiceApi,
  CodeServiceApi,
  CompanyServiceApi,
  Configuration,
  EstimateServiceApi,
  FileServiceApi,
  OperationServiceApi,
  UserServiceApi
} from 'openapi';
import { dispatch } from 'store';
import { openSnackbar } from 'store/reducers/snackbar';

const baseUrl = process.env.REACT_APP_API_URL || 'http://localhost:58080';

export const backendServerUrl = baseUrl;

const axiosServices = axios.create({ baseURL: baseUrl });

// ==============================|| AXIOS - FOR MOCK SERVICES ||============================== //

axiosServices.interceptors.response.use(
  (response) => response,
  (error) => {
    console.error('error', error);

    if (error.response?.status === 401 && !window.location.href.includes('/login')) {
      window.location.pathname = '/login';
      return;
    } else {
      let code = '';
      let message = '';

      if (error.response?.data) {
        code = error.response?.data?.errorCode;
        message = error.response?.data?.errorMessage;
      } else {
        code = error.code;
        message = error.message;
      }

      dispatch(
        openSnackbar({
          ...alertErrorSnackbarConfig,
          message: `[${code}] ${message}`
        })
      );
    }

    // return Promise.reject();
    //return Promise.resolve({ data: null });
    // return Promise.reject(new Error('시스템에 접속할 수 없습니다.'));
    return Promise.reject((error.response && error.response.data) || '시스템에 접속할 수 없습니다.');
  }
);

axiosServices.interceptors.request.use(
  (config) => {
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

const configuration = new Configuration({ basePath: baseUrl });

export const authServiceApi = new AuthServiceApi(configuration, baseUrl, axiosServices);
export const codeServiceApi = new CodeServiceApi(configuration, baseUrl, axiosServices);
export const fileServiceApi = new FileServiceApi(configuration, baseUrl, axiosServices);
export const userServiceApi = new UserServiceApi(configuration, baseUrl, axiosServices);
export const companyServiceApi = new CompanyServiceApi(configuration, baseUrl, axiosServices);
export const bbsServiceApi = new BbsServiceApi(configuration, baseUrl, axiosServices);
export const estimateServiceApi = new EstimateServiceApi(configuration, baseUrl, axiosServices);
export const carServiceApi = new CarServiceApi(configuration, baseUrl, axiosServices);
export const operationServiceApi = new OperationServiceApi(configuration, baseUrl, axiosServices);

export default axiosServices;
