/* tslint:disable */
/* eslint-disable */
/**
 * Rent25 API 가이드
 * Rent25 API 가이드 문서입니다.
 *
 * The version of the OpenAPI document: v0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
// @ts-ignore
import { CompanyCarBrandResponse } from '../models';
// @ts-ignore
import { CompanyCarModelResponse } from '../models';
// @ts-ignore
import { CompanyCarResponse } from '../models';
// @ts-ignore
import { CompanyEmployeeResponse } from '../models';
// @ts-ignore
import { CompanyResponse } from '../models';
// @ts-ignore
import { CreateCompanyCarRequest } from '../models';
// @ts-ignore
import { CreateCompanyCarResponse } from '../models';
// @ts-ignore
import { CreateCompanyRequest } from '../models';
// @ts-ignore
import { CreateCompanyResponse } from '../models';
// @ts-ignore
import { ErrorResponse } from '../models';
// @ts-ignore
import { SearchCompaniesRequest } from '../models';
// @ts-ignore
import { SearchCompaniesResponse } from '../models';
// @ts-ignore
import { SearchCompanyCarRequest } from '../models';
// @ts-ignore
import { SearchCompanyCarResponse } from '../models';
// @ts-ignore
import { UpdateCompanyCarRequest } from '../models';
// @ts-ignore
import { UpdateCompanyCarResponse } from '../models';
// @ts-ignore
import { UpdateCompanyRequest } from '../models';
// @ts-ignore
import { UpdateCompanyResponse } from '../models';
/**
 * CompanyServiceApi - axios parameter creator
 * @export
 */
export const CompanyServiceApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 회사 정보 등록
         * @summary 회사 정보 등록
         * @param {CreateCompanyRequest} createCompanyRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCompany: async (createCompanyRequest: CreateCompanyRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createCompanyRequest' is not null or undefined
            assertParamExists('createCompany', 'createCompanyRequest', createCompanyRequest)
            const localVarPath = `/apis/v1/company`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createCompanyRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 회사 차량 등록
         * @summary 회사 차량 등록
         * @param {number} companyId 
         * @param {CreateCompanyCarRequest} createCompanyCarRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCompanyCar: async (companyId: number, createCompanyCarRequest: CreateCompanyCarRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('createCompanyCar', 'companyId', companyId)
            // verify required parameter 'createCompanyCarRequest' is not null or undefined
            assertParamExists('createCompanyCar', 'createCompanyCarRequest', createCompanyCarRequest)
            const localVarPath = `/apis/v1/company/{companyId}/car`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createCompanyCarRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 회사 보유 브랜드 목록
         * @summary 회사 보유 브랜드 목록
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCarBrands: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/apis/v1/company/car/brands`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 회사 보유 모델 목록
         * @summary 회사 보유 모델 목록
         * @param {string} brandCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCarModels: async (brandCode: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'brandCode' is not null or undefined
            assertParamExists('getCarModels', 'brandCode', brandCode)
            const localVarPath = `/apis/v1/company/car/brands/{brandCode}/models`
                .replace(`{${"brandCode"}}`, encodeURIComponent(String(brandCode)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 회사 정보
         * @summary 회사 정보
         * @param {number} companyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCompany: async (companyId: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('getCompany', 'companyId', companyId)
            const localVarPath = `/apis/v1/company/{companyId}`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 회사 보유 차량 조회
         * @summary 회사 보유 차량 조회
         * @param {number} carId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCompanyCar: async (carId: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'carId' is not null or undefined
            assertParamExists('getCompanyCar', 'carId', carId)
            const localVarPath = `/apis/v1/company/car/{carId}`
                .replace(`{${"carId"}}`, encodeURIComponent(String(carId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 회사 직원 목록
         * @summary 회사 직원 목록
         * @param {number} companyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCompanyEmployees: async (companyId: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('getCompanyEmployees', 'companyId', companyId)
            const localVarPath = `/apis/v1/company/{companyId}/employees`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 나의 회사 정보 조회
         * @summary 나의 회사 정보 조회
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMyCompany: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/apis/v1/company`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 나의 회사 직원 목록
         * @summary 나의 회사 직원 목록
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMyCompanyEmployees: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/apis/v1/company/employees`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 회사 검색
         * @summary 회사 검색
         * @param {SearchCompaniesRequest} searchCompaniesRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchCompanies: async (searchCompaniesRequest: SearchCompaniesRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'searchCompaniesRequest' is not null or undefined
            assertParamExists('searchCompanies', 'searchCompaniesRequest', searchCompaniesRequest)
            const localVarPath = `/apis/v1/company/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(searchCompaniesRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 회사 보유 차량 검색
         * @summary 회사 보유 차량 검색
         * @param {SearchCompanyCarRequest} searchCompanyCarRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchCompanyCars: async (searchCompanyCarRequest: SearchCompanyCarRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'searchCompanyCarRequest' is not null or undefined
            assertParamExists('searchCompanyCars', 'searchCompanyCarRequest', searchCompanyCarRequest)
            const localVarPath = `/apis/v1/company/car/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(searchCompanyCarRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 회사 심플 검색
         * @summary 회사 심플 검색
         * @param {SearchCompaniesRequest} searchCompaniesRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchSimpleCompanies: async (searchCompaniesRequest: SearchCompaniesRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'searchCompaniesRequest' is not null or undefined
            assertParamExists('searchSimpleCompanies', 'searchCompaniesRequest', searchCompaniesRequest)
            const localVarPath = `/apis/v1/company/simple/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(searchCompaniesRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 회사 정보 수정
         * @summary 회사 정보 수정
         * @param {UpdateCompanyRequest} updateCompanyRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCompany: async (updateCompanyRequest: UpdateCompanyRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'updateCompanyRequest' is not null or undefined
            assertParamExists('updateCompany', 'updateCompanyRequest', updateCompanyRequest)
            const localVarPath = `/apis/v1/company`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateCompanyRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 회사 차량 수정
         * @summary 회사 차량 수정
         * @param {number} companyId 
         * @param {UpdateCompanyCarRequest} updateCompanyCarRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCompanyCar: async (companyId: number, updateCompanyCarRequest: UpdateCompanyCarRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('updateCompanyCar', 'companyId', companyId)
            // verify required parameter 'updateCompanyCarRequest' is not null or undefined
            assertParamExists('updateCompanyCar', 'updateCompanyCarRequest', updateCompanyCarRequest)
            const localVarPath = `/apis/v1/company/{companyId}/car`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateCompanyCarRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CompanyServiceApi - functional programming interface
 * @export
 */
export const CompanyServiceApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CompanyServiceApiAxiosParamCreator(configuration)
    return {
        /**
         * 회사 정보 등록
         * @summary 회사 정보 등록
         * @param {CreateCompanyRequest} createCompanyRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createCompany(createCompanyRequest: CreateCompanyRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateCompanyResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createCompany(createCompanyRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CompanyServiceApi.createCompany']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 회사 차량 등록
         * @summary 회사 차량 등록
         * @param {number} companyId 
         * @param {CreateCompanyCarRequest} createCompanyCarRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createCompanyCar(companyId: number, createCompanyCarRequest: CreateCompanyCarRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateCompanyCarResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createCompanyCar(companyId, createCompanyCarRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CompanyServiceApi.createCompanyCar']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 회사 보유 브랜드 목록
         * @summary 회사 보유 브랜드 목록
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCarBrands(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompanyCarBrandResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCarBrands(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CompanyServiceApi.getCarBrands']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 회사 보유 모델 목록
         * @summary 회사 보유 모델 목록
         * @param {string} brandCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCarModels(brandCode: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompanyCarModelResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCarModels(brandCode, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CompanyServiceApi.getCarModels']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 회사 정보
         * @summary 회사 정보
         * @param {number} companyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCompany(companyId: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompanyResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCompany(companyId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CompanyServiceApi.getCompany']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 회사 보유 차량 조회
         * @summary 회사 보유 차량 조회
         * @param {number} carId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCompanyCar(carId: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompanyCarResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCompanyCar(carId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CompanyServiceApi.getCompanyCar']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 회사 직원 목록
         * @summary 회사 직원 목록
         * @param {number} companyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCompanyEmployees(companyId: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompanyEmployeeResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCompanyEmployees(companyId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CompanyServiceApi.getCompanyEmployees']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 나의 회사 정보 조회
         * @summary 나의 회사 정보 조회
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMyCompany(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompanyResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMyCompany(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CompanyServiceApi.getMyCompany']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 나의 회사 직원 목록
         * @summary 나의 회사 직원 목록
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMyCompanyEmployees(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompanyResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMyCompanyEmployees(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CompanyServiceApi.getMyCompanyEmployees']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 회사 검색
         * @summary 회사 검색
         * @param {SearchCompaniesRequest} searchCompaniesRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async searchCompanies(searchCompaniesRequest: SearchCompaniesRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SearchCompaniesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.searchCompanies(searchCompaniesRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CompanyServiceApi.searchCompanies']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 회사 보유 차량 검색
         * @summary 회사 보유 차량 검색
         * @param {SearchCompanyCarRequest} searchCompanyCarRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async searchCompanyCars(searchCompanyCarRequest: SearchCompanyCarRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SearchCompanyCarResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.searchCompanyCars(searchCompanyCarRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CompanyServiceApi.searchCompanyCars']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 회사 심플 검색
         * @summary 회사 심플 검색
         * @param {SearchCompaniesRequest} searchCompaniesRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async searchSimpleCompanies(searchCompaniesRequest: SearchCompaniesRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SearchCompaniesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.searchSimpleCompanies(searchCompaniesRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CompanyServiceApi.searchSimpleCompanies']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 회사 정보 수정
         * @summary 회사 정보 수정
         * @param {UpdateCompanyRequest} updateCompanyRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateCompany(updateCompanyRequest: UpdateCompanyRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UpdateCompanyResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateCompany(updateCompanyRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CompanyServiceApi.updateCompany']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 회사 차량 수정
         * @summary 회사 차량 수정
         * @param {number} companyId 
         * @param {UpdateCompanyCarRequest} updateCompanyCarRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateCompanyCar(companyId: number, updateCompanyCarRequest: UpdateCompanyCarRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UpdateCompanyCarResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateCompanyCar(companyId, updateCompanyCarRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CompanyServiceApi.updateCompanyCar']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * CompanyServiceApi - factory interface
 * @export
 */
export const CompanyServiceApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CompanyServiceApiFp(configuration)
    return {
        /**
         * 회사 정보 등록
         * @summary 회사 정보 등록
         * @param {CreateCompanyRequest} createCompanyRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCompany(createCompanyRequest: CreateCompanyRequest, options?: any): AxiosPromise<CreateCompanyResponse> {
            return localVarFp.createCompany(createCompanyRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 회사 차량 등록
         * @summary 회사 차량 등록
         * @param {number} companyId 
         * @param {CreateCompanyCarRequest} createCompanyCarRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCompanyCar(companyId: number, createCompanyCarRequest: CreateCompanyCarRequest, options?: any): AxiosPromise<CreateCompanyCarResponse> {
            return localVarFp.createCompanyCar(companyId, createCompanyCarRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 회사 보유 브랜드 목록
         * @summary 회사 보유 브랜드 목록
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCarBrands(options?: any): AxiosPromise<CompanyCarBrandResponse> {
            return localVarFp.getCarBrands(options).then((request) => request(axios, basePath));
        },
        /**
         * 회사 보유 모델 목록
         * @summary 회사 보유 모델 목록
         * @param {string} brandCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCarModels(brandCode: string, options?: any): AxiosPromise<CompanyCarModelResponse> {
            return localVarFp.getCarModels(brandCode, options).then((request) => request(axios, basePath));
        },
        /**
         * 회사 정보
         * @summary 회사 정보
         * @param {number} companyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCompany(companyId: number, options?: any): AxiosPromise<CompanyResponse> {
            return localVarFp.getCompany(companyId, options).then((request) => request(axios, basePath));
        },
        /**
         * 회사 보유 차량 조회
         * @summary 회사 보유 차량 조회
         * @param {number} carId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCompanyCar(carId: number, options?: any): AxiosPromise<CompanyCarResponse> {
            return localVarFp.getCompanyCar(carId, options).then((request) => request(axios, basePath));
        },
        /**
         * 회사 직원 목록
         * @summary 회사 직원 목록
         * @param {number} companyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCompanyEmployees(companyId: number, options?: any): AxiosPromise<CompanyEmployeeResponse> {
            return localVarFp.getCompanyEmployees(companyId, options).then((request) => request(axios, basePath));
        },
        /**
         * 나의 회사 정보 조회
         * @summary 나의 회사 정보 조회
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMyCompany(options?: any): AxiosPromise<CompanyResponse> {
            return localVarFp.getMyCompany(options).then((request) => request(axios, basePath));
        },
        /**
         * 나의 회사 직원 목록
         * @summary 나의 회사 직원 목록
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMyCompanyEmployees(options?: any): AxiosPromise<CompanyResponse> {
            return localVarFp.getMyCompanyEmployees(options).then((request) => request(axios, basePath));
        },
        /**
         * 회사 검색
         * @summary 회사 검색
         * @param {SearchCompaniesRequest} searchCompaniesRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchCompanies(searchCompaniesRequest: SearchCompaniesRequest, options?: any): AxiosPromise<SearchCompaniesResponse> {
            return localVarFp.searchCompanies(searchCompaniesRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 회사 보유 차량 검색
         * @summary 회사 보유 차량 검색
         * @param {SearchCompanyCarRequest} searchCompanyCarRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchCompanyCars(searchCompanyCarRequest: SearchCompanyCarRequest, options?: any): AxiosPromise<SearchCompanyCarResponse> {
            return localVarFp.searchCompanyCars(searchCompanyCarRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 회사 심플 검색
         * @summary 회사 심플 검색
         * @param {SearchCompaniesRequest} searchCompaniesRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchSimpleCompanies(searchCompaniesRequest: SearchCompaniesRequest, options?: any): AxiosPromise<SearchCompaniesResponse> {
            return localVarFp.searchSimpleCompanies(searchCompaniesRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 회사 정보 수정
         * @summary 회사 정보 수정
         * @param {UpdateCompanyRequest} updateCompanyRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCompany(updateCompanyRequest: UpdateCompanyRequest, options?: any): AxiosPromise<UpdateCompanyResponse> {
            return localVarFp.updateCompany(updateCompanyRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 회사 차량 수정
         * @summary 회사 차량 수정
         * @param {number} companyId 
         * @param {UpdateCompanyCarRequest} updateCompanyCarRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCompanyCar(companyId: number, updateCompanyCarRequest: UpdateCompanyCarRequest, options?: any): AxiosPromise<UpdateCompanyCarResponse> {
            return localVarFp.updateCompanyCar(companyId, updateCompanyCarRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CompanyServiceApi - object-oriented interface
 * @export
 * @class CompanyServiceApi
 * @extends {BaseAPI}
 */
export class CompanyServiceApi extends BaseAPI {
    /**
     * 회사 정보 등록
     * @summary 회사 정보 등록
     * @param {CreateCompanyRequest} createCompanyRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyServiceApi
     */
    public createCompany(createCompanyRequest: CreateCompanyRequest, options?: RawAxiosRequestConfig) {
        return CompanyServiceApiFp(this.configuration).createCompany(createCompanyRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 회사 차량 등록
     * @summary 회사 차량 등록
     * @param {number} companyId 
     * @param {CreateCompanyCarRequest} createCompanyCarRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyServiceApi
     */
    public createCompanyCar(companyId: number, createCompanyCarRequest: CreateCompanyCarRequest, options?: RawAxiosRequestConfig) {
        return CompanyServiceApiFp(this.configuration).createCompanyCar(companyId, createCompanyCarRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 회사 보유 브랜드 목록
     * @summary 회사 보유 브랜드 목록
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyServiceApi
     */
    public getCarBrands(options?: RawAxiosRequestConfig) {
        return CompanyServiceApiFp(this.configuration).getCarBrands(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 회사 보유 모델 목록
     * @summary 회사 보유 모델 목록
     * @param {string} brandCode 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyServiceApi
     */
    public getCarModels(brandCode: string, options?: RawAxiosRequestConfig) {
        return CompanyServiceApiFp(this.configuration).getCarModels(brandCode, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 회사 정보
     * @summary 회사 정보
     * @param {number} companyId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyServiceApi
     */
    public getCompany(companyId: number, options?: RawAxiosRequestConfig) {
        return CompanyServiceApiFp(this.configuration).getCompany(companyId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 회사 보유 차량 조회
     * @summary 회사 보유 차량 조회
     * @param {number} carId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyServiceApi
     */
    public getCompanyCar(carId: number, options?: RawAxiosRequestConfig) {
        return CompanyServiceApiFp(this.configuration).getCompanyCar(carId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 회사 직원 목록
     * @summary 회사 직원 목록
     * @param {number} companyId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyServiceApi
     */
    public getCompanyEmployees(companyId: number, options?: RawAxiosRequestConfig) {
        return CompanyServiceApiFp(this.configuration).getCompanyEmployees(companyId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 나의 회사 정보 조회
     * @summary 나의 회사 정보 조회
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyServiceApi
     */
    public getMyCompany(options?: RawAxiosRequestConfig) {
        return CompanyServiceApiFp(this.configuration).getMyCompany(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 나의 회사 직원 목록
     * @summary 나의 회사 직원 목록
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyServiceApi
     */
    public getMyCompanyEmployees(options?: RawAxiosRequestConfig) {
        return CompanyServiceApiFp(this.configuration).getMyCompanyEmployees(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 회사 검색
     * @summary 회사 검색
     * @param {SearchCompaniesRequest} searchCompaniesRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyServiceApi
     */
    public searchCompanies(searchCompaniesRequest: SearchCompaniesRequest, options?: RawAxiosRequestConfig) {
        return CompanyServiceApiFp(this.configuration).searchCompanies(searchCompaniesRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 회사 보유 차량 검색
     * @summary 회사 보유 차량 검색
     * @param {SearchCompanyCarRequest} searchCompanyCarRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyServiceApi
     */
    public searchCompanyCars(searchCompanyCarRequest: SearchCompanyCarRequest, options?: RawAxiosRequestConfig) {
        return CompanyServiceApiFp(this.configuration).searchCompanyCars(searchCompanyCarRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 회사 심플 검색
     * @summary 회사 심플 검색
     * @param {SearchCompaniesRequest} searchCompaniesRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyServiceApi
     */
    public searchSimpleCompanies(searchCompaniesRequest: SearchCompaniesRequest, options?: RawAxiosRequestConfig) {
        return CompanyServiceApiFp(this.configuration).searchSimpleCompanies(searchCompaniesRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 회사 정보 수정
     * @summary 회사 정보 수정
     * @param {UpdateCompanyRequest} updateCompanyRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyServiceApi
     */
    public updateCompany(updateCompanyRequest: UpdateCompanyRequest, options?: RawAxiosRequestConfig) {
        return CompanyServiceApiFp(this.configuration).updateCompany(updateCompanyRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 회사 차량 수정
     * @summary 회사 차량 수정
     * @param {number} companyId 
     * @param {UpdateCompanyCarRequest} updateCompanyCarRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyServiceApi
     */
    public updateCompanyCar(companyId: number, updateCompanyCarRequest: UpdateCompanyCarRequest, options?: RawAxiosRequestConfig) {
        return CompanyServiceApiFp(this.configuration).updateCompanyCar(companyId, updateCompanyCarRequest, options).then((request) => request(this.axios, this.basePath));
    }
}

