import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import { DialogContent, Stack, Typography } from '@mui/material';
import { PopupTransition } from 'components/@extended/Transitions';
import EditableTextField from 'components/form/EditableTextField';
// import { InfoCircle } from 'iconsax-react';
// import Avatar from 'components/@extended/Avatar';

export interface ConfirmProps {
  title: string;
  subtitle?: string;
  open: boolean;
  isContent?: boolean;
  color?: 'primary' | 'secondary' | 'error' | 'warning' | 'info' | 'success';
  onConfirm: (args: { yesNo: boolean; content?: string }) => void;
}

function ConfirmDialog({ open, isContent = false, color = 'primary', title, subtitle, onConfirm }: ConfirmProps) {
  const [isOpen, setOpen] = useState(open);
  const [content, setContent] = useState('');
  useEffect(() => {
    setOpen(open);
  }, [open]);

  const handleConfirm = (yesNo: boolean) => {
    // 확인 버튼을 눌렀을 때 실행할 로직
    setOpen(false);
    onConfirm({ yesNo, content });
  };

  return (
    <>
      <Dialog
        open={isOpen}
        onClose={() => handleConfirm(false)}
        fullWidth
        maxWidth={'xs'}
        keepMounted
        TransitionComponent={PopupTransition}
        aria-labelledby="column-delete-title"
        aria-describedby="column-delete-description"
      >
        <DialogContent sx={{ mt: 2 }}>
          <Stack alignItems="center" spacing={3.5}>
            {/* <Avatar color="primary" sx={{ width: 72, height: 72, fontSize: '1.75rem' }}>
              <InfoCircle variant="Bold" />
            </Avatar> */}
            <Stack spacing={2}>
              <Typography variant="h4" align="center">
                {title}
              </Typography>
              {subtitle && <Typography align="center">{subtitle}</Typography>}
            </Stack>
            {isContent && (
              <EditableTextField
                edit={true}
                fullWidth
                multiline
                rows={3}
                value={content}
                id="changeContent"
                name="changeContent"
                placeholder="사유"
                onChange={(e) => setContent(e.target.value)}
              />
            )}
            <Stack direction="row" spacing={2} sx={{ width: 1 }}>
              <Button fullWidth onClick={() => handleConfirm(false)} color="secondary" variant="outlined">
                취소
              </Button>
              <Button fullWidth color={color} variant="contained" onClick={() => handleConfirm(true)} autoFocus>
                확인
              </Button>
            </Stack>
          </Stack>
        </DialogContent>
      </Dialog>
    </>
  );
}

export default ConfirmDialog;
