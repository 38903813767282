import { Box, List, ListItem, ListItemIcon, ListItemSecondaryAction, Typography } from '@mui/material';
import useAuth from 'hooks/useAuth';
import { CallCalling } from 'iconsax-react';
import { User } from 'openapi';
import React, { useEffect, useState } from 'react';
import { companyServiceApi } from 'utils/axios';

type Props = {};

const ChargerInformation = (props: Props) => {
  const { user } = useAuth();
  const [charger, setCharger] = useState<User>();

  useEffect(() => {
    companyServiceApi.getCompany(user?.companyId!).then((response) => {
      setCharger(response.data?.company?.charger);
    });
  }, [user]);

  return (
    <>
      <Box id="chargerInformation" sx={{ p: 1, ml: 3, width: '300px', bgcolor: '#818181', borderRadius: 1, border: '1px solid #919191' }}>
        {charger && (
          <List component="nav" aria-label="charger information" sx={{ m: 0, p: 0, '& .MuiListItem-root': { m: 0, p: 0, py: 0 } }}>
            <ListItem>
              <ListItemIcon sx={{ width: 30, p: 0.4, m: 0 }}>
                <CallCalling size="32" color="#FF8A65" />
              </ListItemIcon>
              <ListItemSecondaryAction>
                <Typography align="left" variant="subtitle2">
                  {charger.name} {charger.positionName}
                </Typography>
                <Typography align="left">{charger.telNumber}</Typography>
              </ListItemSecondaryAction>
            </ListItem>
          </List>
        )}
      </Box>
    </>
  );
};

export default ChargerInformation;
