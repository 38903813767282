import React, { useEffect, useState } from 'react';
import InputLabel, { InputLabelProps } from '@mui/material/InputLabel';

interface RequiredInputLabelProps extends InputLabelProps {
  editMode?: boolean;
  required?: boolean;
}

const RequiredInputLabel: React.FC<RequiredInputLabelProps> = ({ children, editMode = false, required = false, ...props }) => {
  const [_required, setRequired] = useState<boolean>(required);

  useEffect(() => {
    setRequired(required);
  }, [required]);
  return (
    <InputLabel {...props}>
      {_required && editMode && <span style={{ color: 'red' }}> * </span>}
      {children}
    </InputLabel>
  );
};

export default RequiredInputLabel;
