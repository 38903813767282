import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { Helmet } from 'react-helmet';
import PhotoSwipeLightbox from 'photoswipe/lightbox';
import 'photoswipe/style.css';

import { fileServiceApi } from 'utils/axios';
import { RealmGroupCode } from 'constant/Constant';
import { FileInformation } from 'openapi';
import { getImageUrl } from 'utils/file-utils';

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

interface Props {
  galleryId?: string;
}

const ImageViewPage = ({ galleryId = 'galleryId' }: Props) => {
  const query = useQuery();
  const type = query.get('type');
  const id = query.get('id');

  const [images, setImages] = useState<FileInformation[]>();

  function isPhonePortrait() {
    return window.matchMedia('(max-width: 600px) and (orientation: portrait)').matches;
  }

  useEffect(() => {
    let lightbox = new PhotoSwipeLightbox({
      gallery: `#${galleryId}`,
      children: 'a',
      pswpModule: () => import('photoswipe'),
      bgOpacity: 1,
      arrowPrev: true,
      arrowNext: true,
      zoom: true,
      close: true,
      counter: true,
      maxZoomLevel: 3,
      initialZoomLevel: (zoomLevelObject) => {
        if (isPhonePortrait()) {
          return zoomLevelObject.vFill;
        } else {
          return zoomLevelObject.fit;
        }
      },
      secondaryZoomLevel: (zoomLevelObject) => {
        if (isPhonePortrait()) {
          return zoomLevelObject.fit;
        } else {
          return 1;
        }
      }
    });
    lightbox.init();

    return () => {
      lightbox.destroy();
    };
  }, [galleryId]);

  useEffect(() => {
    console.log(`type:${type}, id:${id}`);
    const realmGroupCode = type === 'car' ? RealmGroupCode.car_image.code : '';
    fileServiceApi.imageList({ id: parseInt(id ?? '0'), realmGroupCode }).then((response) => {
      setImages(response.data?.imageList);
    });
  }, [type, id]);

  return (
    <div className="pswp-gallery" id={galleryId}>
      <Helmet>
        <title>홈페이지 제목</title>
        <meta property="og:title" content="홈페이지 제목" />
        <meta property="og:description" content="홈페이지 설명" />
        <meta property="og:image" content="http://localhost:58080/apis/v1/common/files/image/28ce22db-c00e-48df-b351-ec35cf6c591b.jpg" />
        <meta property="og:url" content="홈페이지 URL" />
      </Helmet>
      {images?.map((image, index) => (
        <a
          href={getImageUrl(image.fileUuid)}
          data-pswp-width={image.imageWidth}
          data-pswp-height={image.imageHeight}
          key={galleryId + '-' + index}
          target="_blank"
          rel="noreferrer"
        >
          <img src={getImageUrl(image.fileUuid)} alt={image.originalFileName} />
        </a>
      ))}
    </div>
  );
};

export default ImageViewPage;
