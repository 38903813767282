import { lazy } from 'react';
import Loadable from 'components/Loadable';

// project-imports
import MainLayout from 'layout/MainLayout';
import AuthGuard from 'utils/route-guard/AuthGuard';
import DeliveryAreaFeeSettingSection from 'sections/rent25/estimate/setup/DeliveryAreaFeeSettingSection';
import EstimateSetupPage from 'pages/rent25/estimate/estimate-management';
import NoticeListPage from 'pages/operation/notice-list';
import PdsListPage from 'pages/operation/pds-list';
import NoticeRegisterPage from 'pages/operation/notice-register';
import NoticeViewPage from 'pages/operation/notice-view';
import PdsRegisterPage from 'pages/operation/pds-register';
import PdsViewPage from 'pages/operation/pds-view';

const MyCompanyPage = Loadable(lazy(() => import('pages/rent25/company/company')));
const CompanyEmployeeListPage = Loadable(lazy(() => import('pages/rent25/company/company-employee-list')));
// const EstimateSetupPage = Loadable(lazy(() => import('pages/rent25/estimate/estimate-management')));
const VehiclePriceGroupSettingSection = Loadable(lazy(() => import('sections/rent25/estimate/setup/VehiclePriceGroupSettingSection')));
const DepositSettingSection = Loadable(lazy(() => import('sections/rent25/estimate/setup/DepositSettingSection')));
const CarResidualGroupSection = Loadable(lazy(() => import('sections/rent25/estimate/setup/CarResidualGroupSelectSection')));
const AdditionalRentalFeeSettingSection = Loadable(lazy(() => import('sections/rent25/estimate/setup/AdditionalRentalFeeSettingSection')));
// const DeliveryAreaFeeSettingSection = Loadable(lazy(() => import('sections/rent25/estimate/setup/DeliveryAreaFeeSettingSection')));
const SupplySettingSection = Loadable(lazy(() => import('sections/rent25/estimate/setup/SupplySettingSection')));
const BannerManagementPage = Loadable(lazy(() => import('sections/rent25/operation/BannerManagementSection')));
const DocumentManagementSection = Loadable(lazy(() => import('sections/rent25/operation/DocumentManagementSection')));
const AuthorizationListPage = Loadable(lazy(() => import('pages/system/authorization/authorization-list')));
const CodeListPage = Loadable(lazy(() => import('pages/system/code/code-list')));
const EtcSettingSection = Loadable(lazy(() => import('sections/rent25/estimate/setup/EtcSettingSection')));
const CompanyPage = Loadable(lazy(() => import('pages/rent25/company/company-management')));
const CompanyRegistrationPage = Loadable(lazy(() => import('sections/rent25/company/CompanyRegistrationSection')));
const CompanyView = Loadable(lazy(() => import('sections/rent25/company/CompanyView')));
const EstimateSimulationPage = Loadable(lazy(() => import('pages/rent25/estimate/estimate-simulation')));
const NewCarListPage = Loadable(lazy(() => import('pages/rent25/car/new-car-list')));
const EstimateViewPage = Loadable(lazy(() => import('pages/rent25/estimate/estimate-view')));
const EstimateListPage = Loadable(lazy(() => import('pages/rent25/estimate/estimate-list')));
const MenuManagementPage = Loadable(lazy(() => import('pages/system/menu/menu-management')));
const NewCarViewPage = Loadable(lazy(() => import('pages/rent25/car/new-car-view')));
const CarResidualGroupRateSection = Loadable(lazy(() => import('sections/rent25/estimate/setup/CarResidualGroupRateSection')));
const NewCarUpdatePage = Loadable(lazy(() => import('pages/rent25/car/new-car-update')));
const EstimateRegistrationPage = Loadable(lazy(() => import('pages/rent25/estimate/estimate-registration')));
const Rent25Dashboard = Loadable(lazy(() => import('pages/rent25/Rent25Dashboard')));
const EstimateComparisonPage = Loadable(lazy(() => import('pages/rent25/estimate/estimate-comparison')));

// render - sample page
const UserListPage = Loadable(lazy(() => import('pages/rent25/user/user-list')));
const CompanyListPage = Loadable(lazy(() => import('pages/rent25/company/company-list')));
// ==============================|| MAIN ROUTES ||============================== //

const AuthRent25Routes = {
  path: '/',
  children: [
    {
      path: '/',
      element: (
        <AuthGuard>
          <MainLayout />
        </AuthGuard>
      ),
      children: [
        {
          name: 'Dashboard',
          path: '/dashboard',
          element: <Rent25Dashboard />
        },
        {
          name: 'RENT25',
          path: '/rent25',
          children: [
            {
              name: '회원',
              path: 'user',
              children: [
                {
                  name: '회원 목록',
                  path: 'list',
                  element: <UserListPage />
                }
              ]
            },
            {
              name: '나의회사',
              path: 'my-company',
              element: <MyCompanyPage />,
              children: [
                {
                  name: '회사 정보',
                  path: 'information',
                  element: <CompanyView />
                },
                {
                  name: '회사 직원 목록',
                  path: 'employee',
                  element: <CompanyEmployeeListPage />
                }
                // {
                //   path: 'estimate',
                //   element: <CompanyEstimateSettingSection />
                // }
              ]
            },
            {
              name: '회사',
              path: 'company',
              element: <CompanyPage />,
              children: [
                {
                  path: 'list',
                  element: <CompanyListPage />
                },
                {
                  path: 'registration',
                  element: <CompanyRegistrationPage />
                }
              ]
            },
            {
              name: '차량',
              path: 'car',
              children: [
                {
                  name: '신차(보유차량)',
                  path: 'new',
                  children: [
                    {
                      path: 'list',
                      element: <NewCarListPage />
                    },
                    {
                      path: 'update',
                      element: <NewCarUpdatePage />
                    },
                    {
                      name: '신차(보유차량) 정보 보기',
                      path: 'view',
                      element: <NewCarViewPage />
                    }
                  ]
                }
              ]
            },
            {
              name: '견적',
              path: 'estimate',
              children: [
                {
                  path: 'list',
                  element: <EstimateListPage />
                },
                {
                  path: 'simulation',
                  element: <EstimateSimulationPage />
                },
                {
                  path: 'comparison',
                  element: <EstimateComparisonPage />
                },
                {
                  path: 'registration',
                  element: <EstimateRegistrationPage />
                },
                {
                  path: 'view',
                  element: <EstimateViewPage />
                },
                {
                  path: 'setup',
                  element: <EstimateSetupPage />,
                  children: [
                    {
                      path: 'vehicle-price-group',
                      element: <VehiclePriceGroupSettingSection />
                    },
                    {
                      path: 'deposit',
                      element: <DepositSettingSection />
                    },
                    {
                      path: 'residual-value',
                      element: <CarResidualGroupRateSection />
                    },
                    {
                      path: 'residual-group',
                      element: <CarResidualGroupSection />
                    },
                    {
                      path: 'add-rental-fee',
                      element: <AdditionalRentalFeeSettingSection />
                    },
                    {
                      path: 'rental-area',
                      element: <DeliveryAreaFeeSettingSection />
                    },
                    {
                      path: 'supply',
                      element: <SupplySettingSection />
                    },
                    {
                      path: 'etc',
                      element: <EtcSettingSection />
                    }
                  ]
                }
              ]
            },
            {
              name: '운영관리',
              path: 'operation/management',
              children: [
                {
                  path: 'terms',
                  element: <DocumentManagementSection />
                },
                {
                  path: 'banner',
                  element: <BannerManagementPage />
                },
                {
                  path: 'notice-list',
                  element: <NoticeListPage />
                },
                {
                  path: 'notice-register',
                  element: <NoticeRegisterPage />
                },
                {
                  path: 'notice-view',
                  element: <NoticeViewPage />
                },
                {
                  path: 'pds-list',
                  element: <PdsListPage />
                },
                {
                  path: 'pds-register',
                  element: <PdsRegisterPage />
                },
                {
                  path: 'pds-view',
                  element: <PdsViewPage />
                }
              ]
            }
          ]
        },
        {
          name: 'RENTCAR',
          path: '/rentcar',
          children: []
        },
        {
          path: '/system',
          element: (
            <AuthGuard>
              <MainLayout />
            </AuthGuard>
          ),
          children: [
            {
              path: 'management/menu',
              element: <MenuManagementPage />
            },
            {
              path: 'management/authorization',
              element: <AuthorizationListPage />
            },
            {
              path: 'management/code',
              element: <CodeListPage />
            }
          ]
        }
      ]
    }
  ]
};

export default AuthRent25Routes;
