import { useEffect, useState } from 'react';

// project-imports
import Routes from 'routes';
import ThemeCustomization from 'themes';

import Loader from 'components/Loader';
import Locales from 'components/Locales';
import RTLLayout from 'components/RTLLayout';
import ScrollTop from 'components/ScrollTop';
import Snackbar from 'components/@extended/Snackbar';
import Notistack from 'components/third-party/Notistack';

import { dispatch } from 'store';
import { fetchMenu } from 'store/reducers/menu';

// auth-provider
import { JWTProvider as AuthProvider } from 'contexts/JWTContext';
import { CodeProvider } from 'contexts/CodeContext';
import { DialogProvider } from 'contexts/DialogContext';
import ContextConfirmDialog from 'components/dialog/ContextConfirmDialog';
// import { FirebaseProvider as AuthProvider } from 'contexts/FirebaseContext';
// import { AWSCognitoProvider as AuthProvider } from 'contexts/AWSCognitoContext';
// import { Auth0Provider as AuthProvider } from 'contexts/Auth0Context';

// ==============================|| APP - THEME, ROUTER, LOCAL  ||============================== //

const App = () => {
  // 전체적으로 Error 무시하기
  if (window.ResizeObserver) {
    const observer = new ResizeObserver(() => {});
    observer.observe(document.body);
  }

  useEffect(() => {
    window.addEventListener('error', (e) => {
      if (e.message.startsWith('ResizeObserver loop')) {
        const resizeObserverErrDiv = document.getElementById('webpack-dev-server-client-overlay-div');
        const resizeObserverErr = document.getElementById('webpack-dev-server-client-overlay');
        if (resizeObserverErr) {
          resizeObserverErr.setAttribute('style', 'display: none');
        }
        if (resizeObserverErrDiv) {
          resizeObserverErrDiv.setAttribute('style', 'display: none');
        }
      }
    });
  }, []);

  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    dispatch(fetchMenu()).then(() => {
      setLoading(false);
    });
  }, []);

  if (loading) return <Loader />;

  return (
    <ThemeCustomization>
      <RTLLayout>
        <Locales>
          <CodeProvider>
            <DialogProvider>
              <ContextConfirmDialog />
              <ScrollTop>
                <AuthProvider>
                  <>
                    <Notistack>
                      <Routes />
                      <Snackbar />
                    </Notistack>
                  </>
                </AuthProvider>
              </ScrollTop>
            </DialogProvider>
          </CodeProvider>
        </Locales>
      </RTLLayout>
    </ThemeCustomization>
  );
};

export default App;
