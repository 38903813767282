import { BbsType } from 'constant/Constant';
import React from 'react';
import { useLocation } from 'react-router-dom';
import BbsViewer from 'sections/rent25/operation/BbsViewer';

type Props = {};

const NoticeViewPage = (props: Props) => {
  const location = useLocation();
  const { bbsId } = location.state || {};

  return <BbsViewer bbsId={bbsId} bbsType={BbsType.notice.code} viewType="view" />;
};

export default NoticeViewPage;
