/* tslint:disable */
/* eslint-disable */
/**
 * Rent25 API 가이드
 * Rent25 API 가이드 문서입니다.
 *
 * The version of the OpenAPI document: v0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
// @ts-ignore
import { BbsRequest } from '../models';
// @ts-ignore
import { BbsResponse } from '../models';
// @ts-ignore
import { ErrorResponse } from '../models';
// @ts-ignore
import { SearchBbsRequest } from '../models';
// @ts-ignore
import { SearchBbsResponse } from '../models';
/**
 * BbsServiceApi - axios parameter creator
 * @export
 */
export const BbsServiceApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 게시물 등록
         * @summary 게시물 등록
         * @param {BbsRequest} bbsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createBbs: async (bbsRequest: BbsRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'bbsRequest' is not null or undefined
            assertParamExists('createBbs', 'bbsRequest', bbsRequest)
            const localVarPath = `/apis/v1/bbs`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(bbsRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 게시물 정보
         * @summary 게시물 정보
         * @param {number} bbsId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBbs: async (bbsId: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'bbsId' is not null or undefined
            assertParamExists('getBbs', 'bbsId', bbsId)
            const localVarPath = `/apis/v1/bbs/{bbsId}`
                .replace(`{${"bbsId"}}`, encodeURIComponent(String(bbsId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 게시물 검색
         * @summary 게시물 검색
         * @param {SearchBbsRequest} searchBbsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchBbs: async (searchBbsRequest: SearchBbsRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'searchBbsRequest' is not null or undefined
            assertParamExists('searchBbs', 'searchBbsRequest', searchBbsRequest)
            const localVarPath = `/apis/v1/bbs/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(searchBbsRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 게시물 수정
         * @summary 게시물 수정
         * @param {BbsRequest} bbsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateBbs: async (bbsRequest: BbsRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'bbsRequest' is not null or undefined
            assertParamExists('updateBbs', 'bbsRequest', bbsRequest)
            const localVarPath = `/apis/v1/bbs`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(bbsRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BbsServiceApi - functional programming interface
 * @export
 */
export const BbsServiceApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = BbsServiceApiAxiosParamCreator(configuration)
    return {
        /**
         * 게시물 등록
         * @summary 게시물 등록
         * @param {BbsRequest} bbsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createBbs(bbsRequest: BbsRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BbsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createBbs(bbsRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['BbsServiceApi.createBbs']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 게시물 정보
         * @summary 게시물 정보
         * @param {number} bbsId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBbs(bbsId: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BbsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getBbs(bbsId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['BbsServiceApi.getBbs']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 게시물 검색
         * @summary 게시물 검색
         * @param {SearchBbsRequest} searchBbsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async searchBbs(searchBbsRequest: SearchBbsRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SearchBbsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.searchBbs(searchBbsRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['BbsServiceApi.searchBbs']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 게시물 수정
         * @summary 게시물 수정
         * @param {BbsRequest} bbsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateBbs(bbsRequest: BbsRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BbsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateBbs(bbsRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['BbsServiceApi.updateBbs']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * BbsServiceApi - factory interface
 * @export
 */
export const BbsServiceApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = BbsServiceApiFp(configuration)
    return {
        /**
         * 게시물 등록
         * @summary 게시물 등록
         * @param {BbsRequest} bbsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createBbs(bbsRequest: BbsRequest, options?: any): AxiosPromise<BbsResponse> {
            return localVarFp.createBbs(bbsRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 게시물 정보
         * @summary 게시물 정보
         * @param {number} bbsId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBbs(bbsId: number, options?: any): AxiosPromise<BbsResponse> {
            return localVarFp.getBbs(bbsId, options).then((request) => request(axios, basePath));
        },
        /**
         * 게시물 검색
         * @summary 게시물 검색
         * @param {SearchBbsRequest} searchBbsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchBbs(searchBbsRequest: SearchBbsRequest, options?: any): AxiosPromise<SearchBbsResponse> {
            return localVarFp.searchBbs(searchBbsRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 게시물 수정
         * @summary 게시물 수정
         * @param {BbsRequest} bbsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateBbs(bbsRequest: BbsRequest, options?: any): AxiosPromise<BbsResponse> {
            return localVarFp.updateBbs(bbsRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * BbsServiceApi - object-oriented interface
 * @export
 * @class BbsServiceApi
 * @extends {BaseAPI}
 */
export class BbsServiceApi extends BaseAPI {
    /**
     * 게시물 등록
     * @summary 게시물 등록
     * @param {BbsRequest} bbsRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BbsServiceApi
     */
    public createBbs(bbsRequest: BbsRequest, options?: RawAxiosRequestConfig) {
        return BbsServiceApiFp(this.configuration).createBbs(bbsRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 게시물 정보
     * @summary 게시물 정보
     * @param {number} bbsId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BbsServiceApi
     */
    public getBbs(bbsId: number, options?: RawAxiosRequestConfig) {
        return BbsServiceApiFp(this.configuration).getBbs(bbsId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 게시물 검색
     * @summary 게시물 검색
     * @param {SearchBbsRequest} searchBbsRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BbsServiceApi
     */
    public searchBbs(searchBbsRequest: SearchBbsRequest, options?: RawAxiosRequestConfig) {
        return BbsServiceApiFp(this.configuration).searchBbs(searchBbsRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 게시물 수정
     * @summary 게시물 수정
     * @param {BbsRequest} bbsRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BbsServiceApi
     */
    public updateBbs(bbsRequest: BbsRequest, options?: RawAxiosRequestConfig) {
        return BbsServiceApiFp(this.configuration).updateBbs(bbsRequest, options).then((request) => request(this.axios, this.basePath));
    }
}

