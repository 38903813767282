import React from 'react';
import { Select, SelectProps, Typography } from '@mui/material';

type EditableSelectProps = SelectProps & {
  edit?: boolean;
  textWidth?: number;
  toName?: () => string;
};

const EditableSelect = React.memo(({ edit = false, toName, textWidth, children, ...props }: EditableSelectProps) => {
  return (
    <>
      {edit ? (
        <Select {...props}>{children}</Select>
      ) : (
        <Typography
          variant="body1"
          sx={{
            display: 'inline-block',
            border: '1px solid transparent',
            borderRadius: '4px',
            padding: '14px 13px',
            lineHeight: 'normal',
            width: textWidth ? textWidth : '100%'
            //maxWidth: props.fullWidth ? '100%' : `calc(100% - ${props.margin ? props.margin * 2 : 0}px)`
          }}
        >
          {toName ? toName() : props.value ? props.value.toString() : ' '}
        </Typography>
      )}
    </>
  );
});

export default EditableSelect;
