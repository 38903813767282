import { Box, Button, Divider, Stack, Tab, Tabs } from '@mui/material';
import MainCard from 'components/MainCard';
import React, { MouseEvent, SyntheticEvent, useCallback, useEffect, useState } from 'react';
import { Outlet, useLocation } from 'react-router';
import { Link } from 'react-router-dom';
import { Car } from 'iconsax-react';
import useAuth from 'hooks/useAuth';
import {
  CarTypeCode,
  CompanyTypeCode,
  EstimateSetupTypeCode,
  SaveTypeCode,
  alertErrorSnackbarConfig,
  alertSnackbarConfig
} from 'constant/Constant';
import { estimateServiceApi } from 'utils/axios';
import { EstimateSetup, UpsertEstimateSetupRequest } from 'openapi';
import { dispatch } from 'store';
import { openSnackbar } from 'store/reducers/snackbar';
import { toCompanyTypePath } from 'utils/string-utils';
import { defaultSetupInformation } from 'data/estimate-init-data-v2';
import _ from 'lodash';
import { useDialog } from 'contexts/DialogContext';

export type EstimateSetupContext = {
  estimateSetup?: EstimateSetup;
  onRefresh: (estimateSetup: EstimateSetup) => Promise<void>;
};

const EstimateSetupPage = () => {
  const { pathname } = useLocation();
  const { showDialog } = useDialog();

  const [carTypeCodeIndex, setCarTypeCodeIndex] = useState(0);
  const [estimateSetupTypeIndex, setEstimateSetupTypeIndex] = useState(0);
  const [estimateSetup, setEstimateSetup] = useState<EstimateSetup | undefined>(undefined);
  const [refreshCount, setRefreshCount] = useState(0);
  const { user } = useAuth();
  const [companyTypePath, setCompanyTypePath] = useState('');

  useEffect(() => {
    const path = toCompanyTypePath(user?.company?.companyTypeCode);
    if (path) {
      setCompanyTypePath(path);
    }
  }, [user?.company?.companyTypeCode]);

  useEffect(() => {
    console.log('PATH', pathname);

    const index = _.findIndex(Object.values(EstimateSetupTypeCode), (value) => {
      return `/${companyTypePath}/${value.to}` === pathname;
    });

    setEstimateSetupTypeIndex(index);
  }, [companyTypePath, pathname]);

  const disableTabs = useCallback(
    (index: number, estimateSetupType: any) => {
      if (EstimateSetupTypeCode.deposit.code === estimateSetupType.code) {
        return estimateSetup?.setupInformation?.priceGroups ? false : true;
      }

      return false;
    },
    [estimateSetup]
  );

  const handleChangeEstimateSetupType = (event: SyntheticEvent, newValue: number) => {
    setEstimateSetupTypeIndex(newValue);
  };

  const handleChangeCarType = (event: SyntheticEvent, newValue: number) => {
    setCarTypeCodeIndex(newValue);
  };

  const getCarTypeCode = useCallback((index: number) => {
    return Object.values(CarTypeCode)[index].code;
  }, []);

  const onRefresh = async (estimateSetup: EstimateSetup) => {
    await estimateServiceApi.upsertEstimateSetup(estimateSetup!.companyId!, estimateSetup!.setupTypeCode!, estimateSetup!.saveTypeCode!, {
      estimateSetup
    });

    dispatch(
      openSnackbar({
        ...alertSnackbarConfig,
        message: '저장되었습니다.'
      })
    );

    setRefreshCount((prev) => prev + 1);
  };

  useEffect(() => {
    estimateServiceApi.getEstimateSetup(user?.companyId!, getCarTypeCode(carTypeCodeIndex)).then((response) => {
      if (response.data?.estimateSetup!.estimateSetupId === undefined) {
        // 초기 DATA
        const defaultEstimateSetup = {
          ...response.data?.estimateSetup!,
          setupInformation: {
            ...defaultSetupInformation
          }
        } as EstimateSetup;

        setEstimateSetup(defaultEstimateSetup);
      } else {
        setEstimateSetup(response.data?.estimateSetup!);
      }
    });
  }, [refreshCount, carTypeCodeIndex, getCarTypeCode, user?.companyId]);

  // 견적 설정 초기화(RENT25 설정 정보로 초기화)
  const initRent25EstimateSetup = () => {
    showDialog({
      title: '설정 정보를 초기화하시겠습니까?',
      onConfirm: (yesNo: boolean) => {
        if (!yesNo) return;

        estimateServiceApi.initRent25EstimateSetup(CarTypeCode.newCar.code).then((response) => {
          setEstimateSetup(response.data?.estimateSetup);
          dispatch(
            openSnackbar({
              ...alertSnackbarConfig,
              message: `설정 정보가 초기화되었습니다.`
            })
          );
        });
      }
    });
  };

  const saveBackupEstimateSetup = () => {
    showDialog({
      title: '기본값으로 저장하시겠습니까?',
      onConfirm: async (yesNo: boolean) => {
        if (!yesNo) return;

        const newEstimateSetup = _.cloneDeep(estimateSetup);

        const estimateSetupId = await estimateServiceApi.existBackupEstimateSetup(CarTypeCode.newCar.code);
        if (estimateSetupId.data > 0) {
          newEstimateSetup!.estimateSetupId = estimateSetupId.data;
        } else {
          newEstimateSetup!.estimateSetupId = undefined;
        }
        const request: UpsertEstimateSetupRequest = {
          estimateSetup: newEstimateSetup
        };

        estimateServiceApi
          .upsertEstimateSetup(user?.company?.companyId!, CarTypeCode.newCar.code, SaveTypeCode.backup.code, request)
          .then((_) => {
            dispatch(
              openSnackbar({
                ...alertSnackbarConfig,
                message: `기본값으로 저장되었습니다.`
              })
            );
          });
      }
    });
  };

  // 백업된 정보를 이용하여 재 설정
  const loadBackupEstimateSetup = async () => {
    showDialog({
      title: '기본값으로 설정을 변경하시겠습니까?',
      onConfirm: async (yesNo: boolean) => {
        if (!yesNo) return;
        const existResponse = await estimateServiceApi.existBackupEstimateSetup(CarTypeCode.newCar.code);
        if (existResponse.data === 0) {
          dispatch(
            openSnackbar({
              ...alertErrorSnackbarConfig,
              message: `기본값으로 저장된 설정 정보가 없습니다.`
            })
          );
        } else {
          estimateServiceApi.loadBackupEstimateSetup(CarTypeCode.newCar.code).then((response) => {
            setEstimateSetup(response.data?.estimateSetup);
            dispatch(
              openSnackbar({
                ...alertSnackbarConfig,
                message: `기본값으로 초기화되었습니다.`
              })
            );
          });
        }
      }
    });
  };

  return (
    <MainCard border={false}>
      <Box sx={{ fontSize: '0.5rem', width: '100%' }}>
        <Stack direction={'row'} spacing={1.25}>
          <Stack sx={{ width: '50%' }}>
            <Tabs
              value={carTypeCodeIndex}
              onChange={handleChangeCarType}
              variant="scrollable"
              scrollButtons="auto"
              aria-label="자동차 타입"
            >
              {Object.values(CarTypeCode).map((carType, index) => (
                <Tab key={index} label={carType.name} icon={<Car />} iconPosition="start" />
              ))}
            </Tabs>
          </Stack>
          {user?.company?.companyTypeCode === CompanyTypeCode.rentcar.code && (
            <Stack direction={'row'} alignItems={'center'} spacing={2} justifyContent={'end'} sx={{ width: '50%' }}>
              <Stack direction={'row'} spacing={1}>
                <Button
                  variant="outlined"
                  size="small"
                  sx={{ fontSize: 12 }}
                  onClick={(e: MouseEvent<HTMLButtonElement>) => {
                    saveBackupEstimateSetup();
                  }}
                >
                  기본값으로 저장
                </Button>
                <Button
                  variant="outlined"
                  size="small"
                  sx={{ fontSize: 12 }}
                  onClick={(e: MouseEvent<HTMLButtonElement>) => {
                    loadBackupEstimateSetup();
                  }}
                >
                  기본값 불러오기
                </Button>
              </Stack>
              <Button
                variant="outlined"
                color="error"
                size="small"
                sx={{ fontSize: 12 }}
                onClick={(e: MouseEvent<HTMLButtonElement>) => {
                  initRent25EstimateSetup();
                }}
              >
                RENT25값 불러오기
              </Button>
            </Stack>
          )}
        </Stack>
      </Box>
      <Divider sx={{ mb: 1 }} />
      <Box sx={{ borderBottom: 1, borderColor: 'divider', width: '100%' }}>
        <Tabs
          value={estimateSetupTypeIndex}
          onChange={handleChangeEstimateSetupType}
          variant="scrollable"
          scrollButtons="auto"
          aria-label="견적서 설정 탭"
        >
          {Object.values(EstimateSetupTypeCode).map((estimateSetupType, index) => (
            <Tab
              key={index}
              label={estimateSetupType.name}
              component={Link}
              to={`/${companyTypePath}/${estimateSetupType.to}`}
              icon={estimateSetupType.icon}
              iconPosition="start"
              disabled={disableTabs(index, estimateSetupType)}
            />
          ))}
        </Tabs>
      </Box>
      <Box sx={{ mt: 2.5 }}>
        <Outlet
          context={
            {
              estimateSetup,
              onRefresh
            } as EstimateSetupContext
          }
        />
      </Box>
    </MainCard>
  );
};

export default EstimateSetupPage;
