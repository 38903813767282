import { Box, Divider, Stack, Button, MenuItem, FormHelperText } from '@mui/material';
import MainCard from 'components/MainCard';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Editor } from '@tinymce/tinymce-react';
import { useDialog } from 'contexts/DialogContext';
import { bbsServiceApi } from 'utils/axios';
import { BbsType, CodeGroupName, CompanyTypeCode, RealmGroupCode, toValue } from 'constant/Constant';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Bbs, Code, FileInformation } from 'openapi';
import EditableTextField from 'components/form/EditableTextField';
import EditableSelect from 'components/form/EditableSelect';
import { useNavigate } from 'react-router-dom';
import useAuth from 'hooks/useAuth';
import { toCompanyTypePath } from 'utils/string-utils';
import _ from 'lodash';
import { useCodes } from 'contexts/CodeContext';
import DragAndDropUpload from 'components/image/DragAndDropUpload';

export interface Props {
  bbsType: string;
  viewType?: string;
  bbsId?: number;
}

const BbsViewer = ({ bbsType, viewType = 'view', bbsId }: Props) => {
  const { showDialog } = useDialog();
  const navigate = useNavigate();
  const code = useCodes();
  const { user } = useAuth();
  const editorRef = useRef<any>(null);
  const [bbs, setBbs] = useState<Bbs>();
  const [content, setContent] = useState<string>('');
  const [companyTypePath, setCompanyTypePath] = useState('');
  const [pdsTypeList, setPdsTypeList] = useState<Code[]>([]);
  const [edit, setEdit] = useState(false);

  useEffect(() => {
    setEdit(viewType !== 'view');
  }, [viewType]);

  useEffect(() => {
    if (user?.company?.companyTypeCode) {
      const path = toCompanyTypePath(user?.company?.companyTypeCode);
      if (path) {
        setCompanyTypePath(path);
      }
    }
  }, [user]);

  useEffect(() => {
    console.log(`bbstype = ${bbsType}`);
    if (bbsType === BbsType.pds.code) {
      setPdsTypeList(code.getCodes(CodeGroupName.PdsTypeCode));
    }
  }, [bbsType, code]);

  useEffect(() => {
    if (bbsId) {
      bbsServiceApi.getBbs(bbsId!).then((response) => {
        setContent(response.data.bbs?.content ?? '');
        setBbs(response.data.bbs);
      });
    }
  }, [bbsId]);

  function handleSubmit() {
    formik.validateForm().then((errors) => {
      console.log(errors);
      if (Object.keys(errors).length === 0) {
        console.log(formik.values);
        showDialog({
          title: '저장하시겠습니까?',
          onConfirm: handleOnConfirm
        });
      } else {
        formik.handleSubmit();
      }
    });
  }

  function handleOnConfirm(yesNo: boolean) {
    if (yesNo) {
      formik.handleSubmit();
    }
  }

  const formik = useFormik({
    initialValues: {
      bbsId: bbs?.bbsId,
      bbsTypeCode: bbs?.bbsTypeCode ?? bbsType,
      categoryCode: bbs?.categoryCode ?? '',
      title: bbs?.title ?? '',
      content: bbs?.content ?? '',
      targetCompanyTypeCode: bbs?.targetCompanyTypeCode ?? '',
      attachFiles: bbs?.attachFiles ?? [],
      submit: null
    },
    validationSchema: Yup.object().shape({
      title: Yup.string().max(100).required('제목은 필수입니다.'),
      targetCompanyTypeCode: Yup.string().max(100).required('업체 구분은 필수입니다.'),
      categoryCode: Yup.string().when(['bbsTypeCode'], {
        is: (bbsTypeCode: string) => {
          return bbsTypeCode === BbsType.pds.code;
        },
        then: (schema) => {
          return schema.max(255).required('자료구분은 필수입니다.');
        },
        otherwise: (schema) => {
          return schema;
        }
      })
    }),
    onSubmit: async (values, { setErrors, setStatus, setSubmitting }) => {
      try {
        setStatus({ success: true });
        setSubmitting(false);

        if (values.bbsId) {
          bbsServiceApi
            .updateBbs({
              bbs: {
                ...values,
                content: editorRef.current.getContent() ?? ''
              }
            })
            .then((response) => {
              navigate(`/${companyTypePath}/operation/management/${_.toLower(bbsType)}-list`, {
                state: { bbsId: response.data.bbs?.bbsId }
              });
            });
        } else {
          bbsServiceApi
            .createBbs({
              bbs: {
                ...values,
                content: editorRef.current.getContent() ?? ''
              }
            })
            .then((response) => {
              navigate(`/${companyTypePath}/operation/management/${_.toLower(bbsType)}-list`, {
                state: { bbsId: response.data.bbs?.bbsId }
              });
            });
        }
      } catch (err: any) {
        console.error(err);
        setStatus({ success: false });
        setErrors({ submit: err.message });
        setSubmitting(false);
      }
    },
    enableReinitialize: true
  });

  const changeFiles = useCallback(
    (fieldName: string, files: FileInformation[]) => {
      formik.setFieldValue(fieldName, files);
    },
    [formik]
  );

  return (
    <>
      <MainCard border={false}>
        <Box sx={{ borderColor: 'divider', width: '100%' }}>
          <Stack direction={'row'} spacing={2} sx={{ p: 0, width: '100%' }}>
            <Stack>
              {edit && (
                <EditableSelect
                  size="small"
                  edit={true}
                  textWidth={80}
                  value={formik.values.targetCompanyTypeCode}
                  placeholder="업체구분"
                  toName={() => toValue(CompanyTypeCode, formik.values.targetCompanyTypeCode)?.name}
                  error={Boolean(formik.touched.targetCompanyTypeCode && formik.errors.targetCompanyTypeCode)}
                  onChange={(event) => {
                    formik.setFieldValue('targetCompanyTypeCode', event.target.value);
                  }}
                  sx={{
                    p: 0,
                    m: 0,
                    mr: 0.5,
                    width: 200
                  }}
                  displayEmpty
                >
                  <MenuItem key={0} value={''}>
                    업체구분
                  </MenuItem>
                  <MenuItem key={1} value={'ALL'}>
                    전체
                  </MenuItem>
                  {Object.values(CompanyTypeCode).map((company, i) => (
                    <MenuItem key={i + 2} value={company.code}>
                      {company.name}
                    </MenuItem>
                  ))}
                </EditableSelect>
              )}
              {formik.touched.targetCompanyTypeCode && formik.errors.targetCompanyTypeCode && (
                <FormHelperText error id="helper-text-targetCompanyTypeCode" sx={{ pl: 1 }}>
                  {formik.errors.targetCompanyTypeCode as string}
                </FormHelperText>
              )}
            </Stack>
            {bbsType === BbsType.pds.code && (
              <Stack>
                <EditableSelect
                  size="small"
                  edit={edit}
                  value={formik.values.categoryCode}
                  textWidth={80}
                  toName={() => code.getCode(CodeGroupName.PdsTypeCode, formik.values.categoryCode)?.codeName ?? ''}
                  error={Boolean(formik.touched.categoryCode && formik.errors.categoryCode)}
                  placeholder="업체구분"
                  onChange={(event) => {
                    formik.setFieldValue('categoryCode', event.target.value);
                  }}
                  sx={{
                    p: 0,
                    m: 0,
                    mr: 0.5,
                    width: 200
                  }}
                  displayEmpty
                >
                  <MenuItem key={0} value={''}>
                    자료구분
                  </MenuItem>
                  {pdsTypeList.map((code, i) => (
                    <MenuItem key={i + 1} value={code.code}>
                      {code.codeName}
                    </MenuItem>
                  ))}
                </EditableSelect>
                {formik.touched.categoryCode && formik.errors.categoryCode && (
                  <FormHelperText error id="helper-text-categoryCode" sx={{ pl: 1 }}>
                    {formik.errors.categoryCode as string}
                  </FormHelperText>
                )}
              </Stack>
            )}
            <Stack sx={{ p: 0, width: '100%' }}>
              <EditableTextField
                id="title"
                type="text"
                size="small"
                edit={edit}
                value={formik.values.title}
                name="title"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                placeholder="제목"
                fullWidth
                error={Boolean(formik.touched.title && formik.errors.title)}
                helperText={formik.touched.title && formik.errors.title}
              />
            </Stack>
          </Stack>
        </Box>
        <Box sx={{ mt: 2.5, mb: 2.5 }}>
          <Editor
            apiKey={'i7f7b7bn9xjgavovb0i8qihl3cknwhooownxseopvqdqeerd'}
            onInit={(_evt, editor) => (editorRef.current = editor)}
            initialValue={content}
            disabled={!edit}
            init={{
              height: bbsType === BbsType.notice.code ? 700 : 500,
              plugins:
                user?.company?.companyTypeCode !== CompanyTypeCode.rent25.code
                  ? ''
                  : 'preview importcss searchreplace autolink autosave save directionality code visualblocks visualchars fullscreen image link media codesample table charmap pagebreak nonbreaking anchor insertdatetime advlist lists wordcount help charmap quickbars emoticons accordion',
              editimage_cors_hosts: ['picsum.photos'],
              menubar:
                user?.company?.companyTypeCode !== CompanyTypeCode.rent25.code ? '' : 'file edit view insert format tools table help',
              toolbar:
                user?.company?.companyTypeCode !== CompanyTypeCode.rent25.code
                  ? ''
                  : 'undo redo | accordion accordionremove | blocks fontfamily fontsize | bold italic underline strikethrough | align numlist bullist | link image | table media | lineheight outdent indent| forecolor backcolor removeformat | charmap emoticons | code fullscreen preview | save print | pagebreak anchor codesample | ltr rtl',
              // quickbars_selection_toolbar: 'bold italic | quicklink h2 h3 blockquote quicktable',
              content_style: 'body { font-family:맑은 고딕,Helvetica,Arial,sans-serif; font-size:14px }',
              toolbar_mode: 'sliding',
              language: 'ko_KR' // 언어를 한글로 설정
            }}
          />
        </Box>
        {bbsType === BbsType.pds.code && (
          <DragAndDropUpload
            edit={edit}
            type="files"
            realmGroupCode={RealmGroupCode.pds_attach_files.code}
            name="attachFiles"
            defaultFileInformations={formik.values.attachFiles}
            onChange={(files: FileInformation[]) => {
              changeFiles('attachFiles', files);
            }}
          ></DragAndDropUpload>
        )}
      </MainCard>
      <Box
        sx={{
          zIndex: 1,
          position: 'fixed',
          bottom: 0,
          left: 0,
          right: 0,
          bgcolor: 'rgba(250, 250, 250, 0.9)',
          height: '70px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-end',
          px: 2,
          borderTop: '1px solid rgba(0, 0, 0, 0.12)'
        }}
      >
        <Divider />
        <Stack direction="row" justifyContent="end" alignItems="center" spacing={5} sx={{ mt: 0, ml: 10, mr: 5, width: '100%' }}>
          {edit ? (
            <>
              <Button
                onClick={() => {
                  navigate(`/${companyTypePath}/operation/management/${_.toLower(bbsType)}-list`);
                }}
                variant="outlined"
              >
                {'목록'}
              </Button>
              <Button onClick={handleSubmit} variant="contained">
                {bbsId ? '저장' : '등록'}
              </Button>
            </>
          ) : (
            <>
              <Button
                onClick={() => {
                  navigate(`/${companyTypePath}/operation/management/${_.toLower(bbsType)}-list`);
                }}
                variant="outlined"
              >
                {'목록'}
              </Button>
              {user?.company?.companyTypeCode === CompanyTypeCode.rent25.code && (
                <Button
                  onClick={() => {
                    setEdit(true);
                  }}
                  variant="contained"
                >
                  {'수정'}
                </Button>
              )}
            </>
          )}
        </Stack>
      </Box>
    </>
  );
};

export default BbsViewer;
