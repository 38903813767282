import { Chip } from '@mui/material';
import { CodeGroupName } from 'constant/Constant';
import { useCodes } from 'contexts/CodeContext';
import { Code } from 'openapi/models';
import React, { useEffect, useState } from 'react';

type Props = {
  userStatusCode: string;
};

const UserStatusChip = ({ userStatusCode }: Props) => {
  const { getCode } = useCodes();
  const [statusCode, setStatusCode] = useState<Code>({} as Code);

  useEffect(() => {
    const code = getCode(CodeGroupName.UserStatusCode, userStatusCode);
    setStatusCode(code || ({} as Code));
  }, [getCode, userStatusCode]);

  return (
    <Chip
      label={statusCode.codeName || ''}
      size="small"
      color={`${
        (statusCode.codeAttribute1 as 'default' | 'primary' | 'info' | 'secondary' | 'error' | 'success' | 'warning' | undefined | null) ||
        'primary'
      }`}
      sx={{ fontSize: '0.675rem' }}
    />
  );
};

export default UserStatusChip;
