// assets
import { LocationDiscover, Bank, LikeTag, Level, Layer, Magicpen, Mask, HeartSearch, Harmony, Car, KeyboardOpen } from 'iconsax-react';

// type
import { NavItemType } from 'types/menu';

// icons
const icons = {
  Bank: Bank,
  LikeTag: LikeTag,
  Level: Level,
  Layer: Layer,
  Magicpen: Magicpen,
  Mask: Mask,
  HeartSearch: HeartSearch,
  Harmony: Harmony,
  LocationDiscover: LocationDiscover,
  Car: Car,
  KeyboardOpen: KeyboardOpen
};

// ==============================|| MENU ITEMS - SUPPORT ||============================== //
const authorizationAgentUnionMenus: NavItemType[] = [
  {
    id: '5001',
    title: 'AGUNION',
    type: 'group',
    children: [
      {
        id: '20010',
        title: 'Dashboard',
        type: 'item',
        url: '/dashboard',
        icon: icons['KeyboardOpen']
      },
      {
        id: '50011',
        title: '회사 관리',
        type: 'collapse',
        icon: icons['Layer'],
        children: [
          {
            id: '500111',
            title: '회사정보',
            type: 'item',
            url: '/agent-union/my-company/information',
            icon: icons['LocationDiscover']
          },
          {
            id: '500112',
            title: '직원정보',
            type: 'item',
            url: '/agent-union/my-company/employee',
            icon: icons.Bank
          }
        ]
      },
      {
        id: '50012',
        title: '업체 관리',
        type: 'collapse',
        icon: icons.Level,
        children: [
          {
            id: '500121',
            title: '에이전트 목록',
            type: 'item',
            url: '/agent-union/company/list',
            icon: icons.Bank
          }
        ]
      },
      {
        id: '50013',
        title: '차량 관리',
        type: 'collapse',
        icon: icons.Car,
        children: [
          {
            id: '500131',
            title: '보유 차량 정보',
            type: 'item',
            url: '/agent-union/car/new/list',
            icon: icons.Car
          },
          {
            id: '500132',
            title: '보유 차량',
            type: 'hidden',
            url: '/agent-union/car/new/view',
            icon: icons.Car
          }
        ]
      },
      {
        id: '50014',
        title: '정산 관리',
        type: 'collapse',
        url: '#',
        icon: icons.Harmony,
        children: []
      },
      {
        id: '50015',
        title: '통계',
        type: 'collapse',
        url: '#',
        icon: icons.Harmony,
        children: []
      },
      {
        id: '20017',
        title: '게시판',
        type: 'collapse',
        icon: icons.LocationDiscover,
        children: [
          {
            id: '200171',
            title: '공지사항',
            type: 'item',
            url: '/agent-union/operation/management/notice-list',
            icon: icons.Bank
          },
          {
            id: '200172',
            title: '공지사항',
            type: 'hidden',
            url: '/agent-union/operation/management/notice-view',
            icon: icons.LikeTag
          },
          {
            id: '200173',
            title: '자료실',
            type: 'item',
            url: '/agent-union/operation/management/pds-list',
            icon: icons.Bank
          },
          {
            id: '200174',
            title: '자료실',
            type: 'hidden',
            url: '/agent-union/operation/management/pds-view',
            icon: icons.LikeTag
          }
        ]
      }
    ]
  }
];

export default authorizationAgentUnionMenus;
