// assets
import { LocationDiscover, Bank, LikeTag, Level, Layer, Magicpen, Mask, HeartSearch, Harmony, Car, KeyboardOpen } from 'iconsax-react';

// type
import { NavItemType } from 'types/menu';

// icons
const icons = {
  Bank: Bank,
  LikeTag: LikeTag,
  Level: Level,
  Layer: Layer,
  Magicpen: Magicpen,
  Mask: Mask,
  HeartSearch: HeartSearch,
  Harmony: Harmony,
  LocationDiscover: LocationDiscover,
  KeyboardOpen: KeyboardOpen,
  Car: Car
};

// ==============================|| MENU ITEMS - SUPPORT ||============================== //
const authorizationRent25Menus: NavItemType[] = [
  {
    id: '3001',
    title: 'RENT25',
    type: 'group',
    children: [
      {
        id: '20010',
        title: 'Dashboard',
        type: 'item',
        url: '/dashboard',
        icon: icons['KeyboardOpen']
      },
      {
        id: '30011',
        title: '회사 관리',
        type: 'collapse',
        icon: icons['Layer'],
        children: [
          {
            id: '300112',
            title: '회사정보',
            type: 'item',
            url: '/rent25/my-company/information',
            icon: icons['LocationDiscover']
          },
          {
            id: '300113',
            title: '직원정보',
            type: 'item',
            url: '/rent25/my-company/employee',
            icon: icons.Bank
          }
        ]
      },
      {
        id: '30012',
        title: '회원 관리',
        type: 'collapse',
        icon: icons.HeartSearch,
        children: [
          {
            id: '300121',
            title: '회원 목록',
            type: 'item',
            url: '/rent25/user/list',
            icon: icons.HeartSearch
          }
        ]
      },
      {
        id: '30013',
        title: '업체 관리',
        type: 'collapse',
        icon: icons.Level,
        children: [
          {
            id: '300131',
            title: '업체 등록',
            type: 'item',
            url: '/rent25/company/registration',
            icon: icons.LocationDiscover
          },
          {
            id: '300132',
            title: '업체 목록',
            type: 'item',
            url: '/rent25/company/list',
            icon: icons.Bank
          }
        ]
      },
      {
        id: '30014',
        title: '차량 관리',
        type: 'collapse',
        icon: icons.Car,
        children: [
          {
            id: '300141',
            title: '선구매 차량 목록',
            type: 'item',
            url: '/rent25/car/new/list',
            icon: icons.Car
          },
          {
            id: '400123',
            title: '차량 정보 수정',
            type: 'hidden',
            url: '/rent25/car/new/update',
            icon: icons.Car
          },
          {
            id: '300142',
            title: '차량 정보 보기',
            type: 'hidden',
            url: '/rent25/car/new/view',
            icon: icons.Car
          }
        ]
      },
      {
        id: '30015',
        title: '견적 관리',
        type: 'collapse',
        icon: icons.LocationDiscover,
        children: [
          {
            id: '300151',
            title: '견적 목록',
            type: 'item',
            url: '/rent25/estimate/list',
            icon: icons.Bank
          },
          {
            id: '300152',
            title: '견적 내기',
            type: 'item',
            url: '/rent25/estimate/simulation',
            icon: icons.Bank
          },
          {
            id: '200155',
            title: '견적 비교',
            type: 'item',
            url: '/rent25/estimate/comparison',
            icon: icons.Bank
          },
          {
            id: '300153',
            title: '견적서 수정',
            type: 'hidden',
            url: '/rent25/estimate/update',
            icon: icons.LikeTag
          },
          {
            id: '300154',
            title: '견적서 정보',
            type: 'hidden',
            url: '/rent25/estimate/view',
            icon: icons.LikeTag
          }
        ]
      },
      {
        id: '30016',
        title: '견적 설정 관리',
        type: 'collapse',
        icon: icons.LocationDiscover,
        children: [
          {
            id: '300161',
            title: '차량 가격별 그룹설정',
            type: 'item',
            url: '/rent25/estimate/setup/vehicle-price-group',
            icon: icons.Bank
          },
          {
            id: '300162',
            title: '보증금 설정',
            type: 'item',
            url: '/rent25/estimate/setup/deposit',
            icon: icons.Bank
          },
          {
            id: '300163',
            title: '그룹별 잔가율 설정',
            type: 'item',
            url: '/rent25/estimate/setup/residual-value',
            icon: icons.Bank
          },
          {
            id: '300164',
            title: '약칭 및 잔가 그룹 설정',
            type: 'item',
            url: '/rent25/estimate/setup/residual-group',
            icon: icons.Bank
          },
          {
            id: '300165',
            title: '가산렌탈료 설정',
            type: 'item',
            url: '/rent25/estimate/setup/add-rental-fee',
            icon: icons.LikeTag
          },
          {
            id: '300166',
            title: '렌트지역/탁송료 설정',
            type: 'item',
            url: '/rent25/estimate/setup/rental-area',
            icon: icons.LikeTag
          },
          {
            id: '300167',
            title: '용품 설정',
            type: 'item',
            url: '/rent25/estimate/setup/supply',
            icon: icons.LikeTag
          },
          {
            id: '300168',
            title: '기타 설정',
            type: 'item',
            url: '/rent25/estimate/setup/etc',
            icon: icons.LikeTag
          }
        ]
      },
      {
        id: '30017',
        title: '운영 관리',
        type: 'collapse',
        url: '#',
        icon: icons.Harmony,
        children: [
          {
            id: '300171',
            title: '문서 관리',
            type: 'item',
            url: '/rent25/operation/management/terms',
            icon: icons.Bank
          },
          {
            id: '300172',
            title: '배너 관리',
            type: 'item',
            url: '/rent25/operation/management/banner',
            icon: icons.LocationDiscover
          },
          {
            id: '300173',
            title: '공지사항 관리',
            type: 'item',
            url: '/rent25/operation/management/notice-list',
            icon: icons.LocationDiscover
          },
          {
            id: '300174',
            title: '공지사항',
            type: 'hidden',
            url: '/rent25/operation/management/notice-view',
            icon: icons.LocationDiscover
          },
          {
            id: '300175',
            title: '공지사항 등록',
            type: 'hidden',
            url: '/rent25/operation/management/notice-register',
            icon: icons.LocationDiscover
          },
          {
            id: '300176',
            title: '자료실 관리',
            type: 'item',
            url: '/rent25/operation/management/pds-list',
            icon: icons.LocationDiscover
          },
          {
            id: '300177',
            title: '자료실',
            type: 'hidden',
            url: '/rent25/operation/management/pds-view',
            icon: icons.LocationDiscover
          },
          {
            id: '300178',
            title: '자료실 등록',
            type: 'hidden',
            url: '/rent25/operation/management/pds-register',
            icon: icons.LocationDiscover
          }
        ]
      },
      {
        id: '30018',
        title: '정산 관리',
        type: 'collapse',
        url: '#',
        icon: icons.Harmony,
        children: []
      },
      {
        id: '30019',
        title: '통계',
        type: 'collapse',
        url: '#',
        icon: icons.Harmony,
        children: []
      }
    ]
  },
  {
    id: '3002',
    title: 'SYSTEM MANAGEMENT',
    type: 'group',
    children: [
      {
        id: '30021',
        title: '메뉴 관리',
        type: 'item',
        url: '/system/management/menu',
        icon: icons.Bank
      },
      {
        id: '30022',
        title: '권한 관리',
        type: 'item',
        url: '/system/management/authorization',
        icon: icons.LikeTag
      },
      {
        id: '30023',
        title: '코드 관리',
        type: 'item',
        url: '/system/management/code',
        icon: icons.Level
      }
    ]
  }
];

export default authorizationRent25Menus;
