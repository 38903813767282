import React, { useCallback, useEffect, useState } from 'react';
import { Accept, DropzoneOptions, useDropzone } from 'react-dropzone';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import {
  CardMedia,
  IconButton,
  ImageList,
  ImageListItem,
  ImageListItemBar,
  LinearProgress,
  List,
  ListItem,
  ListItemText
} from '@mui/material';
import { DocumentText, Trash } from 'iconsax-react';
import DownloadIcon from '@mui/icons-material/Download';
import { fileServiceApi } from 'utils/axios';

import ImagesUpload from 'assets/images/upload/images-upload.svg';
import FilesUpload from 'assets/images/upload/files-upload.svg';
import { Stack, styled } from '@mui/system';
import { FileInformation } from 'openapi/models';
import { openSnackbar } from 'store/reducers/snackbar';
import { dispatch } from 'store';
import { getImageUrl } from 'utils/file-utils';
import _ from 'lodash';
import { toFileSize } from 'utils/string-utils';
import { downloadFile } from 'utils/download-utils';
import AlbumImageView from './AlbumImageView';

const ListWrapper = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.background.paper
}));
ListWrapper.displayName = 'ListWrapper';

export interface FileWithPreview extends File {
  preview: string;
  fileInformation?: FileInformation;
}

export interface DragAndDropUploadProps extends Omit<React.InputHTMLAttributes<HTMLInputElement>, 'onChange'> {
  name: string;
  type?: string;
  cols?: number;
  maxHeight?: number;
  realmGroupCode: string;
  edit?: boolean;
  defaultFileInformations?: FileInformation[];
  onChange?: (files: FileInformation[]) => void;
}

type Props = DragAndDropUploadProps & DropzoneOptions;

const DragAndDropUpload: React.FC<Props> = ({
  onChange,
  name,
  type = 'images',
  realmGroupCode,
  edit = true,
  maxFiles = 20,
  maxHeight = 300,
  cols = 1,
  defaultFileInformations
}) => {
  const [uploading, setUploading] = useState(false);
  const [files, setFiles] = useState<FileInformation[]>([]);
  const [boxStyle, setBoxStyle] = useState<any>({});
  const [rootProps, setRootProps] = useState<any>({});
  const [accepts, setAccepts] = useState<Accept>({
    'image/jpeg': [],
    'image/png': []
  });
  const changeFiles = useCallback(
    (files: FileInformation[]) => {
      setFiles(files);
      onChange?.(files);
    },
    [onChange]
  );

  useEffect(() => {
    setAccepts(
      type === 'files'
        ? {
            'image/jpeg': [],
            'image/png': [],
            'application/pdf': [],
            'application/zip': [],
            'text/plain': []
          }
        : {
            'image/jpeg': [],
            'image/gif': [],
            'image/png': []
          }
    );
  }, [type]);

  useEffect(() => {
    if (defaultFileInformations) {
      setFiles(_.cloneDeep(defaultFileInformations));
    }
  }, [defaultFileInformations]);

  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      console.log('DEBUG', acceptedFiles.length, files.length, maxFiles);
      if (acceptedFiles.length + files.length > maxFiles) {
        dispatch(
          openSnackbar({
            open: true,
            message: `파일은 최대 ${maxFiles}개까지 선택 가능합니다.`,
            variant: 'alert',
            alert: {
              color: 'error'
            },
            close: false
          })
        );
      } else if (acceptedFiles.length > 0) {
        setUploading(true);
        fileServiceApi
          .uploadFiles(realmGroupCode, acceptedFiles)
          .then((response) => {
            const uploadFiles = response.data?.fileInformations;
            const merged = [...uploadFiles, ...files];

            changeFiles(merged);
          })
          .catch((error) => {
            console.error(error);
          })
          .finally(() => {
            setUploading(false);
          });
      }
    },
    [files, maxFiles, realmGroupCode, changeFiles]
  );

  const { getRootProps, getInputProps } = useDropzone({
    maxFiles,
    accept: accepts,
    onDrop
  });

  useEffect(() => {
    setBoxStyle(
      edit
        ? { border: '1px dashed gray', padding: '10px', textAlign: 'center', cursor: 'pointer' }
        : { border: '1px gray', padding: '0px', margin: '0px', textAlign: 'center' }
    );

    setRootProps(edit ? getRootProps() : {});
  }, [edit, getRootProps]);

  return (
    <Box {...rootProps} sx={boxStyle}>
      {uploading && <LinearProgress />}
      {edit && maxFiles !== files.length && (
        <Stack direction="column" alignItems={'center'}>
          <input name={name} {...getInputProps()} />
          <CardMedia
            component="img"
            image={type === 'images' ? ImagesUpload : FilesUpload}
            sx={{ width: type === 'images' ? 60 : 50, mb: '10px' }}
          />
          <Typography variant="h6" sx={{ fontSize: '0.7rem' }}>
            파일을 여기에 드래그 또는 클릭하여 선택
          </Typography>
        </Stack>
      )}
      {files.length > 0 ? (
        type === 'images' ? (
          edit ? (
            <ImageList sx={{ width: '100%', p: 0, m: 0 }} cols={cols} style={boxStyle}>
              {files.map((file, index) => (
                <ImageListItem key={`imageListItem-${index}`} sx={{ p: 0, m: 0 }}>
                  <img
                    src={getImageUrl(file.fileUuid)}
                    srcSet={getImageUrl(file.fileUuid)}
                    style={{ width: '100%', maxHeight: `${maxHeight}px` }}
                    alt="preview"
                    loading="lazy"
                  />
                  <ImageListItemBar
                    key={`bar-${index}`}
                    title={file.originalFileName!}
                    actionIcon={
                      <IconButton
                        sx={{ p: 0.5, mr: '10px', color: '#d31c1c', width: '24px', height: '24px', backgroundColor: '#F8F9FA99' }}
                        onClick={(event) => {
                          event.stopPropagation();
                          changeFiles(files.filter((f) => f.fileUuid !== file.fileUuid));
                        }}
                      >
                        <Trash size={14} color="red" />
                      </IconButton>
                    }
                    actionPosition="right"
                    sx={{
                      background: 'linear-gradient(to bottom, rgba(0,0,0,0.4) 20%, rgba(0,0,0,0.4) 50%, rgba(0,0,0,0.4) 100%)',
                      '&:hover': {
                        actionIcon: {
                          opacity: 1
                        }
                      },
                      '.MuiImageListItemBar-title': {
                        // titleWrap 클래스에 대한 스타일 지정
                        fontSize: '0.7rem' // 예시 폰트 크기
                      }
                    }}
                  />
                </ImageListItem>
              ))}
            </ImageList>
          ) : (
            <AlbumImageView images={files.map((file) => getImageUrl(file.fileUuid)!)} currentIndex={0}></AlbumImageView>
          )
        ) : (
          <ListWrapper>
            <List dense={true}>
              {files.map((file, index) => (
                <ListItem
                  secondaryAction={
                    <>
                      <IconButton
                        edge="end"
                        aria-label="delete"
                        color="info"
                        size="small"
                        onClick={(event) => {
                          event.stopPropagation();
                          downloadFile(file);
                          // changeFiles(files.filter((f) => f.fileUuid !== file.fileUuid));
                        }}
                      >
                        <DownloadIcon fontSize="small"></DownloadIcon>
                      </IconButton>
                      {edit && (
                        <IconButton
                          edge="end"
                          aria-label="delete"
                          color="error"
                          size="small"
                          onClick={(event) => {
                            event.stopPropagation();
                            changeFiles(files.filter((f) => f.fileUuid !== file.fileUuid));
                          }}
                        >
                          <Trash variant="Bold" />
                        </IconButton>
                      )}
                    </>
                  }
                >
                  <DocumentText variant="Bulk" size="32" color="#697689" />
                  <ListItemText
                    primary={file.originalFileName}
                    secondary={toFileSize(file.fileSize)}
                    sx={{
                      ml: 1
                      // '& .MuiListItemText-primary': {
                      //   fontWeight: 'bold' // 예시: primary 텍스트를 굵게 변경
                      // }
                    }}
                  />
                </ListItem>
              ))}
            </List>
          </ListWrapper>
        )
      ) : (
        <Typography variant="caption">첨부 파일이 없습니다.</Typography>
      )}
    </Box>
  );
};

export default DragAndDropUpload;
