import { CompanyTypeCode } from 'constant/Constant';
import { format, parseISO } from 'date-fns';
import _ from 'lodash';
import { Address } from 'openapi';

export function takeWords(inputString: string | undefined, count: number): string {
  if (undefined === inputString) {
    return '';
  }
  // 공백을 기준으로 문자열을 배열로 분할
  const words = _.split(inputString.trim(), ' ');
  return _.join(_.take(words, words.length < count ? words.length : count), ' ');
}

export function today() {
  const today = new Date();

  // 년도, 월, 일을 각각 추출
  const year = today.getFullYear();
  const month = today.getMonth() + 1; // getMonth()는 0부터 11까지 반환하므로 1을 더해줍니다.
  const day = today.getDate();

  // 월과 일이 10보다 작으면 앞에 '0'을 붙여서 두 자리로 만듭니다.
  const formattedMonth = month < 10 ? `0${month}` : month;
  const formattedDay = day < 10 ? `0${day}` : day;

  // 최종적으로 'YYYY-MM-DD' 형식으로 문자열을 만듭니다.
  const dateString = `${year}-${formattedMonth}-${formattedDay}`;

  return dateString;
}

export function toDateFormatString(date?: Date | string | null | undefined, formatString: string = 'yyyy-MM-dd') {
  try {
    if (date) {
      if (typeof date === 'string') {
        date = parseISO(date);
      }
      return format(date, formatString);
    }
  } catch {
    return '';
  }

  return '';
}

export function toDateString(date?: Date | string | null | undefined) {
  return toDateFormatString(date);
}

export function toDate(date?: Date | string | null | undefined) {
  if (date) {
    if (typeof date === 'string') {
      return parseISO(date);
    }

    return date;
  }

  return null;
}

export function toCompanyTypePath(companyTypeCode: string | undefined) {
  return _.find(Object.values(CompanyTypeCode), { code: companyTypeCode })?.path;
}

export function toFileSize(bytes: number = 0) {
  const k = 1024;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];

  if (bytes === 0) return '0 Byte';

  const i = Math.floor(Math.log(bytes) / Math.log(k));
  const size = parseFloat((bytes / Math.pow(k, i)).toFixed(2));

  return `${size} ${sizes[i]}`;
}

export function toAddress(addressTypeCode: string, addresses?: Address[]) {
  if (!(addresses && addresses.length > 0)) {
    return '';
  }

  const address = _.find(addresses, { addressTypeCode: addressTypeCode });
  if (address) {
    return `(${address.postCode}) ${address.address} ${address.addressDetail}`;
  }

  return '';
}
