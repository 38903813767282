import { BbsType } from 'constant/Constant';
import React from 'react';
import BbsViewer from 'sections/rent25/operation/BbsViewer';

type Props = {};

const PdsRegisterPage = (props: Props) => {
  return <BbsViewer bbsType={BbsType.pds.code} viewType="create" />;
};

export default PdsRegisterPage;
