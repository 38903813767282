import { Code, CodeGroup } from 'openapi/models';
import React, { createContext, useContext, useState, ReactElement, useEffect } from 'react';
import _ from 'lodash';
import { codeServiceApi } from 'utils/axios';

interface CodeContextType {
  codeGroups: CodeGroup[];
  getCodes: (groupCode: string) => Code[];
  getCode: (groupCode: string, codeCode: string) => Code | undefined;
  toCodeName: (codeGroupCode: string, codeCode: string) => string;
}

const CodeContext = createContext<CodeContextType | undefined>(undefined);

export const CodeProvider = ({ children }: { children: ReactElement }) => {
  const [codeGroups, setCodeGroups] = useState<CodeGroup[]>([] as CodeGroup[]);

  useEffect(() => {
    codeServiceApi.searchCodeGroups({}).then((response) => {
      setCodeGroups(response.data?.codeGroups ?? []);
    });
  }, []);

  const getCodes = (groupCode: string) => {
    let codeGroup = _.find(codeGroups, { codeGroupCode: groupCode });
    return codeGroup?.codes || [];
  };

  const getCode = (codeGroupCode: string, codeCode: string) => {
    const codeGroup = getCodes(codeGroupCode);
    if (codeGroup) {
      const code = _.find(codeGroup, { code: codeCode });
      return code || undefined;
    }

    return undefined;
  };

  const toCodeName = (codeGroupCode: string, codeCode: string) => {
    const codeGroup = getCodes(codeGroupCode);
    if (codeGroup) {
      const code = _.find(codeGroup, { code: codeCode });
      return code?.codeName || '';
    }

    return '';
  };

  return <CodeContext.Provider value={{ codeGroups, getCodes, getCode, toCodeName }}>{children}</CodeContext.Provider>;
};

export const useCodes = () => {
  const context = useContext(CodeContext);
  if (context === undefined) {
    throw new Error('useCodes must be used within a CodeProvider');
  }
  return context;
};
