// project-imports
import ImageViewPage from 'pages/public/image-view';

// ==============================|| AUTH ROUTES ||============================== //

const PublicRoutes = {
  path: 'image-view',
  element: <ImageViewPage />
};

export default PublicRoutes;
