// assets
import { LocationDiscover, Bank, LikeTag, Level, Layer, Magicpen, Mask, HeartSearch, Harmony, Car, KeyboardOpen } from 'iconsax-react';

// type
import { NavItemType } from 'types/menu';

// icons
const icons = {
  Bank: Bank,
  LikeTag: LikeTag,
  Level: Level,
  Layer: Layer,
  Magicpen: Magicpen,
  Mask: Mask,
  HeartSearch: HeartSearch,
  Harmony: Harmony,
  LocationDiscover: LocationDiscover,
  KeyboardOpen: KeyboardOpen,
  Car: Car
};

// ==============================|| MENU ITEMS - SUPPORT ||============================== //
const authorizationAgentMenus: NavItemType[] = [
  {
    id: '2001',
    title: 'AGENT',
    type: 'group',
    children: [
      {
        id: '20010',
        title: 'Dashboard',
        type: 'item',
        url: '/dashboard',
        icon: icons['KeyboardOpen']
      },
      {
        id: '20011',
        title: '회사 관리',
        type: 'collapse',
        icon: icons['Layer'],
        children: [
          {
            id: '200111',
            title: '회사정보',
            type: 'item',
            url: '/agent/my-company/information',
            icon: icons['LocationDiscover']
          },
          {
            id: '200112',
            title: '직원정보',
            type: 'hidden',
            url: '/agent/my-company/employee',
            icon: icons.Bank
          }
        ]
      },
      {
        id: '20012',
        title: '차량 관리',
        type: 'collapse',
        icon: icons.Car,
        children: [
          {
            id: '200121',
            title: '선구매 차량목록',
            type: 'item',
            url: '/agent/car/new/list',
            icon: icons.Car
          },
          {
            id: '200122',
            title: '차량 상세 정보',
            type: 'hidden',
            url: '/agent/car/new/view',
            icon: icons.Car
          }
        ]
      },
      {
        id: '20013',
        title: '견적 관리',
        type: 'collapse',
        icon: icons.LocationDiscover,
        children: [
          {
            id: '200131',
            title: '견적 목록',
            type: 'item',
            url: '/agent/estimate/list',
            icon: icons.Bank
          },
          // {
          //   id: '200132',
          //   title: '견적 내기',
          //   type: 'item',
          //   url: '/agent/estimate/registration',
          //   icon: icons.Bank
          // },
          {
            id: '200133',
            title: '견적서 수정',
            type: 'hidden',
            url: '/agent/estimate/update',
            icon: icons.LikeTag
          },
          {
            id: '200134',
            title: '견적서 정보',
            type: 'hidden',
            url: '/agent/estimate/view',
            icon: icons.LikeTag
          },
          {
            id: '200135',
            title: '견적 비교',
            type: 'item',
            url: '/agent/estimate/comparison',
            icon: icons.Bank
          }
          // {
          //   id: '200135',
          //   title: '견적 비교',
          //   type: 'hidden',
          //   url: '/agent/estimate/comparison',
          //   icon: icons.LikeTag
          // }
        ]
      },
      {
        id: '20014',
        title: '정산 관리',
        type: 'collapse',
        url: '#',
        icon: icons.Harmony,
        children: []
      },
      {
        id: '20015',
        title: '통계',
        type: 'collapse',
        url: '#',
        icon: icons.Harmony,
        children: []
      },
      {
        id: '20016',
        title: '게시판',
        type: 'collapse',
        icon: icons.LocationDiscover,
        children: [
          {
            id: '200161',
            title: '공지사항',
            type: 'item',
            url: '/agent/operation/management/notice-list',
            icon: icons.Bank
          },
          {
            id: '200162',
            title: '공지사항',
            type: 'hidden',
            url: '/agent/operation/management/notice-view',
            icon: icons.LikeTag
          },
          {
            id: '200163',
            title: '자료실',
            type: 'item',
            url: '/agent/operation/management/pds-list',
            icon: icons.Bank
          },
          {
            id: '200164',
            title: '자료실',
            type: 'hidden',
            url: '/agent/operation/management/pds-view',
            icon: icons.LikeTag
          }
        ]
      }
    ]
  }
];

export default authorizationAgentMenus;
