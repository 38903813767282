import { useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
// material-ui
import { useTheme } from '@mui/material/styles';
import { Box, CardMedia, Grid, Stack, useMediaQuery } from '@mui/material';

// project-imports
import MainCard from 'components/MainCard';
import Avatar from 'components/@extended/Avatar';
import IconButton from 'components/@extended/IconButton';

// third-party
import Slider from 'react-slick';
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';
import ImageViewer from 'react-simple-image-viewer';

// assets
import { Gallery, ArrowLeft2, ArrowRight2, ArrowRotateRight, SearchZoomIn, SearchZoomOut } from 'iconsax-react';

// types
import { ThemeMode } from 'types/config';

// ==============================|| PRODUCT DETAILS - IMAGES ||============================== //

type Props = {
  images: string[];
  currentIndex?: number;
  onImageClick?: (index: number) => void;
};

const noImage = 'https://www28.cs.kobe-u.ac.jp/wp-content/uploads/2021/04/noimage.png';

const AlbumImageView = ({ images = [], currentIndex = 0, onImageClick = () => {} }: Props) => {
  const theme = useTheme();

  const matchDownLG = useMediaQuery(theme.breakpoints.up('lg'));

  const [imageViewerOpen, setImageViewerOpen] = useState(false);
  const [imageIndex, setImageIndex] = useState(images.length > 0 ? currentIndex : -1);
  const [viewImages, setViewImages] = useState(images);

  useEffect(() => {
    setImageIndex(images.length > 0 ? currentIndex : -1);
    setViewImages(images);
  }, [currentIndex, images]);

  const lgNo = matchDownLG ? 5 : 4;

  const ArrowUp = ({ currentSlide, slideCount, ...props }: any) => (
    <Box
      {...props}
      className={'prev' + (currentSlide === 0 ? ' slick-disabled' : '')}
      aria-hidden="true"
      aria-disabled={currentSlide === 0 ? true : false}
      color="secondary"
      sx={{ cursor: 'pointer', borderRadius: 1 }}
    >
      <ArrowLeft2 style={{ color: theme.palette.secondary.light }} />
    </Box>
  );

  const ArrowDown = ({ currentSlide, slideCount, ...props }: any) => (
    <Box
      {...props}
      color="secondary"
      className={'next' + (currentSlide === slideCount - 1 ? ' slick-disabled' : '')}
      aria-hidden="true"
      aria-disabled={currentSlide === slideCount - 1 ? true : false}
      sx={{ cursor: 'pointer', borderRadius: 1, p: 0.75 }}
    >
      <ArrowRight2 size={18} style={{ color: theme.palette.secondary[400] }} />
    </Box>
  );

  const settings = {
    rows: 1,
    // vertical: !matchDownMD,
    // verticalSwiping: !matchDownMD,
    dots: false,
    centerMode: true,
    swipeToSlide: true,
    focusOnSelect: true,
    centerPadding: '0px',
    slidesToShow: viewImages.length > 3 ? lgNo : viewImages.length,
    prevArrow: <ArrowUp />,
    nextArrow: <ArrowDown />
  };

  return (
    <>
      <Grid container spacing={0.5}>
        <Grid item xs={12}>
          <MainCard
            content={false}
            border={false}
            boxShadow={false}
            sx={{
              m: '0 auto',
              height: '100%',
              display: 'flex',
              alignItems: 'center',
              bgcolor: theme.palette.mode === ThemeMode.DARK ? 'secondary.lighter' : 'secondary.200',
              '& .react-transform-wrapper': { cursor: 'crosshair', height: '100%' },
              '& .react-transform-component': { height: '100%' }
            }}
          >
            <TransformWrapper initialScale={1}>
              {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
                <>
                  <TransformComponent>
                    <CardMedia
                      component="img"
                      image={imageIndex === -1 ? noImage : viewImages[imageIndex]}
                      title="Scroll Zoom"
                      sx={{ borderRadius: `4px`, position: 'relative' }}
                    />
                  </TransformComponent>
                  <Stack direction="row" className="tools" sx={{ position: 'absolute', bottom: 10, left: 10, zIndex: 1 }}>
                    <IconButton
                      color="secondary"
                      disabled={viewImages.length === 0}
                      onClick={() => {
                        onImageClick(imageIndex);
                        setImageViewerOpen(true);
                      }}
                    >
                      <Gallery style={{ fontSize: '1.15rem' }} />
                    </IconButton>
                  </Stack>
                  <Stack direction="row" className="tools" sx={{ position: 'absolute', bottom: 10, right: 10, zIndex: 1 }}>
                    <IconButton color="secondary" disabled={viewImages.length === 0} onClick={() => zoomIn()}>
                      <SearchZoomIn style={{ fontSize: '1.15rem' }} />
                    </IconButton>
                    <IconButton color="secondary" disabled={viewImages.length === 0} onClick={() => zoomOut()}>
                      <SearchZoomOut style={{ fontSize: '1.15rem' }} />
                    </IconButton>
                    <IconButton color="secondary" disabled={viewImages.length === 0} onClick={() => resetTransform()}>
                      <ArrowRotateRight style={{ fontSize: '1.15rem' }} />
                    </IconButton>
                  </Stack>
                </>
              )}
            </TransformWrapper>
          </MainCard>
        </Grid>
        {viewImages.length > 1 && (
          <Grid item xs={12}>
            <Box sx={{ '& .slick-slider': { display: 'flex', alignItems: 'center', mt: 2 } }}>
              <Slider {...settings}>
                {viewImages.map((item, index) => (
                  <Box
                    key={index}
                    onClick={() => {
                      setImageIndex(index);
                    }}
                    sx={{ p: 1 }}
                  >
                    <Avatar
                      size={matchDownLG ? 'xl' : 'md'}
                      src={viewImages[index]}
                      variant="rounded"
                      sx={{
                        m: '0 auto',
                        cursor: 'pointer',
                        bgcolor: theme.palette.secondary[200]
                      }}
                    />
                  </Box>
                ))}
              </Slider>
            </Box>
          </Grid>
        )}
      </Grid>
      {imageViewerOpen &&
        createPortal(
          <ImageViewer
            src={viewImages}
            currentIndex={imageIndex}
            disableScroll={true}
            closeOnClickOutside={true}
            onClose={() => {
              console.log('close');
              setImageViewerOpen(false);
            }}
          />,
          document.getElementById('portal-root')!
        )}
    </>
  );
};

export default AlbumImageView;
