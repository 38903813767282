import { ExpirationTypeCode, GoodsTypeCode } from 'constant/Constant';
import { EstimateGoods, SetupInformation } from 'openapi/models';

export const defaultSetupInformation: SetupInformation = {
  priceGroups: [
    {
      possibleYn: true,
      groupNumber: 1,
      startPrice: 0,
      endPrice: 100000000,
      availableMonths: [12, 24, 36, 48, 60],
      rentMinAge: 21,
      rentMaxAge: 75,
      possibleDeposits: [
        {
          rate: 5,
          possibleYn: true,
          depositSplitYn: true,
          returnRate: 10,
          minAgentFeeRate: 0,
          maxAgentFeeRate: 10
        },
        {
          rate: 7,
          possibleYn: true,
          depositSplitYn: true,
          returnRate: 10,
          minAgentFeeRate: 0,
          maxAgentFeeRate: 10
        },
        {
          rate: 10,
          possibleYn: true,
          depositSplitYn: true,
          returnRate: 10,
          minAgentFeeRate: 0,
          maxAgentFeeRate: 10
        },
        {
          rate: 15,
          possibleYn: true,
          depositSplitYn: true,
          returnRate: 10,
          minAgentFeeRate: 0,
          maxAgentFeeRate: 10
        },
        {
          rate: 20,
          possibleYn: true,
          depositSplitYn: true,
          returnRate: 10,
          minAgentFeeRate: 0,
          maxAgentFeeRate: 10
        },
        {
          rate: 25,
          possibleYn: true,
          depositSplitYn: true,
          returnRate: 10,
          minAgentFeeRate: 0,
          maxAgentFeeRate: 10
        },
        {
          rate: 30,
          possibleYn: true,
          depositSplitYn: true,
          returnRate: 10,
          minAgentFeeRate: 0,
          maxAgentFeeRate: 10
        }
      ],
      maxDepositRate: 30,
      depositSplitRates: [
        {
          number: 1,
          rates: [100],
          possibleYn: true
        },
        {
          number: 2, // 할부회차
          rates: [50, 50], // 할부비율
          possibleYn: true // 할부가능여부
        },
        {
          number: 3, // 할부회차
          rates: [30, 30, 40], // 할부비율
          possibleYn: true // 할부가능여부
        }
      ],
      insuranceProperties: [
        {
          property: 1,
          rentalFee: 0,
          possibleYn: true
        },
        {
          property: 2,
          rentalFee: 0,
          possibleYn: true
        },
        {
          property: 3,
          rentalFee: 0,
          possibleYn: true
        }
      ]
    }
  ],
  additionalRentalFees: [
    {
      itemName: 'GPS',
      mandatoryYn: true,
      yearFee: 0
    },
    {
      itemName: '차고지비용',
      mandatoryYn: true,
      yearFee: 0
    },
    {
      itemName: '자동차세',
      mandatoryYn: true,
      yearFee: 0
    }
  ],
  supplies: [],
  deliveryAreaFees: [
    {
      doName: '서울',
      all: false,
      deliveryFee: 0,
      sis: [
        { siName: '종로구', isUse: false, deliveryFee: 0 },
        { siName: '중구', isUse: false, deliveryFee: 0 },
        { siName: '용산구', isUse: false, deliveryFee: 0 },
        { siName: '성동구', isUse: false, deliveryFee: 0 },
        { siName: '광진구', isUse: false, deliveryFee: 0 },
        { siName: '동대문구', isUse: false, deliveryFee: 0 },
        { siName: '중랑구', isUse: false, deliveryFee: 0 },
        { siName: '성북구', isUse: false, deliveryFee: 0 },
        { siName: '강북구', isUse: false, deliveryFee: 0 },
        { siName: '도봉구', isUse: false, deliveryFee: 0 },
        { siName: '노원구', isUse: false, deliveryFee: 0 },
        { siName: '은평구', isUse: false, deliveryFee: 0 },
        { siName: '서대문구', isUse: false, deliveryFee: 0 },
        { siName: '마포구', isUse: false, deliveryFee: 0 },
        { siName: '양천구', isUse: false, deliveryFee: 0 },
        { siName: '강서구', isUse: false, deliveryFee: 0 },
        { siName: '구로구', isUse: false, deliveryFee: 0 },
        { siName: '금천구', isUse: false, deliveryFee: 0 },
        { siName: '영등포구', isUse: false, deliveryFee: 0 },
        { siName: '동작구', isUse: false, deliveryFee: 0 },
        { siName: '관악구', isUse: false, deliveryFee: 0 },
        { siName: '서초구', isUse: false, deliveryFee: 0 },
        { siName: '강남구', isUse: false, deliveryFee: 0 },
        { siName: '송파구', isUse: false, deliveryFee: 0 },
        { siName: '강동구', isUse: false, deliveryFee: 0 }
      ]
    },
    {
      doName: '부산',
      all: false,
      deliveryFee: 0,
      sis: [
        { siName: '중구', isUse: false, deliveryFee: 0 },
        { siName: '서구', isUse: false, deliveryFee: 0 },
        { siName: '동구', isUse: false, deliveryFee: 0 },
        { siName: '영도구', isUse: false, deliveryFee: 0 },
        { siName: '부산진구', isUse: false, deliveryFee: 0 },
        { siName: '동래구', isUse: false, deliveryFee: 0 },
        { siName: '남구', isUse: false, deliveryFee: 0 },
        { siName: '북구', isUse: false, deliveryFee: 0 },
        { siName: '해운대구', isUse: false, deliveryFee: 0 },
        { siName: '사하구', isUse: false, deliveryFee: 0 },
        { siName: '금정구', isUse: false, deliveryFee: 0 },
        { siName: '강서구', isUse: false, deliveryFee: 0 },
        { siName: '연제구', isUse: false, deliveryFee: 0 },
        { siName: '수영구', isUse: false, deliveryFee: 0 },
        { siName: '사상구', isUse: false, deliveryFee: 0 },
        { siName: '기장군', isUse: false, deliveryFee: 0 }
      ]
    },
    {
      doName: '대구',
      all: false,
      deliveryFee: 0,
      sis: [
        { siName: '중구', isUse: false, deliveryFee: 0 },
        { siName: '동구', isUse: false, deliveryFee: 0 },
        { siName: '서구', isUse: false, deliveryFee: 0 },
        { siName: '남구', isUse: false, deliveryFee: 0 },
        { siName: '북구', isUse: false, deliveryFee: 0 },
        { siName: '수성구', isUse: false, deliveryFee: 0 },
        { siName: '달서구', isUse: false, deliveryFee: 0 },
        { siName: '달성군', isUse: false, deliveryFee: 0 },
        { siName: '군위군', isUse: false, deliveryFee: 0 }
      ]
    },
    {
      doName: '인천',
      all: false,
      deliveryFee: 0,
      sis: [
        { siName: '중구', isUse: false, deliveryFee: 0 },
        { siName: '동구', isUse: false, deliveryFee: 0 },
        { siName: '미추홀구', isUse: false, deliveryFee: 0 },
        { siName: '연수구', isUse: false, deliveryFee: 0 },
        { siName: '남동구', isUse: false, deliveryFee: 0 },
        { siName: '부평구', isUse: false, deliveryFee: 0 },
        { siName: '계양구', isUse: false, deliveryFee: 0 },
        { siName: '서구', isUse: false, deliveryFee: 0 },
        { siName: '강화군', isUse: false, deliveryFee: 0 },
        { siName: '옹진군', isUse: false, deliveryFee: 0 }
      ]
    },
    {
      doName: '광주',
      all: false,
      deliveryFee: 0,
      sis: [
        { siName: '동구', isUse: false, deliveryFee: 0 },
        { siName: '서구', isUse: false, deliveryFee: 0 },
        { siName: '남구', isUse: false, deliveryFee: 0 },
        { siName: '북구', isUse: false, deliveryFee: 0 },
        { siName: '광산구', isUse: false, deliveryFee: 0 }
      ]
    },
    {
      doName: '대전',
      all: false,
      deliveryFee: 0,
      sis: [
        { siName: '동구', isUse: false, deliveryFee: 0 },
        { siName: '중구', isUse: false, deliveryFee: 0 },
        { siName: '서구', isUse: false, deliveryFee: 0 },
        { siName: '유성구', isUse: false, deliveryFee: 0 },
        { siName: '대덕구', isUse: false, deliveryFee: 0 }
      ]
    },
    {
      doName: '울산',
      all: false,
      deliveryFee: 0,
      sis: [
        { siName: '중구', isUse: false, deliveryFee: 0 },
        { siName: '남구', isUse: false, deliveryFee: 0 },
        { siName: '동구', isUse: false, deliveryFee: 0 },
        { siName: '북구', isUse: false, deliveryFee: 0 },
        { siName: '울주군', isUse: false, deliveryFee: 0 }
      ]
    },
    {
      doName: '세종',
      all: false,
      deliveryFee: 0,
      sis: [{ siName: '세종', isUse: false, deliveryFee: 0 }]
    },
    {
      doName: '경기',
      all: false,
      deliveryFee: 0,
      sis: [
        { siName: '수원시', isUse: false, deliveryFee: 0 },
        { siName: '고양시', isUse: false, deliveryFee: 0 },
        { siName: '용인시', isUse: false, deliveryFee: 0 },
        { siName: '성남시', isUse: false, deliveryFee: 0 },
        { siName: '부천시', isUse: false, deliveryFee: 0 },
        { siName: '화성시', isUse: false, deliveryFee: 0 },
        { siName: '안산시', isUse: false, deliveryFee: 0 },
        { siName: '남양주시', isUse: false, deliveryFee: 0 },
        { siName: '안양시', isUse: false, deliveryFee: 0 },
        { siName: '평택시', isUse: false, deliveryFee: 0 },
        { siName: '시흥시', isUse: false, deliveryFee: 0 },
        { siName: '파주시', isUse: false, deliveryFee: 0 },
        { siName: '의정부시', isUse: false, deliveryFee: 0 },
        { siName: '김포시', isUse: false, deliveryFee: 0 },
        { siName: '광주시', isUse: false, deliveryFee: 0 },
        { siName: '광명시', isUse: false, deliveryFee: 0 },
        { siName: '군포시', isUse: false, deliveryFee: 0 },
        { siName: '하남시', isUse: false, deliveryFee: 0 },
        { siName: '오산시', isUse: false, deliveryFee: 0 },
        { siName: '양주시', isUse: false, deliveryFee: 0 },
        { siName: '이천시', isUse: false, deliveryFee: 0 },
        { siName: '구리시', isUse: false, deliveryFee: 0 },
        { siName: '안성시', isUse: false, deliveryFee: 0 },
        { siName: '포천시', isUse: false, deliveryFee: 0 },
        { siName: '의왕시', isUse: false, deliveryFee: 0 },
        { siName: '양평군', isUse: false, deliveryFee: 0 },
        { siName: '여주시', isUse: false, deliveryFee: 0 },
        { siName: '동두천시', isUse: false, deliveryFee: 0 },
        { siName: '가평군', isUse: false, deliveryFee: 0 },
        { siName: '과천시', isUse: false, deliveryFee: 0 },
        { siName: '연천군', isUse: false, deliveryFee: 0 }
      ]
    },
    {
      doName: '강원',
      all: false,
      deliveryFee: 0,
      sis: [
        { siName: '춘천시', isUse: false, deliveryFee: 0 },
        { siName: '원주시', isUse: false, deliveryFee: 0 },
        { siName: '강릉시', isUse: false, deliveryFee: 0 },
        { siName: '동해시', isUse: false, deliveryFee: 0 },
        { siName: '태백시', isUse: false, deliveryFee: 0 },
        { siName: '속초시', isUse: false, deliveryFee: 0 },
        { siName: '삼척시', isUse: false, deliveryFee: 0 },
        { siName: '홍천군', isUse: false, deliveryFee: 0 },
        { siName: '횡성군', isUse: false, deliveryFee: 0 },
        { siName: '영월군', isUse: false, deliveryFee: 0 },
        { siName: '평창군', isUse: false, deliveryFee: 0 },
        { siName: '정선군', isUse: false, deliveryFee: 0 },
        { siName: '철원군', isUse: false, deliveryFee: 0 },
        { siName: '화천군', isUse: false, deliveryFee: 0 },
        { siName: '양구군', isUse: false, deliveryFee: 0 },
        { siName: '인제군', isUse: false, deliveryFee: 0 },
        { siName: '고성군', isUse: false, deliveryFee: 0 },
        { siName: '양양군', isUse: false, deliveryFee: 0 }
      ]
    },
    {
      doName: '충북',
      all: false,
      deliveryFee: 0,
      sis: [
        { siName: '청주시', isUse: false, deliveryFee: 0 },
        { siName: '충주시', isUse: false, deliveryFee: 0 },
        { siName: '제천시', isUse: false, deliveryFee: 0 },
        { siName: '보은군', isUse: false, deliveryFee: 0 },
        { siName: '옥천군', isUse: false, deliveryFee: 0 },
        { siName: '영동군', isUse: false, deliveryFee: 0 },
        { siName: '증평군', isUse: false, deliveryFee: 0 },
        { siName: '진천군', isUse: false, deliveryFee: 0 },
        { siName: '괴산군', isUse: false, deliveryFee: 0 },
        { siName: '음성군', isUse: false, deliveryFee: 0 },
        { siName: '단양군', isUse: false, deliveryFee: 0 }
      ]
    },
    {
      doName: '충남',
      all: false,
      deliveryFee: 0,
      sis: [
        { siName: '천안시', isUse: false, deliveryFee: 0 },
        { siName: '공주시', isUse: false, deliveryFee: 0 },
        { siName: '보령시', isUse: false, deliveryFee: 0 },
        { siName: '아산시', isUse: false, deliveryFee: 0 },
        { siName: '서산시', isUse: false, deliveryFee: 0 },
        { siName: '논산시', isUse: false, deliveryFee: 0 },
        { siName: '계룡시', isUse: false, deliveryFee: 0 },
        { siName: '당진시', isUse: false, deliveryFee: 0 },
        { siName: '금산군', isUse: false, deliveryFee: 0 },
        { siName: '부여군', isUse: false, deliveryFee: 0 },
        { siName: '서천군', isUse: false, deliveryFee: 0 },
        { siName: '청양군', isUse: false, deliveryFee: 0 },
        { siName: '홍성군', isUse: false, deliveryFee: 0 },
        { siName: '예산군', isUse: false, deliveryFee: 0 },
        { siName: '태안군', isUse: false, deliveryFee: 0 }
      ]
    },
    {
      doName: '전북',
      all: false,
      deliveryFee: 0,
      sis: [
        { siName: '전주시', isUse: false, deliveryFee: 0 },
        { siName: '군산시', isUse: false, deliveryFee: 0 },
        { siName: '익산시', isUse: false, deliveryFee: 0 },
        { siName: '정읍시', isUse: false, deliveryFee: 0 },
        { siName: '남원시', isUse: false, deliveryFee: 0 },
        { siName: '김제시', isUse: false, deliveryFee: 0 },
        { siName: '완주군', isUse: false, deliveryFee: 0 },
        { siName: '진안군', isUse: false, deliveryFee: 0 },
        { siName: '무주군', isUse: false, deliveryFee: 0 },
        { siName: '장수군', isUse: false, deliveryFee: 0 },
        { siName: '임실군', isUse: false, deliveryFee: 0 },
        { siName: '순창군', isUse: false, deliveryFee: 0 },
        { siName: '고창군', isUse: false, deliveryFee: 0 },
        { siName: '부안군', isUse: false, deliveryFee: 0 }
      ]
    },
    {
      doName: '전남',
      all: false,
      deliveryFee: 0,
      sis: [
        { siName: '목포시', isUse: false, deliveryFee: 0 },
        { siName: '여수시', isUse: false, deliveryFee: 0 },
        { siName: '순천시', isUse: false, deliveryFee: 0 },
        { siName: '나주시', isUse: false, deliveryFee: 0 },
        { siName: '광양시', isUse: false, deliveryFee: 0 },
        { siName: '담양군', isUse: false, deliveryFee: 0 },
        { siName: '곡성군', isUse: false, deliveryFee: 0 },
        { siName: '구례군', isUse: false, deliveryFee: 0 },
        { siName: '고흥군', isUse: false, deliveryFee: 0 },
        { siName: '보성군', isUse: false, deliveryFee: 0 },
        { siName: '화순군', isUse: false, deliveryFee: 0 },
        { siName: '장흥군', isUse: false, deliveryFee: 0 },
        { siName: '강진군', isUse: false, deliveryFee: 0 },
        { siName: '해남군', isUse: false, deliveryFee: 0 },
        { siName: '영암군', isUse: false, deliveryFee: 0 },
        { siName: '무안군', isUse: false, deliveryFee: 0 },
        { siName: '함평군', isUse: false, deliveryFee: 0 },
        { siName: '영광군', isUse: false, deliveryFee: 0 },
        { siName: '장성군', isUse: false, deliveryFee: 0 },
        { siName: '완도군', isUse: false, deliveryFee: 0 },
        { siName: '진도군', isUse: false, deliveryFee: 0 },
        { siName: '신안군', isUse: false, deliveryFee: 0 }
      ]
    },
    {
      doName: '경북',
      all: false,
      deliveryFee: 0,
      sis: [
        { siName: '포항시', isUse: false, deliveryFee: 0 },
        { siName: '경주시', isUse: false, deliveryFee: 0 },
        { siName: '김천시', isUse: false, deliveryFee: 0 },
        { siName: '안동시', isUse: false, deliveryFee: 0 },
        { siName: '구미시', isUse: false, deliveryFee: 0 },
        { siName: '영주시', isUse: false, deliveryFee: 0 },
        { siName: '영천시', isUse: false, deliveryFee: 0 },
        { siName: '상주시', isUse: false, deliveryFee: 0 },
        { siName: '문경시', isUse: false, deliveryFee: 0 },
        { siName: '경산시', isUse: false, deliveryFee: 0 },
        { siName: '의성군', isUse: false, deliveryFee: 0 },
        { siName: '청송군', isUse: false, deliveryFee: 0 },
        { siName: '영양군', isUse: false, deliveryFee: 0 },
        { siName: '영덕군', isUse: false, deliveryFee: 0 },
        { siName: '청도군', isUse: false, deliveryFee: 0 },
        { siName: '고령군', isUse: false, deliveryFee: 0 },
        { siName: '성주군', isUse: false, deliveryFee: 0 },
        { siName: '칠곡군', isUse: false, deliveryFee: 0 },
        { siName: '예천군', isUse: false, deliveryFee: 0 },
        { siName: '봉화군', isUse: false, deliveryFee: 0 },
        { siName: '울진군', isUse: false, deliveryFee: 0 },
        { siName: '울릉군', isUse: false, deliveryFee: 0 }
      ]
    },
    {
      doName: '경남',
      all: false,
      deliveryFee: 0,
      sis: [
        { siName: '창원시', isUse: false, deliveryFee: 0 },
        { siName: '진주시', isUse: false, deliveryFee: 0 },
        { siName: '통영시', isUse: false, deliveryFee: 0 },
        { siName: '사천시', isUse: false, deliveryFee: 0 },
        { siName: '김해시', isUse: false, deliveryFee: 0 },
        { siName: '밀양시', isUse: false, deliveryFee: 0 },
        { siName: '거제시', isUse: false, deliveryFee: 0 },
        { siName: '양산시', isUse: false, deliveryFee: 0 },
        { siName: '의령군', isUse: false, deliveryFee: 0 },
        { siName: '함안군', isUse: false, deliveryFee: 0 },
        { siName: '창녕군', isUse: false, deliveryFee: 0 },
        { siName: '고성군', isUse: false, deliveryFee: 0 },
        { siName: '남해군', isUse: false, deliveryFee: 0 },
        { siName: '하동군', isUse: false, deliveryFee: 0 },
        { siName: '산청군', isUse: false, deliveryFee: 0 },
        { siName: '함양군', isUse: false, deliveryFee: 0 },
        { siName: '거창군', isUse: false, deliveryFee: 0 },
        { siName: '합천군', isUse: false, deliveryFee: 0 }
      ]
    },
    {
      doName: '제주',
      all: false,
      deliveryFee: 0,
      sis: [
        { siName: '제주시', isUse: false, deliveryFee: 0 },
        { siName: '서귀포시', isUse: false, deliveryFee: 0 }
      ]
    }
  ],
  carAdditionals: [],
  etc: {
    driverTypes: [
      {
        name: '개인',
        code: 'C01',
        addDriverType: 'C01',
        documents: []
      },
      {
        name: '개인사업자',
        code: 'C02',
        addDriverType: 'C01',
        documents: []
      },
      {
        name: '법인사업자',
        code: 'C03',
        addDriverType: 'C04',
        documents: []
      }
    ],
    interestRate: 0,
    registrationTaxRate: 2.0,
    acquisitionTaxRate: 2.0,
    discountApplicationRate: 100,
    etcAdditionalFee: 0,
    estimateBalanceRate: [0, 0, 0, 0],
    insuranceIndemnityPersonFee: '', // 보험명책금 - 대인
    insuranceIndemnityPropertyFee: '', // 보험명책금 - 대물
    insuranceIndemnityCarFee: '', // 보험명책금 - 자차
    insuranceIndemnityCarLimitFee: 0,
    description: '' // 보험 자차 한도액 설정
  },
  defaultEstimateGoods: [
    {
      estimateYn: 'Y',
      engagementDistance: 20000,
      guarantyRate: 10,
      term: 24,
      goodsCode: GoodsTypeCode.long.code,
      goodsName: GoodsTypeCode.long.name,
      expirationTypeCode: ExpirationTypeCode.choice.code,
      expirationTypeName: ExpirationTypeCode.choice.name
    },
    {
      estimateYn: 'N',
      engagementDistance: 20000,
      guarantyRate: 10,
      term: 36,
      goodsCode: GoodsTypeCode.long.code,
      goodsName: GoodsTypeCode.long.name,
      expirationTypeCode: ExpirationTypeCode.choice.code,
      expirationTypeName: ExpirationTypeCode.choice.name
    },
    {
      estimateYn: 'N',
      engagementDistance: 20000,
      guarantyRate: 10,
      term: 48,
      goodsCode: GoodsTypeCode.long.code,
      goodsName: GoodsTypeCode.long.name,
      expirationTypeCode: ExpirationTypeCode.choice.code,
      expirationTypeName: ExpirationTypeCode.choice.name
    }
  ]
};

export const defaultCarRegistrationProducts: Array<EstimateGoods> = [
  {
    engagementDistance: 20000,
    term: 48,
    goodsCode: GoodsTypeCode.long.code,
    goodsName: GoodsTypeCode.long.name,
    expirationTypeCode: ExpirationTypeCode.choice.code,
    expirationTypeName: ExpirationTypeCode.choice.name,
    guarantyRate: 15,
    guarantyAmountPartition: 1
  },
  {
    engagementDistance: 20000,
    term: 60,
    goodsCode: GoodsTypeCode.long.code,
    goodsName: GoodsTypeCode.long.name,
    expirationTypeCode: ExpirationTypeCode.choice.code,
    expirationTypeName: ExpirationTypeCode.choice.name,
    guarantyRate: 15,
    guarantyAmountPartition: 1
  }
];
