/* tslint:disable */
/* eslint-disable */
/**
 * Rent25 API 가이드
 * Rent25 API 가이드 문서입니다.
 *
 * The version of the OpenAPI document: v0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
// @ts-ignore
import { BannerResponse } from '../models';
// @ts-ignore
import { CreateBannerRequest } from '../models';
// @ts-ignore
import { CreateBannerResponse } from '../models';
// @ts-ignore
import { CreateDocumentRequest } from '../models';
// @ts-ignore
import { CreateDocumentResponse } from '../models';
// @ts-ignore
import { DeleteBannerResponse } from '../models';
// @ts-ignore
import { DeleteDocumentResponse } from '../models';
// @ts-ignore
import { DocumentResponse } from '../models';
// @ts-ignore
import { ErrorResponse } from '../models';
// @ts-ignore
import { SearchBannerRequest } from '../models';
// @ts-ignore
import { SearchBannerResponse } from '../models';
// @ts-ignore
import { SearchDocumentRequest } from '../models';
// @ts-ignore
import { SearchDocumentResponse } from '../models';
// @ts-ignore
import { UpdateBannerRequest } from '../models';
// @ts-ignore
import { UpdateBannerResponse } from '../models';
// @ts-ignore
import { UpdateDocumentRequest } from '../models';
// @ts-ignore
import { UpdateDocumentResponse } from '../models';
/**
 * OperationServiceApi - axios parameter creator
 * @export
 */
export const OperationServiceApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Banner 생성
         * @summary Banner 생성
         * @param {CreateBannerRequest} createBannerRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createBanner: async (createBannerRequest: CreateBannerRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createBannerRequest' is not null or undefined
            assertParamExists('createBanner', 'createBannerRequest', createBannerRequest)
            const localVarPath = `/apis/v1/operation/banner`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createBannerRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Document 생성
         * @summary Document 생성
         * @param {CreateDocumentRequest} createDocumentRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDocument: async (createDocumentRequest: CreateDocumentRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createDocumentRequest' is not null or undefined
            assertParamExists('createDocument', 'createDocumentRequest', createDocumentRequest)
            const localVarPath = `/apis/v1/operation/document`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createDocumentRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Banner 삭제
         * @summary Banner 삭제
         * @param {number} bannerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteBanner: async (bannerId: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'bannerId' is not null or undefined
            assertParamExists('deleteBanner', 'bannerId', bannerId)
            const localVarPath = `/apis/v1/operation/banner/{bannerId}`
                .replace(`{${"bannerId"}}`, encodeURIComponent(String(bannerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Document 삭제
         * @summary Document 삭제
         * @param {number} documentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteDocument: async (documentId: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'documentId' is not null or undefined
            assertParamExists('deleteDocument', 'documentId', documentId)
            const localVarPath = `/apis/v1/operation/document/{documentId}`
                .replace(`{${"documentId"}}`, encodeURIComponent(String(documentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Banner 조회
         * @summary Banner 조회
         * @param {number} bannerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBanner: async (bannerId: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'bannerId' is not null or undefined
            assertParamExists('getBanner', 'bannerId', bannerId)
            const localVarPath = `/apis/v1/operation/banner/{bannerId}`
                .replace(`{${"bannerId"}}`, encodeURIComponent(String(bannerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Document 조회
         * @summary Document 조회
         * @param {number} documentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDocument: async (documentId: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'documentId' is not null or undefined
            assertParamExists('getDocument', 'documentId', documentId)
            const localVarPath = `/apis/v1/operation/document/{documentId}`
                .replace(`{${"documentId"}}`, encodeURIComponent(String(documentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Document 조회
         * @summary Document 조회
         * @param {string} documentCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDocumentByCode: async (documentCode: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'documentCode' is not null or undefined
            assertParamExists('getDocumentByCode', 'documentCode', documentCode)
            const localVarPath = `/apis/v1/operation/public/document/codes/{documentCode}`
                .replace(`{${"documentCode"}}`, encodeURIComponent(String(documentCode)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Banner 검색(퍼블릭)
         * @summary Banner 검색(퍼블릭)
         * @param {SearchBannerRequest} searchBannerRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        publicBanners: async (searchBannerRequest: SearchBannerRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'searchBannerRequest' is not null or undefined
            assertParamExists('publicBanners', 'searchBannerRequest', searchBannerRequest)
            const localVarPath = `/apis/v1/operation/public/banners`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(searchBannerRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Banner 검색
         * @summary Banner 검색
         * @param {SearchBannerRequest} searchBannerRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchBanner: async (searchBannerRequest: SearchBannerRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'searchBannerRequest' is not null or undefined
            assertParamExists('searchBanner', 'searchBannerRequest', searchBannerRequest)
            const localVarPath = `/apis/v1/operation/banner/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(searchBannerRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Document 검색
         * @summary Document 검색
         * @param {SearchDocumentRequest} searchDocumentRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchDocument: async (searchDocumentRequest: SearchDocumentRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'searchDocumentRequest' is not null or undefined
            assertParamExists('searchDocument', 'searchDocumentRequest', searchDocumentRequest)
            const localVarPath = `/apis/v1/operation/document/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(searchDocumentRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Banner 수정
         * @summary Banner 수정
         * @param {UpdateBannerRequest} updateBannerRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateBanner: async (updateBannerRequest: UpdateBannerRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'updateBannerRequest' is not null or undefined
            assertParamExists('updateBanner', 'updateBannerRequest', updateBannerRequest)
            const localVarPath = `/apis/v1/operation/banner`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateBannerRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Document 수정
         * @summary Document 수정
         * @param {UpdateDocumentRequest} updateDocumentRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDocument: async (updateDocumentRequest: UpdateDocumentRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'updateDocumentRequest' is not null or undefined
            assertParamExists('updateDocument', 'updateDocumentRequest', updateDocumentRequest)
            const localVarPath = `/apis/v1/operation/document`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateDocumentRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OperationServiceApi - functional programming interface
 * @export
 */
export const OperationServiceApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = OperationServiceApiAxiosParamCreator(configuration)
    return {
        /**
         * Banner 생성
         * @summary Banner 생성
         * @param {CreateBannerRequest} createBannerRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createBanner(createBannerRequest: CreateBannerRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateBannerResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createBanner(createBannerRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OperationServiceApi.createBanner']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Document 생성
         * @summary Document 생성
         * @param {CreateDocumentRequest} createDocumentRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createDocument(createDocumentRequest: CreateDocumentRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateDocumentResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createDocument(createDocumentRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OperationServiceApi.createDocument']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Banner 삭제
         * @summary Banner 삭제
         * @param {number} bannerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteBanner(bannerId: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeleteBannerResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteBanner(bannerId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OperationServiceApi.deleteBanner']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Document 삭제
         * @summary Document 삭제
         * @param {number} documentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteDocument(documentId: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeleteDocumentResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteDocument(documentId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OperationServiceApi.deleteDocument']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Banner 조회
         * @summary Banner 조회
         * @param {number} bannerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBanner(bannerId: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BannerResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getBanner(bannerId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OperationServiceApi.getBanner']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Document 조회
         * @summary Document 조회
         * @param {number} documentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDocument(documentId: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DocumentResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDocument(documentId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OperationServiceApi.getDocument']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Document 조회
         * @summary Document 조회
         * @param {string} documentCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDocumentByCode(documentCode: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DocumentResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDocumentByCode(documentCode, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OperationServiceApi.getDocumentByCode']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Banner 검색(퍼블릭)
         * @summary Banner 검색(퍼블릭)
         * @param {SearchBannerRequest} searchBannerRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async publicBanners(searchBannerRequest: SearchBannerRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SearchBannerResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.publicBanners(searchBannerRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OperationServiceApi.publicBanners']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Banner 검색
         * @summary Banner 검색
         * @param {SearchBannerRequest} searchBannerRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async searchBanner(searchBannerRequest: SearchBannerRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SearchBannerResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.searchBanner(searchBannerRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OperationServiceApi.searchBanner']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Document 검색
         * @summary Document 검색
         * @param {SearchDocumentRequest} searchDocumentRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async searchDocument(searchDocumentRequest: SearchDocumentRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SearchDocumentResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.searchDocument(searchDocumentRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OperationServiceApi.searchDocument']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Banner 수정
         * @summary Banner 수정
         * @param {UpdateBannerRequest} updateBannerRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateBanner(updateBannerRequest: UpdateBannerRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UpdateBannerResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateBanner(updateBannerRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OperationServiceApi.updateBanner']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Document 수정
         * @summary Document 수정
         * @param {UpdateDocumentRequest} updateDocumentRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateDocument(updateDocumentRequest: UpdateDocumentRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UpdateDocumentResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateDocument(updateDocumentRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OperationServiceApi.updateDocument']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * OperationServiceApi - factory interface
 * @export
 */
export const OperationServiceApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = OperationServiceApiFp(configuration)
    return {
        /**
         * Banner 생성
         * @summary Banner 생성
         * @param {CreateBannerRequest} createBannerRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createBanner(createBannerRequest: CreateBannerRequest, options?: any): AxiosPromise<CreateBannerResponse> {
            return localVarFp.createBanner(createBannerRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Document 생성
         * @summary Document 생성
         * @param {CreateDocumentRequest} createDocumentRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDocument(createDocumentRequest: CreateDocumentRequest, options?: any): AxiosPromise<CreateDocumentResponse> {
            return localVarFp.createDocument(createDocumentRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Banner 삭제
         * @summary Banner 삭제
         * @param {number} bannerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteBanner(bannerId: number, options?: any): AxiosPromise<DeleteBannerResponse> {
            return localVarFp.deleteBanner(bannerId, options).then((request) => request(axios, basePath));
        },
        /**
         * Document 삭제
         * @summary Document 삭제
         * @param {number} documentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteDocument(documentId: number, options?: any): AxiosPromise<DeleteDocumentResponse> {
            return localVarFp.deleteDocument(documentId, options).then((request) => request(axios, basePath));
        },
        /**
         * Banner 조회
         * @summary Banner 조회
         * @param {number} bannerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBanner(bannerId: number, options?: any): AxiosPromise<BannerResponse> {
            return localVarFp.getBanner(bannerId, options).then((request) => request(axios, basePath));
        },
        /**
         * Document 조회
         * @summary Document 조회
         * @param {number} documentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDocument(documentId: number, options?: any): AxiosPromise<DocumentResponse> {
            return localVarFp.getDocument(documentId, options).then((request) => request(axios, basePath));
        },
        /**
         * Document 조회
         * @summary Document 조회
         * @param {string} documentCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDocumentByCode(documentCode: string, options?: any): AxiosPromise<DocumentResponse> {
            return localVarFp.getDocumentByCode(documentCode, options).then((request) => request(axios, basePath));
        },
        /**
         * Banner 검색(퍼블릭)
         * @summary Banner 검색(퍼블릭)
         * @param {SearchBannerRequest} searchBannerRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        publicBanners(searchBannerRequest: SearchBannerRequest, options?: any): AxiosPromise<SearchBannerResponse> {
            return localVarFp.publicBanners(searchBannerRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Banner 검색
         * @summary Banner 검색
         * @param {SearchBannerRequest} searchBannerRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchBanner(searchBannerRequest: SearchBannerRequest, options?: any): AxiosPromise<SearchBannerResponse> {
            return localVarFp.searchBanner(searchBannerRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Document 검색
         * @summary Document 검색
         * @param {SearchDocumentRequest} searchDocumentRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchDocument(searchDocumentRequest: SearchDocumentRequest, options?: any): AxiosPromise<SearchDocumentResponse> {
            return localVarFp.searchDocument(searchDocumentRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Banner 수정
         * @summary Banner 수정
         * @param {UpdateBannerRequest} updateBannerRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateBanner(updateBannerRequest: UpdateBannerRequest, options?: any): AxiosPromise<UpdateBannerResponse> {
            return localVarFp.updateBanner(updateBannerRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Document 수정
         * @summary Document 수정
         * @param {UpdateDocumentRequest} updateDocumentRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDocument(updateDocumentRequest: UpdateDocumentRequest, options?: any): AxiosPromise<UpdateDocumentResponse> {
            return localVarFp.updateDocument(updateDocumentRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * OperationServiceApi - object-oriented interface
 * @export
 * @class OperationServiceApi
 * @extends {BaseAPI}
 */
export class OperationServiceApi extends BaseAPI {
    /**
     * Banner 생성
     * @summary Banner 생성
     * @param {CreateBannerRequest} createBannerRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OperationServiceApi
     */
    public createBanner(createBannerRequest: CreateBannerRequest, options?: RawAxiosRequestConfig) {
        return OperationServiceApiFp(this.configuration).createBanner(createBannerRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Document 생성
     * @summary Document 생성
     * @param {CreateDocumentRequest} createDocumentRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OperationServiceApi
     */
    public createDocument(createDocumentRequest: CreateDocumentRequest, options?: RawAxiosRequestConfig) {
        return OperationServiceApiFp(this.configuration).createDocument(createDocumentRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Banner 삭제
     * @summary Banner 삭제
     * @param {number} bannerId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OperationServiceApi
     */
    public deleteBanner(bannerId: number, options?: RawAxiosRequestConfig) {
        return OperationServiceApiFp(this.configuration).deleteBanner(bannerId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Document 삭제
     * @summary Document 삭제
     * @param {number} documentId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OperationServiceApi
     */
    public deleteDocument(documentId: number, options?: RawAxiosRequestConfig) {
        return OperationServiceApiFp(this.configuration).deleteDocument(documentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Banner 조회
     * @summary Banner 조회
     * @param {number} bannerId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OperationServiceApi
     */
    public getBanner(bannerId: number, options?: RawAxiosRequestConfig) {
        return OperationServiceApiFp(this.configuration).getBanner(bannerId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Document 조회
     * @summary Document 조회
     * @param {number} documentId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OperationServiceApi
     */
    public getDocument(documentId: number, options?: RawAxiosRequestConfig) {
        return OperationServiceApiFp(this.configuration).getDocument(documentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Document 조회
     * @summary Document 조회
     * @param {string} documentCode 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OperationServiceApi
     */
    public getDocumentByCode(documentCode: string, options?: RawAxiosRequestConfig) {
        return OperationServiceApiFp(this.configuration).getDocumentByCode(documentCode, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Banner 검색(퍼블릭)
     * @summary Banner 검색(퍼블릭)
     * @param {SearchBannerRequest} searchBannerRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OperationServiceApi
     */
    public publicBanners(searchBannerRequest: SearchBannerRequest, options?: RawAxiosRequestConfig) {
        return OperationServiceApiFp(this.configuration).publicBanners(searchBannerRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Banner 검색
     * @summary Banner 검색
     * @param {SearchBannerRequest} searchBannerRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OperationServiceApi
     */
    public searchBanner(searchBannerRequest: SearchBannerRequest, options?: RawAxiosRequestConfig) {
        return OperationServiceApiFp(this.configuration).searchBanner(searchBannerRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Document 검색
     * @summary Document 검색
     * @param {SearchDocumentRequest} searchDocumentRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OperationServiceApi
     */
    public searchDocument(searchDocumentRequest: SearchDocumentRequest, options?: RawAxiosRequestConfig) {
        return OperationServiceApiFp(this.configuration).searchDocument(searchDocumentRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Banner 수정
     * @summary Banner 수정
     * @param {UpdateBannerRequest} updateBannerRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OperationServiceApi
     */
    public updateBanner(updateBannerRequest: UpdateBannerRequest, options?: RawAxiosRequestConfig) {
        return OperationServiceApiFp(this.configuration).updateBanner(updateBannerRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Document 수정
     * @summary Document 수정
     * @param {UpdateDocumentRequest} updateDocumentRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OperationServiceApi
     */
    public updateDocument(updateDocumentRequest: UpdateDocumentRequest, options?: RawAxiosRequestConfig) {
        return OperationServiceApiFp(this.configuration).updateDocument(updateDocumentRequest, options).then((request) => request(this.axios, this.basePath));
    }
}

