import { useRoutes } from 'react-router-dom';

// project-imports
import LoginRoutes from './LoginRoutes';
// import MainRoutes from './MainRoutes';
import AuthRent25Routes from './AuthRent25Routes';
import MaintenanceRoutes from './MaintenanceRoutes';
import useAuth from 'hooks/useAuth';
import { CompanyTypeCode } from 'constant/Constant';
import AuthSystemRoutes from './AuthSystemRoutes';
import AuthRentCarRoutes from './AuthRentCarRoutes';
import AuthAgentRoutes from './AuthAgentRoutes';
import AuthAgentUnionRoutes from './AuthAgentUnionRoutes';
import PublicRoutes from './PublicRoutes';

// ==============================|| ROUTES RENDER ||============================== //

export default function ThemeRoutes() {
  const user = useAuth();

  const routes = [];
  // Public Routes
  routes.push(PublicRoutes);
  // 로그인 Routes
  routes.push(LoginRoutes);
  // 시스템 장애 Routes
  routes.push(MaintenanceRoutes);
  // 회원 타입별 Routes
  if (user.user?.company?.companyTypeCode === CompanyTypeCode.rent25.code) {
    routes.push(AuthRent25Routes);
    routes.push(AuthSystemRoutes);
  } else if (user.user?.company?.companyTypeCode === CompanyTypeCode.rentcar.code) {
    routes.push(AuthRentCarRoutes);
  } else if (user.user?.company?.companyTypeCode === CompanyTypeCode.agent.code) {
    routes.push(AuthAgentRoutes);
  } else if (user.user?.company?.companyTypeCode === CompanyTypeCode.agentUnion.code) {
    routes.push(AuthAgentUnionRoutes);
  }

  return useRoutes(routes);
}
