import { useMemo, useState, useCallback } from 'react';

// material-ui
import { Typography } from '@mui/material';

// third-party
import { Column } from 'react-table';

// project-imports
import MainCard from 'components/MainCard';
import ScrollX from 'components/ScrollX';

// types
import { bbsServiceApi } from 'utils/axios';
import { Bbs, SearchBbsRequest } from 'openapi/models';
import { BbsType, CodeGroupName, CompanyTypeCode, toValue } from 'constant/Constant';
import { toDateString } from 'utils/string-utils';
import _ from 'lodash';
import BbsTable from './BbsTable';
import { useCodes } from 'contexts/CodeContext';
import useAuth from 'hooks/useAuth';

type BbsProps = {
  bbsType?: string;
};

const BbsList = ({ bbsType = 'notice' }: BbsProps) => {
  const code = useCodes();
  const { user } = useAuth();

  const columns: Column[] = useMemo(() => {
    let columns = [
      {
        width: 80,
        Header: 'ID',
        accessor: 'bbsId'
      },
      {
        Header: '업체',
        accessor: 'targetCompanyTypeCode',
        Cell: ({ value }: any) => {
          return value === 'ALL' ? <Typography>전체</Typography> : <Typography>{toValue(CompanyTypeCode, value).name}</Typography>;
        }
      },
      {
        Header: '자료구분',
        accessor: 'categoryCode',
        Cell: ({ value }: any) => {
          return <Typography>{code.getCode(CodeGroupName.PdsTypeCode, value)?.codeName}</Typography>;
        }
      },
      {
        Header: '제목',
        accessor: 'title'
      },
      {
        width: 200,
        Header: '등록 일시',
        accessor: 'createDatetime',
        className: 'cell-center',
        Cell: ({ value }: any) => {
          return <Typography>{toDateString(value)}</Typography>;
        }
      }
    ];

    // BBS Type
    if (bbsType === BbsType.notice.code) {
      columns = _.remove(columns, (column) => {
        return !_.includes(['categoryCode'], column.accessor);
      });
    }

    if (user?.company?.companyTypeCode !== CompanyTypeCode.rent25.code) {
      columns = _.remove(columns, (column) => {
        return !_.includes(['targetCompanyTypeCode'], column.accessor);
      });
    }

    return columns;
  }, [bbsType, code, user]);

  const [loading, setLoading] = useState(false);
  const [result, setResult] = useState<{ data: Bbs[]; totalCount: number; pageCount: number }>({
    data: [],
    totalCount: 0,
    pageCount: 0
  });

  const fetchData = useCallback((searchBbsRequest: SearchBbsRequest) => {
    setLoading(true);

    bbsServiceApi
      .searchBbs({ ...searchBbsRequest, bbsTypeCode: bbsType })
      .then((response) => {
        setResult({
          data: response.data?.bbsList!,
          totalCount: response.data?.page!.totalCount!,
          pageCount: Math.ceil(response.data?.page!.totalCount! / searchBbsRequest.page?.pageSize!)
        });
      })
      .catch((error) => {
        console.error('게시물 조회 실패', error);
      })
      .finally(() => {
        setLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <MainCard content={false}>
      <ScrollX>
        <BbsTable
          bbsType={bbsType}
          columns={columns}
          companies={result.data}
          totalCount={result.totalCount}
          fetchData={fetchData}
          loading={loading}
          pageCount={result.pageCount}
        />{' '}
      </ScrollX>
    </MainCard>
  );
};

export default BbsList;
