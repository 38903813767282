import { useEffect, useState } from 'react';

// assets
import { Dialog, Divider, DialogContent, DialogProps, IconButton, DialogTitle } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import UserInformationSection from 'sections/rent25/user/UserInformationSection';

type Props = DialogProps & {
  open: boolean;
  userId?: number;
  onClose: () => void;
};

const UserDialog = ({ open, onClose, userId }: Props) => {
  const [isOpen, setOpen] = useState(open);

  useEffect(() => {
    setOpen(open);
  }, [open]);

  const handleClose = () => {
    onClose();
  };

  return (
    <>
      <Dialog fullWidth maxWidth="xl" open={isOpen} onClick={(event) => event.stopPropagation()} onClose={onClose}>
        <DialogTitle>사용자 정보</DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500]
          }}
        >
          <CloseIcon />
        </IconButton>
        <Divider />
        <DialogContent sx={{ p: 2 }}>
          <UserInformationSection edit={false} userId={userId}></UserInformationSection>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default UserDialog;
