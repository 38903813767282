import Avatar from '@mui/material/Avatar';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import PersonIcon from '@mui/icons-material/Person';
import { blue } from '@mui/material/colors';
import { Box, Button, DialogActions, DialogContent, Divider, Grid, IconButton, InputAdornment, TextField, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { companyServiceApi } from 'utils/axios';
import { Code, Company } from 'openapi';
import { Add, SearchNormal1 } from 'iconsax-react';
import { getImageUrl } from 'utils/file-utils';
import { useCodes } from 'contexts/CodeContext';
import { CodeGroupName } from 'constant/Constant';
import { toCodeName } from 'utils/code-utils';
import { Stack } from '@mui/system';
import { HighlightOff } from '@mui/icons-material';

export interface Props {
  open: boolean;
  companyTypeCode?: string;
  title?: string;
  onClose: (value?: Company) => void;
}

function SelectCompanyDialog({ title = '회사 선택', open, companyTypeCode, onClose }: Props) {
  const [companies, setCompanies] = useState<Company[]>([]);
  const [companyTypeCodes, setCompanyTypeCodes] = useState<Code[]>([]);
  const [searchText, setSearchText] = useState('');
  const [searchCount, setSearchCount] = useState(0);
  const code = useCodes();

  useEffect(() => {
    const codes = code.getCodes(CodeGroupName.CompanyTypeCode);
    setCompanyTypeCodes(codes);
  }, [code]);

  const handleClose = () => {
    setSearchText('');
    setSearchCount((count) => count + 1);
    onClose();
  };

  const handleListItemClick = (value: Company) => {
    onClose(value);
  };

  useEffect(() => {
    companyServiceApi
      .searchSimpleCompanies({
        searchText: searchText,
        companyTypeCode: companyTypeCode
      })
      .then((response) => {
        setCompanies(response.data?.companies ?? []);
      })
      .catch((error) => {
        console.error(error);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyTypeCode, searchCount]);

  return (
    <Dialog onClose={handleClose} open={open} fullWidth maxWidth={'xs'}>
      <Grid
        container
        spacing={2}
        justifyContent="space-between"
        alignItems="center"
        sx={{ borderBottom: '1px solid {theme.palette.divider}' }}
      >
        <Grid item>
          <DialogTitle>{title}</DialogTitle>
        </Grid>
        <Grid item sx={{ mr: 1.5 }}>
          <IconButton color="secondary" onClick={handleClose}>
            <Add style={{ transform: 'rotate(45deg)' }} />
          </IconButton>
        </Grid>
      </Grid>
      <Divider />
      <Box sx={{ p: 1, py: 1.5 }}>
        <DialogContent sx={{ p: 0, pl: 1, pr: 1, m: 0 }}>
          <Stack direction={'row'} spacing={1} sx={{ mt: 0, mb: 2 }}>
            <TextField
              size="small"
              id="with-label-input"
              sx={{ width: '100%' }}
              placeholder={'업체명, 대표자명'}
              value={searchText}
              onChange={(e) => {
                setSearchText(e.target.value);
              }}
              onKeyDown={(event) => {
                if (event.key === 'Enter') {
                  setSearchCount((count) => count + 1);
                }
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {searchText && (
                      <IconButton
                        onClick={() => {
                          setSearchText('');
                          setSearchCount((count) => count + 1);
                        }}
                        edge="end"
                        size="small"
                      >
                        <HighlightOff />
                      </IconButton>
                    )}
                  </InputAdornment>
                )
              }}
            ></TextField>
            <Button
              size="small"
              variant="contained"
              startIcon={<SearchNormal1 />}
              sx={{ height: '37px', width: '100px' }}
              onClick={() => {
                setSearchCount((count) => count + 1);
              }}
            >
              조회
            </Button>
          </Stack>
          <List sx={{ pt: 0, maxHeight: '200px', overflow: 'auto' }}>
            {companies.map((company, index) => (
              <ListItem key={index} disableGutters disablePadding divider>
                <ListItemButton onClick={() => handleListItemClick(company)}>
                  <ListItemAvatar>
                    {company.companyLogoImage ? (
                      <Avatar alt="회사 로고" src={getImageUrl(company.companyLogoImage?.fileUuid)} sx={{ width: 40, height: 40 }} />
                    ) : (
                      <Avatar sx={{ bgcolor: blue[100], color: blue[600] }}>
                        <PersonIcon />
                      </Avatar>
                    )}
                  </ListItemAvatar>
                  <ListItemText
                    primary={<Typography variant="subtitle2">{String(toCodeName(companyTypeCodes, company.companyTypeCode!))}</Typography>}
                    secondary={
                      <Typography variant="subtitle1">
                        {company.companyName} / {company.representativeName} 대표
                      </Typography>
                    }
                  />
                </ListItemButton>
              </ListItem>
            ))}
          </List>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={handleClose}>
            취소
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  );
}

export default SelectCompanyDialog;
