import { lazy } from 'react';
import Loadable from 'components/Loadable';

// project-imports
import MainLayout from 'layout/MainLayout';
import AuthGuard from 'utils/route-guard/AuthGuard';
import NoticeListPage from 'pages/operation/notice-list';
import NoticeViewPage from 'pages/operation/notice-view';
import PdsListPage from 'pages/operation/pds-list';
import PdsViewPage from 'pages/operation/pds-view';

const MyCompanyPage = Loadable(lazy(() => import('pages/rent25/company/company')));
const CompanyEmployeeListPage = Loadable(lazy(() => import('pages/rent25/company/company-employee-list')));
const EstimateSetupPage = Loadable(lazy(() => import('pages/rent25/estimate/estimate-management')));
const VehiclePriceGroupSettingSection = Loadable(lazy(() => import('sections/rent25/estimate/setup/VehiclePriceGroupSettingSection')));
const DepositSettingSection = Loadable(lazy(() => import('sections/rent25/estimate/setup/DepositSettingSection')));
const CarResidualGroupSelectSection = Loadable(lazy(() => import('sections/rent25/estimate/setup/CarResidualGroupSelectSection')));
const AdditionalRentalFeeSettingSection = Loadable(lazy(() => import('sections/rent25/estimate/setup/AdditionalRentalFeeSettingSection')));
const DeliveryAreaFeeSettingSection = Loadable(lazy(() => import('sections/rent25/estimate/setup/DeliveryAreaFeeSettingSection')));
const SupplySettingSection = Loadable(lazy(() => import('sections/rent25/estimate/setup/SupplySettingSection')));
const EtcSettingSection = Loadable(lazy(() => import('sections/rent25/estimate/setup/EtcSettingSection')));
const CompanyView = Loadable(lazy(() => import('sections/rent25/company/CompanyView')));
const EstimateSimulationPage = Loadable(lazy(() => import('pages/rent25/estimate/estimate-simulation')));
const NewCarRegistrationPage = Loadable(lazy(() => import('pages/rent25/car/new-car-registration')));
const NewCarListPage = Loadable(lazy(() => import('pages/rent25/car/new-car-list')));
const NewCarUpdatePage = Loadable(lazy(() => import('pages/rent25/car/new-car-update')));
const EstimateViewPage = Loadable(lazy(() => import('pages/rent25/estimate/estimate-view')));
const EstimateListPage = Loadable(lazy(() => import('pages/rent25/estimate/estimate-list')));
const CompanyPage = Loadable(lazy(() => import('pages/rent25/company/company-management')));
const CompanyListPage = Loadable(lazy(() => import('pages/rent25/company/company-list')));
const AgentUnionDashboard = Loadable(lazy(() => import('pages/agent-union/AgentUnionDashboard')));

const AuthAgentUnionRoutes = {
  path: '/',
  children: [
    {
      path: '/',
      element: (
        <AuthGuard>
          <MainLayout />
        </AuthGuard>
      ),
      children: [
        {
          name: 'Dashboard',
          path: '/dashboard',
          element: <AgentUnionDashboard />
        },
        {
          name: 'AGENT 연합',
          path: '/agent-union',
          children: [
            {
              name: '나의회사',
              path: 'my-company',
              element: <MyCompanyPage />,
              children: [
                {
                  name: '회사 정보',
                  path: 'information',
                  element: <CompanyView />
                },
                {
                  name: '회사 직원 목록',
                  path: 'employee',
                  element: <CompanyEmployeeListPage />
                }
                // {
                //   path: 'estimate',
                //   element: <CompanyEstimateSettingSection />
                // }
              ]
            },
            {
              name: '회사',
              path: 'company',
              element: <CompanyPage />,
              children: [
                {
                  path: 'list',
                  element: <CompanyListPage />
                }
              ]
            },
            {
              name: '차량',
              path: 'car',
              children: [
                {
                  name: '신차(보유차량)',
                  path: 'new',
                  children: [
                    {
                      path: 'list',
                      element: <NewCarListPage />
                    },
                    {
                      path: 'registration',
                      element: <NewCarRegistrationPage />
                    },
                    {
                      path: 'update',
                      element: <NewCarUpdatePage />
                    },
                    {
                      path: 'view'
                      //element: <NewCarUpdatePage />
                    }
                  ]
                }
              ]
            },
            {
              name: '견적',
              path: 'estimate',
              children: [
                {
                  path: 'list',
                  element: <EstimateListPage />
                },
                {
                  path: 'simulation',
                  element: <EstimateSimulationPage />
                },
                {
                  path: 'view',
                  element: <EstimateViewPage />
                },
                {
                  path: 'setup',
                  element: <EstimateSetupPage />,
                  children: [
                    {
                      path: 'vehicle-price-group',
                      element: <VehiclePriceGroupSettingSection />
                    },
                    {
                      path: 'deposit',
                      element: <DepositSettingSection />
                    },
                    {
                      path: 'residual-group',
                      element: <CarResidualGroupSelectSection />
                    },
                    {
                      path: 'add-rental-fee',
                      element: <AdditionalRentalFeeSettingSection />
                    },
                    {
                      path: 'rental-area',
                      element: <DeliveryAreaFeeSettingSection />
                    },
                    {
                      path: 'supply',
                      element: <SupplySettingSection />
                    },
                    {
                      path: 'etc',
                      element: <EtcSettingSection />
                    }
                  ]
                }
              ]
            },
            {
              name: '운영관리',
              path: 'operation/management',
              children: [
                {
                  path: 'notice-list',
                  element: <NoticeListPage />
                },
                {
                  path: 'notice-view',
                  element: <NoticeViewPage />
                },
                {
                  path: 'pds-list',
                  element: <PdsListPage />
                },
                {
                  path: 'pds-view',
                  element: <PdsViewPage />
                }
              ]
            }
          ]
        }
      ]
    }
  ]
};

export default AuthAgentUnionRoutes;
