import React from 'react';
//import TextField, { TextFieldProps } from '@mui/material/TextField';
import { Typography } from '@mui/material';
import { NumericFormat } from 'react-number-format';

// type EditableNumericFormatProps = Omit<NumericFormatProps<InputAttributes>, 'customInput' | 'size'> &
//   TextFieldProps & {
//     edit: boolean;
//     toName?: () => string;
//   };

// type EditableNumericFormatProps = NumericFormatProps<InputAttributes> & {
//   size?: string;
//   edit: boolean;
//   toName?: () => string;
// };

// interface EditableNumericFormatProps extends Omit<NumericFormatProps<InputAttributes>, 'customInput'>, TextFieldProps {
//   edit: boolean;
//   toName?: () => string;
// }

// type EditableNumericFormatProps = TextFieldProps &
//   NumericFormatProps<InputAttributes> & {
//     edit: boolean;
//     toName?: () => string;
//   };

const EditableNumericFormat = ({ edit = true, toName, ...props }: any) => {
  // const { inputRef, onValueChange, ...rest } = props;

  return (
    <>
      {edit ? (
        <NumericFormat {...props} />
      ) : (
        <Typography
          variant="body1"
          sx={{
            fontWeight: 'bold',
            display: 'inline-block',
            border: '1px solid transparent',
            borderRadius: '4px',
            padding: '14px 13px',
            lineHeight: 'normal',
            width: '100%'
            //maxWidth: props.fullWidth ? '100%' : `calc(100% - ${props.margin ? props.margin * 2 : 0}px)`
          }}
        >
          {toName ? toName() : props.value && props.value !== '' ? props.value.toString() : '0'} {props.InputProps?.endAdornment ?? ''}
        </Typography>
      )}
    </>
  );
};

export default EditableNumericFormat;
