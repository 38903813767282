// assets
import { LocationDiscover, Bank, LikeTag, Level, Layer, Magicpen, Mask, HeartSearch, Harmony, Car, KeyboardOpen } from 'iconsax-react';

// type
import { NavItemType } from 'types/menu';

// icons
const icons = {
  Bank: Bank,
  LikeTag: LikeTag,
  Level: Level,
  Layer: Layer,
  Magicpen: Magicpen,
  Mask: Mask,
  HeartSearch: HeartSearch,
  Harmony: Harmony,
  LocationDiscover: LocationDiscover,
  Car: Car,
  KeyboardOpen: KeyboardOpen
};

// ==============================|| MENU ITEMS - SUPPORT ||============================== //
const authorizationRentcarMenus: NavItemType[] = [
  {
    id: '4001',
    title: 'RENTCAR',
    type: 'group',
    children: [
      {
        id: '20010',
        title: 'Dashboard',
        type: 'item',
        url: '/dashboard',
        icon: icons['KeyboardOpen']
      },
      {
        id: '40011',
        title: '회사 관리',
        type: 'collapse',
        icon: icons['Layer'],
        children: [
          {
            id: '400111',
            title: '회사정보',
            type: 'item',
            url: '/rentcar/my-company/information',
            icon: icons['LocationDiscover']
          },
          {
            id: '400112',
            title: '직원정보',
            type: 'item',
            url: '/rentcar/my-company/employee',
            icon: icons.Bank
          }
        ]
      },
      {
        id: '40012',
        title: '차량 관리',
        type: 'collapse',
        icon: icons.Car,
        children: [
          {
            id: '400121',
            title: '보유 차량 등록',
            type: 'item',
            url: '/rentcar/car/new/registration',
            icon: icons.Car
          },
          {
            id: '400122',
            title: '보유 차량 목록',
            type: 'item',
            url: '/rentcar/car/new/list',
            icon: icons.Car
          },
          {
            id: '400123',
            title: '보유 차량 수정',
            type: 'hidden',
            url: '/rentcar/car/new/update',
            icon: icons.Car
          },
          {
            id: '400124',
            title: '보유 차량 정보 보기',
            type: 'hidden',
            url: '/rentcar/car/new/view',
            icon: icons.Car
          }
        ]
      },
      {
        id: '40013',
        title: '견적 관리',
        type: 'collapse',
        icon: icons.LocationDiscover,
        children: [
          {
            id: '400131',
            title: '견적 목록',
            type: 'item',
            url: '/rentcar/estimate/list',
            icon: icons.Bank
          },
          {
            id: '400132',
            title: '견적서 등록',
            type: 'hidden',
            url: '/rentcar/estimate/registration',
            icon: icons.Bank
          },
          {
            id: '400133',
            title: '견적서 수정',
            type: 'hidden',
            url: '/rentcar/estimate/update',
            icon: icons.LikeTag
          },
          {
            id: '400134',
            title: '견적서 정보',
            type: 'hidden',
            url: '/rentcar/estimate/view',
            icon: icons.LikeTag
          },
          {
            id: '400135',
            title: '견적 내기',
            type: 'item',
            url: '/rentcar/estimate/simulation',
            icon: icons.Bank
          }
        ]
      },
      {
        id: '40014',
        title: '견적 설정 관리',
        type: 'collapse',
        icon: icons.LocationDiscover,
        children: [
          {
            id: '400141',
            title: '차량 가격별 그룹설정',
            type: 'item',
            url: '/rentcar/estimate/setup/vehicle-price-group',
            icon: icons.Bank
          },
          {
            id: '400142',
            title: '보증금 설정',
            type: 'item',
            url: '/rentcar/estimate/setup/deposit',
            icon: icons.Bank
          },
          {
            id: '400143',
            title: '그룹별 잔가율 설정',
            type: 'item',
            url: '/rentcar/estimate/setup/residual-value',
            icon: icons.Bank
          },
          {
            id: '400144',
            title: '약칭 및 잔가 그룹 설정',
            type: 'item',
            url: '/rentcar/estimate/setup/residual-group',
            icon: icons.Bank
          },
          {
            id: '400145',
            title: '가산렌탈료 설정',
            type: 'item',
            url: '/rentcar/estimate/setup/add-rental-fee',
            icon: icons.LikeTag
          },
          {
            id: '400146',
            title: '렌트지역/탁송료 설정',
            type: 'item',
            url: '/rentcar/estimate/setup/rental-area',
            icon: icons.LikeTag
          },
          {
            id: '400147',
            title: '용품 설정',
            type: 'item',
            url: '/rentcar/estimate/setup/supply',
            icon: icons.LikeTag
          },
          {
            id: '400148',
            title: '기타 설정',
            type: 'item',
            url: '/rentcar/estimate/setup/etc',
            icon: icons.LikeTag
          }
        ]
      },
      {
        id: '40015',
        title: '정산 관리',
        type: 'collapse',
        url: '#',
        icon: icons.Harmony,
        children: []
      },
      {
        id: '40016',
        title: '통계',
        type: 'collapse',
        url: '#',
        icon: icons.Harmony,
        children: []
      },
      {
        id: '20017',
        title: '게시판',
        type: 'collapse',
        icon: icons.LocationDiscover,
        children: [
          {
            id: '200171',
            title: '공지사항',
            type: 'item',
            url: '/rentcar/operation/management/notice-list',
            icon: icons.Bank
          },
          {
            id: '200172',
            title: '공지사항',
            type: 'hidden',
            url: '/rentcar/operation/management/notice-view',
            icon: icons.LikeTag
          },
          {
            id: '200173',
            title: '자료실',
            type: 'item',
            url: '/rentcar/operation/management/pds-list',
            icon: icons.Bank
          },
          {
            id: '200174',
            title: '자료실',
            type: 'hidden',
            url: '/rentcar/operation/management/pds-view',
            icon: icons.LikeTag
          }
        ]
      }
    ]
  }
];

export default authorizationRentcarMenus;
