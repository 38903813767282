// material-ui
import { Grid, Stack, Typography } from '@mui/material';

// ==============================|| MAIN LAYOUT - FOOTER ||============================== //

const Footer = () => (
  <Stack direction="column" justifyContent="space-between" alignItems="center" sx={{ p: '10px 16px 5px', mt: 'auto' }}>
    <Stack>
      <Grid container direction={'row'} sx={{ mt: 3 }}>
        <Grid item>
          <Typography variant="h6" sx={{ fontSize: 14 }}>
            상호명 : 엘케이 | 대표자: 이영돈 | 이메일: lk200701@naver.com | 연락처: 1600-4894 | 주소: 인천광역시 계양구 계산로 88 |
            사업자등록본호: 204-35-61464
          </Typography>
        </Grid>
      </Grid>
    </Stack>
    {/* <Stack>
      <Typography variant="caption">주소: 인천광역시 계양구 계산로 88|사업자등록본호: 204-35-61464</Typography>
    </Stack> */}
    <Stack sx={{ mt: 2 }}>
      <Typography variant="body2" sx={{ fontSize: 10, fontWeight: 'bold' }}>
        &copy; Copyright RENT 25. All Rights Reserved
      </Typography>
    </Stack>
    <Stack spacing={1.5} direction="row" justifyContent="space-between" alignItems="center">
      {/* <Link component={RouterLink} to="https://ableproadmin.com" target="_blank" variant="caption" color="textPrimary">
        Home
      </Link>
      <Link
        component={RouterLink}
        to="https://phoenixcoded.gitbook.io/able-pro/v/react/"
        target="_blank"
        variant="caption"
        color="textPrimary"
      >
        Documentation
      </Link>
      <Link component={RouterLink} to="https://phoenixcoded.authordesk.app/" target="_blank" variant="caption" color="textPrimary">
        Support
      </Link> */}
    </Stack>
  </Stack>
);

export default Footer;
