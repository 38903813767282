import React from 'react';
//import TextField, { TextFieldProps } from '@mui/material/TextField';
import { TextField, TextFieldProps, Typography } from '@mui/material';

type EditableTextFieldProps = TextFieldProps & {
  edit?: boolean;
  toName?: () => string;
};

const EditableTextField = React.memo(({ edit = true, toName, ...props }: EditableTextFieldProps) => {
  return (
    <>
      {edit ? (
        <TextField {...props} />
      ) : (
        <Typography
          variant="body1"
          sx={{
            fontWeight: 'bold',
            display: 'inline-block',
            border: '1px solid transparent',
            borderRadius: '4px',
            padding: '14px 13px',
            lineHeight: 'normal',
            width: '100%'
            //maxWidth: props.fullWidth ? '100%' : `calc(100% - ${props.margin ? props.margin * 2 : 0}px)`
          }}
        >
          {toName ? toName() : props.value ? props.value.toString() : ' '}
        </Typography>
      )}
    </>
  );
});

export default EditableTextField;
