/* tslint:disable */
/* eslint-disable */
/**
 * Rent25 API 가이드
 * Rent25 API 가이드 문서입니다.
 *
 * The version of the OpenAPI document: v0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
// @ts-ignore
import { ErrorResponse } from '../models';
// @ts-ignore
import { SearchCodeGroupsRequest } from '../models';
// @ts-ignore
import { SearchCodeGroupsResponse } from '../models';
// @ts-ignore
import { VirtualAccountsRequest } from '../models';
/**
 * CodeServiceApi - axios parameter creator
 * @export
 */
export const CodeServiceApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 가상 계좌 발급 요청
         * @summary 가상 계좌 발급 요청
         * @param {VirtualAccountsRequest} virtualAccountsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createVirtualAccounts: async (virtualAccountsRequest: VirtualAccountsRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'virtualAccountsRequest' is not null or undefined
            assertParamExists('createVirtualAccounts', 'virtualAccountsRequest', virtualAccountsRequest)
            const localVarPath = `/apis/v1/toss/virtual-accounts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(virtualAccountsRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 코드 그룹 검색
         * @summary 코드 그룹 검색
         * @param {SearchCodeGroupsRequest} searchCodeGroupsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchCodeGroups: async (searchCodeGroupsRequest: SearchCodeGroupsRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'searchCodeGroupsRequest' is not null or undefined
            assertParamExists('searchCodeGroups', 'searchCodeGroupsRequest', searchCodeGroupsRequest)
            const localVarPath = `/apis/v1/code/code-groups`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(searchCodeGroupsRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CodeServiceApi - functional programming interface
 * @export
 */
export const CodeServiceApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CodeServiceApiAxiosParamCreator(configuration)
    return {
        /**
         * 가상 계좌 발급 요청
         * @summary 가상 계좌 발급 요청
         * @param {VirtualAccountsRequest} virtualAccountsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createVirtualAccounts(virtualAccountsRequest: VirtualAccountsRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SearchCodeGroupsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createVirtualAccounts(virtualAccountsRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CodeServiceApi.createVirtualAccounts']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 코드 그룹 검색
         * @summary 코드 그룹 검색
         * @param {SearchCodeGroupsRequest} searchCodeGroupsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async searchCodeGroups(searchCodeGroupsRequest: SearchCodeGroupsRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SearchCodeGroupsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.searchCodeGroups(searchCodeGroupsRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CodeServiceApi.searchCodeGroups']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * CodeServiceApi - factory interface
 * @export
 */
export const CodeServiceApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CodeServiceApiFp(configuration)
    return {
        /**
         * 가상 계좌 발급 요청
         * @summary 가상 계좌 발급 요청
         * @param {VirtualAccountsRequest} virtualAccountsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createVirtualAccounts(virtualAccountsRequest: VirtualAccountsRequest, options?: any): AxiosPromise<SearchCodeGroupsResponse> {
            return localVarFp.createVirtualAccounts(virtualAccountsRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 코드 그룹 검색
         * @summary 코드 그룹 검색
         * @param {SearchCodeGroupsRequest} searchCodeGroupsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchCodeGroups(searchCodeGroupsRequest: SearchCodeGroupsRequest, options?: any): AxiosPromise<SearchCodeGroupsResponse> {
            return localVarFp.searchCodeGroups(searchCodeGroupsRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CodeServiceApi - object-oriented interface
 * @export
 * @class CodeServiceApi
 * @extends {BaseAPI}
 */
export class CodeServiceApi extends BaseAPI {
    /**
     * 가상 계좌 발급 요청
     * @summary 가상 계좌 발급 요청
     * @param {VirtualAccountsRequest} virtualAccountsRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CodeServiceApi
     */
    public createVirtualAccounts(virtualAccountsRequest: VirtualAccountsRequest, options?: RawAxiosRequestConfig) {
        return CodeServiceApiFp(this.configuration).createVirtualAccounts(virtualAccountsRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 코드 그룹 검색
     * @summary 코드 그룹 검색
     * @param {SearchCodeGroupsRequest} searchCodeGroupsRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CodeServiceApi
     */
    public searchCodeGroups(searchCodeGroupsRequest: SearchCodeGroupsRequest, options?: RawAxiosRequestConfig) {
        return CodeServiceApiFp(this.configuration).searchCodeGroups(searchCodeGroupsRequest, options).then((request) => request(this.axios, this.basePath));
    }
}

