/* tslint:disable */
/* eslint-disable */
/**
 * Rent25 API 가이드
 * Rent25 API 가이드 문서입니다.
 *
 * The version of the OpenAPI document: v0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
// @ts-ignore
import { ErrorResponse } from '../models';
// @ts-ignore
import { SearchUsersRequest } from '../models';
// @ts-ignore
import { SearchUsersResponse } from '../models';
// @ts-ignore
import { SignupRequest } from '../models';
// @ts-ignore
import { SignupResponse } from '../models';
// @ts-ignore
import { UpdateUserRequest } from '../models';
// @ts-ignore
import { UpdateUserResponse } from '../models';
// @ts-ignore
import { UpdateUserStatusRequest } from '../models';
// @ts-ignore
import { UpdateUserStatusResponse } from '../models';
// @ts-ignore
import { UserResponse } from '../models';
/**
 * UserServiceApi - axios parameter creator
 * @export
 */
export const UserServiceApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 회원 정보 조회
         * @summary 회원 정보 조회
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUser: async (userId: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('getUser', 'userId', userId)
            const localVarPath = `/apis/v1/user/{userId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 회원 검색
         * @summary 회원 검색
         * @param {SearchUsersRequest} searchUsersRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchUsers: async (searchUsersRequest: SearchUsersRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'searchUsersRequest' is not null or undefined
            assertParamExists('searchUsers', 'searchUsersRequest', searchUsersRequest)
            const localVarPath = `/apis/v1/user/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(searchUsersRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 회원 가입
         * @summary 회원 가입
         * @param {SignupRequest} signupRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        signup: async (signupRequest: SignupRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'signupRequest' is not null or undefined
            assertParamExists('signup', 'signupRequest', signupRequest)
            const localVarPath = `/apis/v1/user/signup`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(signupRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 회원 정보 수정
         * @summary 회원 정보 수정
         * @param {UpdateUserRequest} updateUserRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUser: async (updateUserRequest: UpdateUserRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'updateUserRequest' is not null or undefined
            assertParamExists('updateUser', 'updateUserRequest', updateUserRequest)
            const localVarPath = `/apis/v1/user`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateUserRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 회원 상태 변경
         * @summary 회원 상태 변경
         * @param {UpdateUserStatusRequest} updateUserStatusRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUserStatusCode: async (updateUserStatusRequest: UpdateUserStatusRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'updateUserStatusRequest' is not null or undefined
            assertParamExists('updateUserStatusCode', 'updateUserStatusRequest', updateUserStatusRequest)
            const localVarPath = `/apis/v1/user/status`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateUserStatusRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UserServiceApi - functional programming interface
 * @export
 */
export const UserServiceApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UserServiceApiAxiosParamCreator(configuration)
    return {
        /**
         * 회원 정보 조회
         * @summary 회원 정보 조회
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUser(userId: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUser(userId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserServiceApi.getUser']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 회원 검색
         * @summary 회원 검색
         * @param {SearchUsersRequest} searchUsersRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async searchUsers(searchUsersRequest: SearchUsersRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SearchUsersResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.searchUsers(searchUsersRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserServiceApi.searchUsers']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 회원 가입
         * @summary 회원 가입
         * @param {SignupRequest} signupRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async signup(signupRequest: SignupRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SignupResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.signup(signupRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserServiceApi.signup']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 회원 정보 수정
         * @summary 회원 정보 수정
         * @param {UpdateUserRequest} updateUserRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateUser(updateUserRequest: UpdateUserRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UpdateUserResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateUser(updateUserRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserServiceApi.updateUser']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 회원 상태 변경
         * @summary 회원 상태 변경
         * @param {UpdateUserStatusRequest} updateUserStatusRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateUserStatusCode(updateUserStatusRequest: UpdateUserStatusRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UpdateUserStatusResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateUserStatusCode(updateUserStatusRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserServiceApi.updateUserStatusCode']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * UserServiceApi - factory interface
 * @export
 */
export const UserServiceApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UserServiceApiFp(configuration)
    return {
        /**
         * 회원 정보 조회
         * @summary 회원 정보 조회
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUser(userId: number, options?: any): AxiosPromise<UserResponse> {
            return localVarFp.getUser(userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 회원 검색
         * @summary 회원 검색
         * @param {SearchUsersRequest} searchUsersRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchUsers(searchUsersRequest: SearchUsersRequest, options?: any): AxiosPromise<SearchUsersResponse> {
            return localVarFp.searchUsers(searchUsersRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 회원 가입
         * @summary 회원 가입
         * @param {SignupRequest} signupRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        signup(signupRequest: SignupRequest, options?: any): AxiosPromise<SignupResponse> {
            return localVarFp.signup(signupRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 회원 정보 수정
         * @summary 회원 정보 수정
         * @param {UpdateUserRequest} updateUserRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUser(updateUserRequest: UpdateUserRequest, options?: any): AxiosPromise<UpdateUserResponse> {
            return localVarFp.updateUser(updateUserRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 회원 상태 변경
         * @summary 회원 상태 변경
         * @param {UpdateUserStatusRequest} updateUserStatusRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUserStatusCode(updateUserStatusRequest: UpdateUserStatusRequest, options?: any): AxiosPromise<UpdateUserStatusResponse> {
            return localVarFp.updateUserStatusCode(updateUserStatusRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UserServiceApi - object-oriented interface
 * @export
 * @class UserServiceApi
 * @extends {BaseAPI}
 */
export class UserServiceApi extends BaseAPI {
    /**
     * 회원 정보 조회
     * @summary 회원 정보 조회
     * @param {number} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserServiceApi
     */
    public getUser(userId: number, options?: RawAxiosRequestConfig) {
        return UserServiceApiFp(this.configuration).getUser(userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 회원 검색
     * @summary 회원 검색
     * @param {SearchUsersRequest} searchUsersRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserServiceApi
     */
    public searchUsers(searchUsersRequest: SearchUsersRequest, options?: RawAxiosRequestConfig) {
        return UserServiceApiFp(this.configuration).searchUsers(searchUsersRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 회원 가입
     * @summary 회원 가입
     * @param {SignupRequest} signupRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserServiceApi
     */
    public signup(signupRequest: SignupRequest, options?: RawAxiosRequestConfig) {
        return UserServiceApiFp(this.configuration).signup(signupRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 회원 정보 수정
     * @summary 회원 정보 수정
     * @param {UpdateUserRequest} updateUserRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserServiceApi
     */
    public updateUser(updateUserRequest: UpdateUserRequest, options?: RawAxiosRequestConfig) {
        return UserServiceApiFp(this.configuration).updateUser(updateUserRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 회원 상태 변경
     * @summary 회원 상태 변경
     * @param {UpdateUserStatusRequest} updateUserStatusRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserServiceApi
     */
    public updateUserStatusCode(updateUserStatusRequest: UpdateUserStatusRequest, options?: RawAxiosRequestConfig) {
        return UserServiceApiFp(this.configuration).updateUserStatusCode(updateUserStatusRequest, options).then((request) => request(this.axios, this.basePath));
    }
}

