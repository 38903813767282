import { Typography } from '@mui/material';
import TextField, { TextFieldProps } from '@mui/material/TextField';
import React, { useEffect, useState } from 'react';

interface PhoneNumberInputProps extends Omit<TextFieldProps, 'value' | 'onChange'> {
  onChange?: (e: React.ChangeEvent<any>, value: string) => void;
  value?: string;
  edit?: boolean;
}

export default function PhoneNumberField({ value: externalValue, edit = true, onChange, ...props }: PhoneNumberInputProps) {
  const [phoneNumber, setPhoneNumber] = useState<string>(externalValue || '');

  useEffect(() => {
    setPhoneNumber(externalValue || '');
  }, [externalValue]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    const onlyNums = value.replace(/[^\d]/g, '');

    let formattedNumber;
    if (onlyNums.length <= 3) {
      formattedNumber = onlyNums;
    } else if (onlyNums.length <= 7) {
      formattedNumber = `${onlyNums.slice(0, 3)}-${onlyNums.slice(3)}`;
    } else if (onlyNums.length === 9) {
      formattedNumber = `${onlyNums.slice(0, 2)}-${onlyNums.slice(2, 5)}-${onlyNums.slice(5, 9)}`;
    } else if (onlyNums.length === 10) {
      formattedNumber = `${onlyNums.slice(0, 2)}-${onlyNums.slice(2, 6)}-${onlyNums.slice(6, 10)}`;
    } else {
      formattedNumber = `${onlyNums.slice(0, 3)}-${onlyNums.slice(3, 7)}-${onlyNums.slice(7, 11)}`;
    }

    setPhoneNumber(formattedNumber);
    if (onChange) {
      onChange(event, formattedNumber);
    }
  };

  return (
    <>
      {edit ? (
        <TextField {...props} value={phoneNumber} onChange={handleChange} />
      ) : (
        <Typography
          variant="body1"
          sx={{
            fontWeight: 'bold',
            display: 'inline-block',
            border: '1px solid transparent',
            borderRadius: '4px',
            padding: '14px 13px',
            lineHeight: 'normal',
            width: '100%'
            //maxWidth: props.fullWidth ? '100%' : `calc(100% - ${props.margin ? props.margin * 2 : 0}px)`
          }}
        >
          {phoneNumber}
        </Typography>
      )}
    </>
  );
}
