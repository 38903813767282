/* tslint:disable */
/* eslint-disable */
/**
 * Rent25 API 가이드
 * Rent25 API 가이드 문서입니다.
 *
 * The version of the OpenAPI document: v0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
// @ts-ignore
import { CarAdditionalResponse } from '../models';
// @ts-ignore
import { CreateEstimateRequest } from '../models';
// @ts-ignore
import { DownloadContractRequest } from '../models';
// @ts-ignore
import { ErrorResponse } from '../models';
// @ts-ignore
import { EstimateResponse } from '../models';
// @ts-ignore
import { EstimateSetupResponse } from '../models';
// @ts-ignore
import { SearchEstimateCountResponse } from '../models';
// @ts-ignore
import { SearchEstimateRequest } from '../models';
// @ts-ignore
import { SearchEstimateResponse } from '../models';
// @ts-ignore
import { SearchRentCarRequest } from '../models';
// @ts-ignore
import { SearchRentCarResponse } from '../models';
// @ts-ignore
import { UpdateEstimateRequest } from '../models';
// @ts-ignore
import { UpsertEstimateSetupRequest } from '../models';
// @ts-ignore
import { UpsertEstimateSetupResponse } from '../models';
/**
 * EstimateServiceApi - axios parameter creator
 * @export
 */
export const EstimateServiceApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 견적서 등록
         * @summary 견적서 등록
         * @param {CreateEstimateRequest} createEstimateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createEstimate: async (createEstimateRequest: CreateEstimateRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createEstimateRequest' is not null or undefined
            assertParamExists('createEstimate', 'createEstimateRequest', createEstimateRequest)
            const localVarPath = `/apis/v1/estimate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createEstimateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 약정서 다운로드
         * @summary 약정서 다운로드
         * @param {DownloadContractRequest} downloadContractRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadContract: async (downloadContractRequest: DownloadContractRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'downloadContractRequest' is not null or undefined
            assertParamExists('downloadContract', 'downloadContractRequest', downloadContractRequest)
            const localVarPath = `/apis/v1/estimate/contract/download`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(downloadContractRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 백업(기본) 정보 존재 여부
         * @summary 백업(기본) 정보 존재 여부
         * @param {string} setupTypeCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        existBackupEstimateSetup: async (setupTypeCode: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'setupTypeCode' is not null or undefined
            assertParamExists('existBackupEstimateSetup', 'setupTypeCode', setupTypeCode)
            const localVarPath = `/apis/v1/estimate/setup/exist/backup/{setupTypeCode}`
                .replace(`{${"setupTypeCode"}}`, encodeURIComponent(String(setupTypeCode)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 견적 조회
         * @summary 견적 조회
         * @param {number} estimateId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEstimate: async (estimateId: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'estimateId' is not null or undefined
            assertParamExists('getEstimate', 'estimateId', estimateId)
            const localVarPath = `/apis/v1/estimate/{estimateId}`
                .replace(`{${"estimateId"}}`, encodeURIComponent(String(estimateId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 견적 설정 정보
         * @summary 견적 설정 정보
         * @param {number} companyId 
         * @param {string} setupTypeCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEstimateSetup: async (companyId: number, setupTypeCode: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('getEstimateSetup', 'companyId', companyId)
            // verify required parameter 'setupTypeCode' is not null or undefined
            assertParamExists('getEstimateSetup', 'setupTypeCode', setupTypeCode)
            const localVarPath = `/apis/v1/estimate/{companyId}/setup/{setupTypeCode}`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)))
                .replace(`{${"setupTypeCode"}}`, encodeURIComponent(String(setupTypeCode)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 견적 설정 정보
         * @summary 견적 설정 정보
         * @param {number} companyId 
         * @param {string} setupTypeCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEstimateSetupBackup: async (companyId: number, setupTypeCode: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('getEstimateSetupBackup', 'companyId', companyId)
            // verify required parameter 'setupTypeCode' is not null or undefined
            assertParamExists('getEstimateSetupBackup', 'setupTypeCode', setupTypeCode)
            const localVarPath = `/apis/v1/estimate/{companyId}/setup/{setupTypeCode}/backup`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)))
                .replace(`{${"setupTypeCode"}}`, encodeURIComponent(String(setupTypeCode)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * RENT25 견적 설정 정보
         * @summary RENT25 견적 설정 정보
         * @param {string} setupTypeCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRent25EstimateSetup: async (setupTypeCode: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'setupTypeCode' is not null or undefined
            assertParamExists('getRent25EstimateSetup', 'setupTypeCode', setupTypeCode)
            const localVarPath = `/apis/v1/estimate/rent25/setup/{setupTypeCode}`
                .replace(`{${"setupTypeCode"}}`, encodeURIComponent(String(setupTypeCode)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 견적 설정 정보 초기화
         * @summary 견적 설정 정보 초기화
         * @param {string} setupTypeCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        initRent25EstimateSetup: async (setupTypeCode: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'setupTypeCode' is not null or undefined
            assertParamExists('initRent25EstimateSetup', 'setupTypeCode', setupTypeCode)
            const localVarPath = `/apis/v1/estimate/setup/initialization/{setupTypeCode}`
                .replace(`{${"setupTypeCode"}}`, encodeURIComponent(String(setupTypeCode)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 백업(기본) 정보로 변경하기
         * @summary 백업(기본) 정보로 변경하기
         * @param {string} setupTypeCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loadBackupEstimateSetup: async (setupTypeCode: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'setupTypeCode' is not null or undefined
            assertParamExists('loadBackupEstimateSetup', 'setupTypeCode', setupTypeCode)
            const localVarPath = `/apis/v1/estimate/setup/load/backup/{setupTypeCode}`
                .replace(`{${"setupTypeCode"}}`, encodeURIComponent(String(setupTypeCode)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 견적 검색
         * @summary 견적 검색
         * @param {SearchEstimateRequest} searchEstimateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchEstimate: async (searchEstimateRequest: SearchEstimateRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'searchEstimateRequest' is not null or undefined
            assertParamExists('searchEstimate', 'searchEstimateRequest', searchEstimateRequest)
            const localVarPath = `/apis/v1/estimate/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(searchEstimateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 견적 검색 카운트
         * @summary 견적 검색 카운트
         * @param {SearchEstimateRequest} searchEstimateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchEstimateCounts: async (searchEstimateRequest: SearchEstimateRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'searchEstimateRequest' is not null or undefined
            assertParamExists('searchEstimateCounts', 'searchEstimateRequest', searchEstimateRequest)
            const localVarPath = `/apis/v1/estimate/search/count`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(searchEstimateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 상품 조건에 따른 렌트카 업체 검색
         * @summary 렌트카 업체 검색
         * @param {SearchRentCarRequest} searchRentCarRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchRentCar: async (searchRentCarRequest: SearchRentCarRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'searchRentCarRequest' is not null or undefined
            assertParamExists('searchRentCar', 'searchRentCarRequest', searchRentCarRequest)
            const localVarPath = `/apis/v1/estimate/rentcar/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(searchRentCarRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 견적 차량 할부원금율 조회
         * @summary 견적 차량 할부원금율 조회
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        selectDefaultAdditional: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/apis/v1/estimate/setup/default/additional`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 견적 단계 진행
         * @summary 견적 단계 진행
         * @param {UpdateEstimateRequest} updateEstimateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stepByStepEstimate: async (updateEstimateRequest: UpdateEstimateRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'updateEstimateRequest' is not null or undefined
            assertParamExists('stepByStepEstimate', 'updateEstimateRequest', updateEstimateRequest)
            const localVarPath = `/apis/v1/estimate/step`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateEstimateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 견적서 수정
         * @summary 견적서 수정
         * @param {UpdateEstimateRequest} updateEstimateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateEstimate: async (updateEstimateRequest: UpdateEstimateRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'updateEstimateRequest' is not null or undefined
            assertParamExists('updateEstimate', 'updateEstimateRequest', updateEstimateRequest)
            const localVarPath = `/apis/v1/estimate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateEstimateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 견적 상태 변경
         * @summary 견적 상태 변경
         * @param {UpdateEstimateRequest} updateEstimateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateEstimateStatus: async (updateEstimateRequest: UpdateEstimateRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'updateEstimateRequest' is not null or undefined
            assertParamExists('updateEstimateStatus', 'updateEstimateRequest', updateEstimateRequest)
            const localVarPath = `/apis/v1/estimate/status`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateEstimateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 견적 설정 생성
         * @summary 견적 설정 생성
         * @param {number} companyId 
         * @param {string} setupTypeCode 
         * @param {string} saveTypeCode 
         * @param {UpsertEstimateSetupRequest} upsertEstimateSetupRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        upsertEstimateSetup: async (companyId: number, setupTypeCode: string, saveTypeCode: string, upsertEstimateSetupRequest: UpsertEstimateSetupRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('upsertEstimateSetup', 'companyId', companyId)
            // verify required parameter 'setupTypeCode' is not null or undefined
            assertParamExists('upsertEstimateSetup', 'setupTypeCode', setupTypeCode)
            // verify required parameter 'saveTypeCode' is not null or undefined
            assertParamExists('upsertEstimateSetup', 'saveTypeCode', saveTypeCode)
            // verify required parameter 'upsertEstimateSetupRequest' is not null or undefined
            assertParamExists('upsertEstimateSetup', 'upsertEstimateSetupRequest', upsertEstimateSetupRequest)
            const localVarPath = `/apis/v1/estimate/{companyId}/setup/{setupTypeCode}/{saveTypeCode}`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)))
                .replace(`{${"setupTypeCode"}}`, encodeURIComponent(String(setupTypeCode)))
                .replace(`{${"saveTypeCode"}}`, encodeURIComponent(String(saveTypeCode)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(upsertEstimateSetupRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * EstimateServiceApi - functional programming interface
 * @export
 */
export const EstimateServiceApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = EstimateServiceApiAxiosParamCreator(configuration)
    return {
        /**
         * 견적서 등록
         * @summary 견적서 등록
         * @param {CreateEstimateRequest} createEstimateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createEstimate(createEstimateRequest: CreateEstimateRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EstimateResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createEstimate(createEstimateRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['EstimateServiceApi.createEstimate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 약정서 다운로드
         * @summary 약정서 다운로드
         * @param {DownloadContractRequest} downloadContractRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async downloadContract(downloadContractRequest: DownloadContractRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<File>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.downloadContract(downloadContractRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['EstimateServiceApi.downloadContract']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 백업(기본) 정보 존재 여부
         * @summary 백업(기본) 정보 존재 여부
         * @param {string} setupTypeCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async existBackupEstimateSetup(setupTypeCode: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.existBackupEstimateSetup(setupTypeCode, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['EstimateServiceApi.existBackupEstimateSetup']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 견적 조회
         * @summary 견적 조회
         * @param {number} estimateId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getEstimate(estimateId: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EstimateResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getEstimate(estimateId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['EstimateServiceApi.getEstimate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 견적 설정 정보
         * @summary 견적 설정 정보
         * @param {number} companyId 
         * @param {string} setupTypeCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getEstimateSetup(companyId: number, setupTypeCode: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EstimateSetupResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getEstimateSetup(companyId, setupTypeCode, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['EstimateServiceApi.getEstimateSetup']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 견적 설정 정보
         * @summary 견적 설정 정보
         * @param {number} companyId 
         * @param {string} setupTypeCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getEstimateSetupBackup(companyId: number, setupTypeCode: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EstimateSetupResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getEstimateSetupBackup(companyId, setupTypeCode, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['EstimateServiceApi.getEstimateSetupBackup']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * RENT25 견적 설정 정보
         * @summary RENT25 견적 설정 정보
         * @param {string} setupTypeCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRent25EstimateSetup(setupTypeCode: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EstimateSetupResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRent25EstimateSetup(setupTypeCode, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['EstimateServiceApi.getRent25EstimateSetup']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 견적 설정 정보 초기화
         * @summary 견적 설정 정보 초기화
         * @param {string} setupTypeCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async initRent25EstimateSetup(setupTypeCode: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EstimateSetupResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.initRent25EstimateSetup(setupTypeCode, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['EstimateServiceApi.initRent25EstimateSetup']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 백업(기본) 정보로 변경하기
         * @summary 백업(기본) 정보로 변경하기
         * @param {string} setupTypeCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async loadBackupEstimateSetup(setupTypeCode: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EstimateSetupResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.loadBackupEstimateSetup(setupTypeCode, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['EstimateServiceApi.loadBackupEstimateSetup']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 견적 검색
         * @summary 견적 검색
         * @param {SearchEstimateRequest} searchEstimateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async searchEstimate(searchEstimateRequest: SearchEstimateRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SearchEstimateResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.searchEstimate(searchEstimateRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['EstimateServiceApi.searchEstimate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 견적 검색 카운트
         * @summary 견적 검색 카운트
         * @param {SearchEstimateRequest} searchEstimateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async searchEstimateCounts(searchEstimateRequest: SearchEstimateRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SearchEstimateCountResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.searchEstimateCounts(searchEstimateRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['EstimateServiceApi.searchEstimateCounts']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 상품 조건에 따른 렌트카 업체 검색
         * @summary 렌트카 업체 검색
         * @param {SearchRentCarRequest} searchRentCarRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async searchRentCar(searchRentCarRequest: SearchRentCarRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SearchRentCarResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.searchRentCar(searchRentCarRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['EstimateServiceApi.searchRentCar']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 견적 차량 할부원금율 조회
         * @summary 견적 차량 할부원금율 조회
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async selectDefaultAdditional(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CarAdditionalResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.selectDefaultAdditional(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['EstimateServiceApi.selectDefaultAdditional']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 견적 단계 진행
         * @summary 견적 단계 진행
         * @param {UpdateEstimateRequest} updateEstimateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async stepByStepEstimate(updateEstimateRequest: UpdateEstimateRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EstimateResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.stepByStepEstimate(updateEstimateRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['EstimateServiceApi.stepByStepEstimate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 견적서 수정
         * @summary 견적서 수정
         * @param {UpdateEstimateRequest} updateEstimateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateEstimate(updateEstimateRequest: UpdateEstimateRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EstimateResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateEstimate(updateEstimateRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['EstimateServiceApi.updateEstimate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 견적 상태 변경
         * @summary 견적 상태 변경
         * @param {UpdateEstimateRequest} updateEstimateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateEstimateStatus(updateEstimateRequest: UpdateEstimateRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EstimateResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateEstimateStatus(updateEstimateRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['EstimateServiceApi.updateEstimateStatus']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 견적 설정 생성
         * @summary 견적 설정 생성
         * @param {number} companyId 
         * @param {string} setupTypeCode 
         * @param {string} saveTypeCode 
         * @param {UpsertEstimateSetupRequest} upsertEstimateSetupRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async upsertEstimateSetup(companyId: number, setupTypeCode: string, saveTypeCode: string, upsertEstimateSetupRequest: UpsertEstimateSetupRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UpsertEstimateSetupResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.upsertEstimateSetup(companyId, setupTypeCode, saveTypeCode, upsertEstimateSetupRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['EstimateServiceApi.upsertEstimateSetup']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * EstimateServiceApi - factory interface
 * @export
 */
export const EstimateServiceApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = EstimateServiceApiFp(configuration)
    return {
        /**
         * 견적서 등록
         * @summary 견적서 등록
         * @param {CreateEstimateRequest} createEstimateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createEstimate(createEstimateRequest: CreateEstimateRequest, options?: any): AxiosPromise<EstimateResponse> {
            return localVarFp.createEstimate(createEstimateRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 약정서 다운로드
         * @summary 약정서 다운로드
         * @param {DownloadContractRequest} downloadContractRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadContract(downloadContractRequest: DownloadContractRequest, options?: any): AxiosPromise<File> {
            return localVarFp.downloadContract(downloadContractRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 백업(기본) 정보 존재 여부
         * @summary 백업(기본) 정보 존재 여부
         * @param {string} setupTypeCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        existBackupEstimateSetup(setupTypeCode: string, options?: any): AxiosPromise<number> {
            return localVarFp.existBackupEstimateSetup(setupTypeCode, options).then((request) => request(axios, basePath));
        },
        /**
         * 견적 조회
         * @summary 견적 조회
         * @param {number} estimateId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEstimate(estimateId: number, options?: any): AxiosPromise<EstimateResponse> {
            return localVarFp.getEstimate(estimateId, options).then((request) => request(axios, basePath));
        },
        /**
         * 견적 설정 정보
         * @summary 견적 설정 정보
         * @param {number} companyId 
         * @param {string} setupTypeCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEstimateSetup(companyId: number, setupTypeCode: string, options?: any): AxiosPromise<EstimateSetupResponse> {
            return localVarFp.getEstimateSetup(companyId, setupTypeCode, options).then((request) => request(axios, basePath));
        },
        /**
         * 견적 설정 정보
         * @summary 견적 설정 정보
         * @param {number} companyId 
         * @param {string} setupTypeCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEstimateSetupBackup(companyId: number, setupTypeCode: string, options?: any): AxiosPromise<EstimateSetupResponse> {
            return localVarFp.getEstimateSetupBackup(companyId, setupTypeCode, options).then((request) => request(axios, basePath));
        },
        /**
         * RENT25 견적 설정 정보
         * @summary RENT25 견적 설정 정보
         * @param {string} setupTypeCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRent25EstimateSetup(setupTypeCode: string, options?: any): AxiosPromise<EstimateSetupResponse> {
            return localVarFp.getRent25EstimateSetup(setupTypeCode, options).then((request) => request(axios, basePath));
        },
        /**
         * 견적 설정 정보 초기화
         * @summary 견적 설정 정보 초기화
         * @param {string} setupTypeCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        initRent25EstimateSetup(setupTypeCode: string, options?: any): AxiosPromise<EstimateSetupResponse> {
            return localVarFp.initRent25EstimateSetup(setupTypeCode, options).then((request) => request(axios, basePath));
        },
        /**
         * 백업(기본) 정보로 변경하기
         * @summary 백업(기본) 정보로 변경하기
         * @param {string} setupTypeCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loadBackupEstimateSetup(setupTypeCode: string, options?: any): AxiosPromise<EstimateSetupResponse> {
            return localVarFp.loadBackupEstimateSetup(setupTypeCode, options).then((request) => request(axios, basePath));
        },
        /**
         * 견적 검색
         * @summary 견적 검색
         * @param {SearchEstimateRequest} searchEstimateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchEstimate(searchEstimateRequest: SearchEstimateRequest, options?: any): AxiosPromise<SearchEstimateResponse> {
            return localVarFp.searchEstimate(searchEstimateRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 견적 검색 카운트
         * @summary 견적 검색 카운트
         * @param {SearchEstimateRequest} searchEstimateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchEstimateCounts(searchEstimateRequest: SearchEstimateRequest, options?: any): AxiosPromise<SearchEstimateCountResponse> {
            return localVarFp.searchEstimateCounts(searchEstimateRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 상품 조건에 따른 렌트카 업체 검색
         * @summary 렌트카 업체 검색
         * @param {SearchRentCarRequest} searchRentCarRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchRentCar(searchRentCarRequest: SearchRentCarRequest, options?: any): AxiosPromise<SearchRentCarResponse> {
            return localVarFp.searchRentCar(searchRentCarRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 견적 차량 할부원금율 조회
         * @summary 견적 차량 할부원금율 조회
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        selectDefaultAdditional(options?: any): AxiosPromise<CarAdditionalResponse> {
            return localVarFp.selectDefaultAdditional(options).then((request) => request(axios, basePath));
        },
        /**
         * 견적 단계 진행
         * @summary 견적 단계 진행
         * @param {UpdateEstimateRequest} updateEstimateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stepByStepEstimate(updateEstimateRequest: UpdateEstimateRequest, options?: any): AxiosPromise<EstimateResponse> {
            return localVarFp.stepByStepEstimate(updateEstimateRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 견적서 수정
         * @summary 견적서 수정
         * @param {UpdateEstimateRequest} updateEstimateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateEstimate(updateEstimateRequest: UpdateEstimateRequest, options?: any): AxiosPromise<EstimateResponse> {
            return localVarFp.updateEstimate(updateEstimateRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 견적 상태 변경
         * @summary 견적 상태 변경
         * @param {UpdateEstimateRequest} updateEstimateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateEstimateStatus(updateEstimateRequest: UpdateEstimateRequest, options?: any): AxiosPromise<EstimateResponse> {
            return localVarFp.updateEstimateStatus(updateEstimateRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 견적 설정 생성
         * @summary 견적 설정 생성
         * @param {number} companyId 
         * @param {string} setupTypeCode 
         * @param {string} saveTypeCode 
         * @param {UpsertEstimateSetupRequest} upsertEstimateSetupRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        upsertEstimateSetup(companyId: number, setupTypeCode: string, saveTypeCode: string, upsertEstimateSetupRequest: UpsertEstimateSetupRequest, options?: any): AxiosPromise<UpsertEstimateSetupResponse> {
            return localVarFp.upsertEstimateSetup(companyId, setupTypeCode, saveTypeCode, upsertEstimateSetupRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * EstimateServiceApi - object-oriented interface
 * @export
 * @class EstimateServiceApi
 * @extends {BaseAPI}
 */
export class EstimateServiceApi extends BaseAPI {
    /**
     * 견적서 등록
     * @summary 견적서 등록
     * @param {CreateEstimateRequest} createEstimateRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EstimateServiceApi
     */
    public createEstimate(createEstimateRequest: CreateEstimateRequest, options?: RawAxiosRequestConfig) {
        return EstimateServiceApiFp(this.configuration).createEstimate(createEstimateRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 약정서 다운로드
     * @summary 약정서 다운로드
     * @param {DownloadContractRequest} downloadContractRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EstimateServiceApi
     */
    public downloadContract(downloadContractRequest: DownloadContractRequest, options?: RawAxiosRequestConfig) {
        return EstimateServiceApiFp(this.configuration).downloadContract(downloadContractRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 백업(기본) 정보 존재 여부
     * @summary 백업(기본) 정보 존재 여부
     * @param {string} setupTypeCode 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EstimateServiceApi
     */
    public existBackupEstimateSetup(setupTypeCode: string, options?: RawAxiosRequestConfig) {
        return EstimateServiceApiFp(this.configuration).existBackupEstimateSetup(setupTypeCode, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 견적 조회
     * @summary 견적 조회
     * @param {number} estimateId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EstimateServiceApi
     */
    public getEstimate(estimateId: number, options?: RawAxiosRequestConfig) {
        return EstimateServiceApiFp(this.configuration).getEstimate(estimateId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 견적 설정 정보
     * @summary 견적 설정 정보
     * @param {number} companyId 
     * @param {string} setupTypeCode 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EstimateServiceApi
     */
    public getEstimateSetup(companyId: number, setupTypeCode: string, options?: RawAxiosRequestConfig) {
        return EstimateServiceApiFp(this.configuration).getEstimateSetup(companyId, setupTypeCode, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 견적 설정 정보
     * @summary 견적 설정 정보
     * @param {number} companyId 
     * @param {string} setupTypeCode 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EstimateServiceApi
     */
    public getEstimateSetupBackup(companyId: number, setupTypeCode: string, options?: RawAxiosRequestConfig) {
        return EstimateServiceApiFp(this.configuration).getEstimateSetupBackup(companyId, setupTypeCode, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * RENT25 견적 설정 정보
     * @summary RENT25 견적 설정 정보
     * @param {string} setupTypeCode 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EstimateServiceApi
     */
    public getRent25EstimateSetup(setupTypeCode: string, options?: RawAxiosRequestConfig) {
        return EstimateServiceApiFp(this.configuration).getRent25EstimateSetup(setupTypeCode, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 견적 설정 정보 초기화
     * @summary 견적 설정 정보 초기화
     * @param {string} setupTypeCode 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EstimateServiceApi
     */
    public initRent25EstimateSetup(setupTypeCode: string, options?: RawAxiosRequestConfig) {
        return EstimateServiceApiFp(this.configuration).initRent25EstimateSetup(setupTypeCode, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 백업(기본) 정보로 변경하기
     * @summary 백업(기본) 정보로 변경하기
     * @param {string} setupTypeCode 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EstimateServiceApi
     */
    public loadBackupEstimateSetup(setupTypeCode: string, options?: RawAxiosRequestConfig) {
        return EstimateServiceApiFp(this.configuration).loadBackupEstimateSetup(setupTypeCode, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 견적 검색
     * @summary 견적 검색
     * @param {SearchEstimateRequest} searchEstimateRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EstimateServiceApi
     */
    public searchEstimate(searchEstimateRequest: SearchEstimateRequest, options?: RawAxiosRequestConfig) {
        return EstimateServiceApiFp(this.configuration).searchEstimate(searchEstimateRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 견적 검색 카운트
     * @summary 견적 검색 카운트
     * @param {SearchEstimateRequest} searchEstimateRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EstimateServiceApi
     */
    public searchEstimateCounts(searchEstimateRequest: SearchEstimateRequest, options?: RawAxiosRequestConfig) {
        return EstimateServiceApiFp(this.configuration).searchEstimateCounts(searchEstimateRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 상품 조건에 따른 렌트카 업체 검색
     * @summary 렌트카 업체 검색
     * @param {SearchRentCarRequest} searchRentCarRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EstimateServiceApi
     */
    public searchRentCar(searchRentCarRequest: SearchRentCarRequest, options?: RawAxiosRequestConfig) {
        return EstimateServiceApiFp(this.configuration).searchRentCar(searchRentCarRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 견적 차량 할부원금율 조회
     * @summary 견적 차량 할부원금율 조회
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EstimateServiceApi
     */
    public selectDefaultAdditional(options?: RawAxiosRequestConfig) {
        return EstimateServiceApiFp(this.configuration).selectDefaultAdditional(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 견적 단계 진행
     * @summary 견적 단계 진행
     * @param {UpdateEstimateRequest} updateEstimateRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EstimateServiceApi
     */
    public stepByStepEstimate(updateEstimateRequest: UpdateEstimateRequest, options?: RawAxiosRequestConfig) {
        return EstimateServiceApiFp(this.configuration).stepByStepEstimate(updateEstimateRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 견적서 수정
     * @summary 견적서 수정
     * @param {UpdateEstimateRequest} updateEstimateRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EstimateServiceApi
     */
    public updateEstimate(updateEstimateRequest: UpdateEstimateRequest, options?: RawAxiosRequestConfig) {
        return EstimateServiceApiFp(this.configuration).updateEstimate(updateEstimateRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 견적 상태 변경
     * @summary 견적 상태 변경
     * @param {UpdateEstimateRequest} updateEstimateRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EstimateServiceApi
     */
    public updateEstimateStatus(updateEstimateRequest: UpdateEstimateRequest, options?: RawAxiosRequestConfig) {
        return EstimateServiceApiFp(this.configuration).updateEstimateStatus(updateEstimateRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 견적 설정 생성
     * @summary 견적 설정 생성
     * @param {number} companyId 
     * @param {string} setupTypeCode 
     * @param {string} saveTypeCode 
     * @param {UpsertEstimateSetupRequest} upsertEstimateSetupRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EstimateServiceApi
     */
    public upsertEstimateSetup(companyId: number, setupTypeCode: string, saveTypeCode: string, upsertEstimateSetupRequest: UpsertEstimateSetupRequest, options?: RawAxiosRequestConfig) {
        return EstimateServiceApiFp(this.configuration).upsertEstimateSetup(companyId, setupTypeCode, saveTypeCode, upsertEstimateSetupRequest, options).then((request) => request(this.axios, this.basePath));
    }
}

