import { lazy } from 'react';
import Loadable from 'components/Loadable';

// project-imports
import MainLayout from 'layout/MainLayout';
import AuthGuard from 'utils/route-guard/AuthGuard';
import NoticeListPage from 'pages/operation/notice-list';
import NoticeViewPage from 'pages/operation/notice-view';
import PdsListPage from 'pages/operation/pds-list';
import PdsViewPage from 'pages/operation/pds-view';

const MyCompanyPage = Loadable(lazy(() => import('pages/rent25/company/company')));
const CompanyEmployeeListPage = Loadable(lazy(() => import('pages/rent25/company/company-employee-list')));
const CompanyView = Loadable(lazy(() => import('sections/rent25/company/CompanyView')));
const NewCarListPage = Loadable(lazy(() => import('pages/rent25/car/new-car-list')));
const EstimateViewPage = Loadable(lazy(() => import('pages/rent25/estimate/estimate-view')));
const EstimateListPage = Loadable(lazy(() => import('pages/rent25/estimate/estimate-list')));
const EstimateRegistrationPage = Loadable(lazy(() => import('pages/rent25/estimate/estimate-registration')));
const NewCarViewPage = Loadable(lazy(() => import('pages/rent25/car/new-car-view')));
const EstimateUpdatePage = Loadable(lazy(() => import('pages/rent25/estimate/estimate-update')));
const EstimateComparisonPage = Loadable(lazy(() => import('pages/rent25/estimate/estimate-comparison')));
const AgentDashboard = Loadable(lazy(() => import('pages/agent/AgentDashboard')));

const AuthAgentRoutes = {
  path: '/',
  children: [
    {
      path: '/',
      element: (
        <AuthGuard>
          <MainLayout />
        </AuthGuard>
      ),
      children: [
        {
          name: 'Dashboard',
          path: '/dashboard',
          element: <AgentDashboard />
        },
        {
          name: 'AGENT',
          path: '/agent',
          children: [
            {
              name: '나의회사',
              path: 'my-company',
              element: <MyCompanyPage />,
              children: [
                {
                  name: '회사 정보',
                  path: 'information',
                  element: <CompanyView />
                },
                {
                  name: '회사 직원 목록',
                  path: 'employee',
                  element: <CompanyEmployeeListPage />
                }
              ]
            },
            {
              name: '차량',
              path: 'car',
              children: [
                {
                  name: '신차(보유차량)',
                  path: 'new',
                  children: [
                    {
                      path: 'list',
                      element: <NewCarListPage />
                    },
                    {
                      path: 'view',
                      element: <NewCarViewPage />
                    }
                  ]
                }
              ]
            },
            {
              name: '견적',
              path: 'estimate',
              children: [
                {
                  path: 'list',
                  element: <EstimateListPage />
                },
                {
                  path: 'registration',
                  element: <EstimateRegistrationPage />
                },
                {
                  path: 'view',
                  element: <EstimateViewPage />
                },
                {
                  path: 'update',
                  element: <EstimateUpdatePage />
                },
                {
                  path: 'comparison',
                  element: <EstimateComparisonPage />
                }
              ]
            },
            {
              name: '운영관리',
              path: 'operation/management',
              children: [
                {
                  path: 'notice-list',
                  element: <NoticeListPage />
                },
                {
                  path: 'notice-view',
                  element: <NoticeViewPage />
                },
                {
                  path: 'pds-list',
                  element: <PdsListPage />
                },
                {
                  path: 'pds-view',
                  element: <PdsViewPage />
                }
              ]
            }
          ]
        }
      ]
    }
  ]
};

export default AuthAgentRoutes;
