import { HighlightOff } from '@mui/icons-material';
import {
  useMediaQuery,
  ToggleButtonGroup,
  ToggleButton,
  Typography,
  TextField,
  InputAdornment,
  IconButton,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  useTheme,
  Button,
  MenuItem
} from '@mui/material';
import { Stack, alpha } from '@mui/system';
import EditableSelect from 'components/form/EditableSelect';
import { LoadingTable, EmptyTable, TablePagination } from 'components/third-party/ReactTable';
import { BbsType, CodeGroupName, CompanyTypeCode, UserTypeCode } from 'constant/Constant';
import { useCodes } from 'contexts/CodeContext';
import useAuth from 'hooks/useAuth';
import { SearchNormal1 } from 'iconsax-react';
import _ from 'lodash';
import { Code, Company, SearchBbsRequest } from 'openapi';
import { useState, useMemo, useEffect, Fragment } from 'react';
import { useNavigate } from 'react-router-dom';
import { Column, useTable, useExpanded, usePagination, HeaderGroup, Row, Cell } from 'react-table';
import { toCompanyTypePath } from 'utils/string-utils';

export interface BbsTableProps {
  bbsType: string;
  columns: Column[];
  companies: Company[];
  totalCount: number;
  fetchData: (condition: SearchBbsRequest) => void;
  loading: boolean;
  pageCount: number;
}

function BbsTable({ bbsType, columns, companies, totalCount, fetchData, loading, pageCount: controlledPageCount }: BbsTableProps) {
  const navigate = useNavigate();
  const theme = useTheme();
  const { user } = useAuth();

  const code = useCodes();

  const matchDownSM = useMediaQuery(theme.breakpoints.down('sm'));
  const [companyTypeCode, setCompanyTypeCode] = useState('');
  const [categoryCode, setCategoryCode] = useState<string>('');
  const [tempSearchText, setTempSearchText] = useState('');
  const [searchCount, setSearchCount] = useState(0);
  const [pdsTypeList, setPdsTypeList] = useState<Code[]>([]);
  const [companyTypePath, setCompanyTypePath] = useState('');

  useEffect(() => {
    if (user?.company?.companyTypeCode) {
      const path = toCompanyTypePath(user?.company?.companyTypeCode);
      if (path) {
        setCompanyTypePath(path);
      }
    }
  }, [user]);

  useEffect(() => {
    console.log(`bbstype = ${bbsType}`);
    if (bbsType === 'PDS') {
      setPdsTypeList(code.getCodes(CodeGroupName.PdsTypeCode));
    }
  }, [bbsType, code]);

  const defaultColumn = useMemo(
    () => ({
      minWidth: 50, // 최소 너비
      maxWidth: 200 // 최대 너비
    }),
    []
  );

  const initialState = useMemo(
    () => ({
      pageIndex: 0,
      pageSize: 10,
      hiddenColumns: ['bankName', 'bankAccountNumber', 'bankAccountName', 'webSite', 'description']
    }),
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    //rows,
    gotoPage,
    setPageSize,
    state: { pageIndex, pageSize },
    setHiddenColumns
  } = useTable(
    {
      columns,
      data: companies,
      defaultColumn,
      initialState,
      //autoResetPage: false,
      manualPagination: true,
      pageCount: controlledPageCount
    },
    useExpanded,
    usePagination
  );

  //console.log(data, page, rows);

  // Web, Mobile 별 노출 여부
  useEffect(() => {
    if (matchDownSM) {
      setHiddenColumns([]);
    } else {
      setHiddenColumns([]);
    }
    // eslint-disable-next-line
  }, [matchDownSM]);

  useEffect(() => {
    fetchData({
      page: { pageNo: pageIndex + 1, pageSize: pageSize },
      manageYn: user?.company?.companyTypeCode === CompanyTypeCode.rent25.code ? true : false,
      companyTypeCode: companyTypeCode,
      searchText: tempSearchText
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchData, pageIndex, pageSize, companyTypeCode, searchCount]);

  return (
    <>
      <Stack spacing={3}>
        {/*검색 영역 */}
        <Stack
          direction={matchDownSM ? 'column' : 'row'}
          spacing={1}
          justifyContent="space-between"
          alignItems="center"
          sx={{ p: 3, pb: 0 }}
        >
          <Stack direction={matchDownSM ? 'column' : 'row'} alignItems="center" spacing={1}>
            {user?.userTypeCode === UserTypeCode.rent25.code && (
              <ToggleButtonGroup
                size="small"
                color="primary"
                value={companyTypeCode}
                exclusive
                onChange={(event, value) => {
                  setCompanyTypeCode(value);
                }}
                aria-label="text alignment"
              >
                <ToggleButton value="" aria-label="left aligned" sx={{ width: 50 }}>
                  <Typography>전체</Typography>
                </ToggleButton>
                <ToggleButton value={CompanyTypeCode.rent25.code} aria-label="right aligned" sx={{ width: 80 }}>
                  <Typography>RENT25</Typography>
                </ToggleButton>
                <ToggleButton value={CompanyTypeCode.rentcar.code} aria-label="right aligned" sx={{ width: 80 }}>
                  <Typography>RENTCAR</Typography>
                </ToggleButton>
                <ToggleButton value={CompanyTypeCode.agent.code} aria-label="right aligned" sx={{ width: 80 }}>
                  <Typography>AG</Typography>
                </ToggleButton>
                <ToggleButton value={CompanyTypeCode.agentUnion.code} aria-label="centered" sx={{ width: 80 }}>
                  <Typography>AG연합</Typography>
                </ToggleButton>
              </ToggleButtonGroup>
            )}
            {bbsType === BbsType.pds.code && (
              <EditableSelect
                size="small"
                edit={true}
                value={categoryCode}
                placeholder="업체구분"
                onChange={(event) => {
                  setCategoryCode(event.target.value as string);
                }}
                sx={{
                  p: 0,
                  m: 0,
                  mr: 0.5,
                  width: 200
                }}
                displayEmpty
              >
                <MenuItem key={0} value={''}>
                  자료구분
                </MenuItem>
                {pdsTypeList.map((code, i) => (
                  <MenuItem key={i + 1} value={code.code}>
                    {code.codeName}
                  </MenuItem>
                ))}
              </EditableSelect>
            )}
            <TextField
              value={tempSearchText}
              onChange={(e) => {
                setTempSearchText(e.target.value);
              }}
              onKeyDown={(event) => {
                if (event.key === 'Enter') {
                  setSearchCount((prev) => prev + 1);
                }
              }}
              size="small"
              sx={{ width: '300px' }}
              id="with-label-input"
              placeholder="제목"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {tempSearchText && (
                      <IconButton
                        onClick={() => {
                          setTempSearchText('');
                          setSearchCount((prev) => prev + 1);
                        }}
                        edge="end"
                        size="small"
                      >
                        <HighlightOff />
                      </IconButton>
                    )}
                  </InputAdornment>
                )
              }}
            />{' '}
            <Button
              size="small"
              sx={{ height: '37px' }}
              variant="contained"
              startIcon={<SearchNormal1 />}
              onClick={() => setSearchCount((prev) => prev + 1)}
            >
              조회
            </Button>
          </Stack>
          <Stack>
            {user?.company?.companyTypeCode === CompanyTypeCode.rent25.code && (
              <Button
                size="small"
                sx={{ height: '37px' }}
                variant="contained"
                startIcon={<SearchNormal1 />}
                onClick={() => {
                  if (companyTypePath) {
                    navigate(`/${companyTypePath}/operation/management/${_.lowerCase(bbsType)}-register`);
                  }
                }}
              >
                {bbsType === BbsType.notice.code ? '공지사항 등록' : '자료실 등록'}
              </Button>
            )}
          </Stack>
        </Stack>
        <Table {...getTableProps()}>
          <TableHead>
            {headerGroups.map((headerGroup: HeaderGroup<{}>) => (
              <TableRow {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column: HeaderGroup) => (
                  <TableCell
                    {...column.getHeaderProps([{ className: column.className }])}
                    sx={{ '&:hover::after': { bgcolor: 'primary.main', width: 2 } }}
                  >
                    {column.render('Header')}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableHead>
          <TableBody {...getTableBodyProps()}>
            {loading ? (
              <LoadingTable msg="로딩중 ..." colSpan={11} />
            ) : page.length > 0 ? (
              page.map((row: Row<any>, i: number) => {
                prepareRow(row);
                return (
                  <Fragment key={i}>
                    <TableRow
                      {...row.getRowProps()}
                      onClick={() => {
                        if (companyTypePath) {
                          navigate(`/${companyTypePath}/operation/management/${_.toLower(bbsType)}-view`, {
                            state: { bbsId: row.original.bbsId }
                          });
                        }
                      }}
                      sx={{ cursor: 'pointer', bgcolor: row.isSelected ? alpha(theme.palette.primary.lighter, 0.35) : 'inherit' }}
                    >
                      {row.cells.map((cell: Cell) => (
                        <TableCell {...cell.getCellProps([{ className: cell.column.className }])}>{cell.render('Cell')}</TableCell>
                      ))}
                    </TableRow>
                  </Fragment>
                );
              })
            ) : (
              <EmptyTable msg="조회 결과가 없습니다." colSpan={11} />
            )}
            <TableRow sx={{ '&:hover': { bgcolor: 'transparent !important' } }}>
              <TableCell sx={{ p: 2, py: 3 }} colSpan={11}>
                <TablePagination
                  gotoPage={gotoPage}
                  totalCount={totalCount}
                  setPageSize={setPageSize}
                  pageSize={pageSize}
                  pageIndex={pageIndex}
                />
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Stack>
    </>
  );
}

export default BbsTable;
