// import { useMemo } from 'react';

// material-ui
import { Theme } from '@mui/material/styles';
import { Box, useMediaQuery } from '@mui/material';

// project-imports
// import Search from './Search';
// import Message from './Message';
import Profile from './Profile';
// import Localization from './Localization';
// import Notification from './Notification';
// import MobileSection from './MobileSection';
// import MegaMenuSection from './MegaMenuSection';

import useConfig from 'hooks/useConfig';
import DrawerHeader from 'layout/MainLayout/Drawer/DrawerHeader';

// type
import { MenuOrientation } from 'types/config';
import Breadcrumbs from 'components/@extended/Breadcrumbs';
// import navigation from 'menu-items';
import { menuItems, rentcarMenuItems, agentMenuItems, agentUnionMenuItems } from 'menu-items';
import { useLayoutEffect, useState } from 'react';
import useAuth from 'hooks/useAuth';
import { CompanyTypeCode } from 'constant/Constant';
import { NavItemType } from 'types/menu';
import ChargerInformation from 'layout/MainLayout/Drawer/ChargerInformation';

// ==============================|| HEADER - CONTENT ||============================== //

const HeaderContent = () => {
  const { isLoggedIn, user } = useAuth();
  const { menuOrientation } = useConfig();
  const [navigation, setNavigation] = useState<{ items: NavItemType[] }>({ items: [] });

  const downLG = useMediaQuery((theme: Theme) => theme.breakpoints.down('lg'));

  useLayoutEffect(() => {
    if (isLoggedIn) {
      if (user?.company?.companyTypeCode === CompanyTypeCode.rent25.code) {
        setNavigation(menuItems);
      } else if (user?.company?.companyTypeCode === CompanyTypeCode.rentcar.code) {
        setNavigation(rentcarMenuItems);
      } else if (user?.company?.companyTypeCode === CompanyTypeCode.agent.code) {
        setNavigation(agentMenuItems);
      } else if (user?.company?.companyTypeCode === CompanyTypeCode.agentUnion.code) {
        setNavigation(agentUnionMenuItems);
      }
    }
    // eslint-disable-next-line
  }, [isLoggedIn, user, rentcarMenuItems, agentMenuItems, agentUnionMenuItems]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  // const localization = useMemo(() => <Localization />, [i18n]);

  // const megaMenu = useMemo(() => <MegaMenuSection />, []);

  return (
    <>
      {menuOrientation === MenuOrientation.HORIZONTAL && !downLG && <DrawerHeader open={true} />}
      <Box sx={{ width: '100%', height: '62px', p: 0, ml: { xs: 0, md: 2 } }}>
        <Breadcrumbs navigation={navigation} title titleBottom={true} card={false} divider={false} />
      </Box>
      {/* {!downLG && <Search />} */}
      {/* {!downLG && megaMenu}
      {!downLG && localization} */}

      {/* {downLG && <Box sx={{ width: '100%', ml: 1 }} />} */}

      {/* <Notification /> */}
      {/* <Message /> */}
      {<Profile />}
      {user?.company?.companyTypeCode !== CompanyTypeCode.rent25.code && <ChargerInformation />}
      {/* {!downLG && <MobileSection />} */}
    </>
  );
};

export default HeaderContent;
