import { BbsType } from 'constant/Constant';
import React from 'react';
import BbsViewer from 'sections/rent25/operation/BbsViewer';

type Props = {};

const NoticeRegisterPage = (props: Props) => {
  return <BbsViewer bbsType={BbsType.notice.code} viewType="create" />;
};

export default NoticeRegisterPage;
