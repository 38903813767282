import React, { useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import { DialogContent, Stack, Typography } from '@mui/material';
import { PopupTransition } from 'components/@extended/Transitions';
import EditableTextField from 'components/form/EditableTextField';
import { useDialog } from 'contexts/DialogContext';
// import { InfoCircle } from 'iconsax-react';
// import Avatar from 'components/@extended/Avatar';

export interface ConfirmProps {
  title: string;
  subtitle?: string;
  isContent?: boolean;
  color?: 'primary' | 'secondary' | 'error' | 'warning' | 'info' | 'success';
  onConfirm: (yesNo: boolean, content?: any) => void;
}

function ContextConfirmDialog() {
  const { isOpen, confirmProps, onConfirm } = useDialog();
  const [content, setContent] = useState('');

  const handleConfirm = (yesNo: boolean) => {
    onConfirm(yesNo, content);
    setContent('');
  };

  return (
    <>
      <Dialog
        open={isOpen}
        onClose={() => handleConfirm(false)}
        fullWidth
        maxWidth={'xs'}
        keepMounted
        TransitionComponent={PopupTransition}
        aria-labelledby="confirm-dialog"
        aria-describedby="confirm-dialog-description"
      >
        <DialogContent sx={{ mt: 2 }}>
          <Stack alignItems="center" spacing={3.5}>
            <Stack spacing={2}>
              <Typography variant="h4" align="center">
                {confirmProps.title}
              </Typography>
              {confirmProps.subtitle && <Typography align="center">{confirmProps.subtitle}</Typography>}
            </Stack>
            {confirmProps.isContent && (
              <EditableTextField
                edit={true}
                fullWidth
                multiline
                rows={3}
                value={content}
                id="changeContent"
                name="changeContent"
                placeholder="사유"
                onChange={(e) => setContent(e.target.value)}
              />
            )}
            <Stack direction="row" spacing={2} sx={{ width: 1 }}>
              <Button fullWidth onClick={() => handleConfirm(false)} color="secondary" variant="outlined">
                취소
              </Button>
              <Button fullWidth color={confirmProps.color} variant="contained" onClick={() => handleConfirm(true)} autoFocus>
                확인
              </Button>
            </Stack>
          </Stack>
        </DialogContent>
      </Dialog>
    </>
  );
}

export default ContextConfirmDialog;
