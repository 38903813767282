import { Tooltip, Typography, TypographyProps, useTheme } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { ThemeMode } from 'types/config';

type Props = TypographyProps & {
  width?: string;
};

const TooltipTypography = ({ children, width, ...props }: Props) => {
  const theme = useTheme();
  const mode = theme.palette.mode;
  const textRef = useRef<HTMLElement>(null);
  const [overflow, setOverflow] = useState<boolean>(false);

  useEffect(() => {
    const checkOverflow = () => {
      const current = textRef.current;
      if (current) {
        // offsetWidth와 scrollWidth를 비교하여 오버플로우 여부를 확인
        setOverflow(current.offsetWidth < current.scrollWidth);
      }
    };

    checkOverflow();
    // 윈도우 리사이징에 대응하기 위해 이벤트 리스너 추가
    window.addEventListener('resize', checkOverflow);
    return () => {
      window.removeEventListener('resize', checkOverflow);
    };
  }, [children]);

  return (
    <>
      <Tooltip
        componentsProps={{
          tooltip: {
            sx: {
              backgroundColor: mode === ThemeMode.DARK ? theme.palette.grey[50] : theme.palette.grey[700],
              opacity: 0.9
            }
          }
        }}
        title={overflow ? String(children) : ''}
      >
        <Typography
          ref={textRef}
          noWrap={props.noWrap ? props.noWrap : true}
          overflow={overflow ? 'ellipsis' : 'unset'}
          style={{
            width: width
          }}
          {...props}
        >
          {children}
        </Typography>
      </Tooltip>
    </>
  );
};

export default TooltipTypography;
