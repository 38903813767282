// project-imports
import authorizationRent25Menus from './authorization-rent25-menus';
import authorizationRentcarMenus from './authorization-rentcar-menus';
import authorizationAgentMenus from './authorization-agent-menus';
import authorizationAgentUnionMenus from './authorization-agent-union-menus';

// types
import { NavItemType } from 'types/menu';

// ==============================|| MENU ITEMS ||============================== //

export const menuItems: { items: NavItemType[] } = {
  items: authorizationRent25Menus
};

export const rentcarMenuItems: { items: NavItemType[] } = {
  items: authorizationRentcarMenus
};

export const agentMenuItems: { items: NavItemType[] } = {
  items: authorizationAgentMenus
};
export const agentUnionMenuItems: { items: NavItemType[] } = {
  items: authorizationAgentUnionMenus
};

export default menuItems;
