import { lazy } from 'react';
import Loadable from 'components/Loadable';

// project-imports
import MainLayout from 'layout/MainLayout';
import AuthGuard from 'utils/route-guard/AuthGuard';

// render - sample page
// ==============================|| MAIN ROUTES ||============================== //
const CodeListPage = Loadable(lazy(() => import('pages/system/code/code-list')));
const MenuManagementPage = Loadable(lazy(() => import('pages/system/menu/menu-management')));
const AuthorizationListPage = Loadable(lazy(() => import('pages/system/authorization/authorization-list')));

const AuthSystemRoutes = {
  path: '/',
  children: [
    {
      path: 'system',
      element: (
        <AuthGuard>
          <MainLayout />
        </AuthGuard>
      ),
      children: [
        {
          path: 'management/menu',
          element: <MenuManagementPage />
        },
        {
          path: 'management/authorization',
          element: <AuthorizationListPage />
        },
        {
          path: 'management/code',
          element: <CodeListPage />
        }
      ]
    }
  ]
};

export default AuthSystemRoutes;
